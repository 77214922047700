import {
  AvatarGroup,
  Box,
  Button,
  Divider,
  Paper,
  Skeleton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { isBefore } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GuestAvatar } from '../../components/GuestAvatar';
import { Answer, answers, useGetGuests } from '../../services/useGuest';
import { theme } from '../../theme';
import { Sx } from '../../utils/sx';
import { useDialog } from '../../utils/useDialog';
import { GuestListDialog } from './GuestListDialog';

interface GuestListProps {
  loading?: boolean;
  sx?: Sx;
}

export const GuestList: FC<GuestListProps> = ({ sx, loading }) => {
  const { t } = useTranslation();
  const { guests: globalGuests } = useGetGuests();
  const guests = loading ? undefined : globalGuests;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [dialogSelectedTab, setDialogSelectedTab] = useState(0);

  const [openDialog, closeDialog] = useDialog((open) => (
    <GuestListDialog
      open={open}
      onClose={closeDialog}
      selectedTab={dialogSelectedTab}
    />
  ));

  const sortedGuests = [...(guests || [])]
    .filter(({ answer }) => answer !== Answer.DO_NOT_PARTICIPATE)
    .sort((guestA, guestB) => {
      if (guestA.answer === guestB.answer) {
        if (guestA.answeredAt && guestB.answeredAt) {
          return isBefore(guestA.answeredAt, guestB.answeredAt) ? -1 : 1;
        }
        return isBefore(guestA.createdAt, guestB.createdAt) ? -1 : 1;
      }
      if (
        guestA.answer === Answer.PARTICIPATE &&
        guestB.answer !== Answer.PARTICIPATE
      ) {
        return -1;
      }
      if (
        guestA.answer !== Answer.PARTICIPATE &&
        guestB.answer === Answer.PARTICIPATE
      ) {
        return 1;
      }
      if (!guestA.answer && guestB.answer) {
        return 1;
      }
      if (guestA.answer && !guestB.answer) {
        return -1;
      }
      return 0;
    });

  return (
    <Paper
      sx={{ px: 3, py: 2, display: 'flex', flexDirection: 'column', ...sx }}
    >
      <Typography variant="h6">{t('GUEST_LIST')}</Typography>
      <Box
        sx={{
          display: 'flex',
          my: 1,
        }}
      >
        {[Answer.PARTICIPATE, Answer.MAYBE, null].map((answ, i) => {
          const answerInfos = answ && answers[answ];
          const guestsCount = guests?.filter(
            ({ answer }) => answer === answ,
          ).length;
          return (
            <Button
              key={i}
              sx={{ flexDirection: 'column', flex: 1, py: 1 }}
              color="inherit"
              onClick={() => {
                setDialogSelectedTab(i);
                openDialog();
              }}
            >
              <Typography
                sx={{ fontWeight: 800, fontSize: 24, lineHeight: '20px' }}
              >
                {guests ? guestsCount : <Skeleton width={15} />}
              </Typography>
              <Typography sx={{ fontSize: 12 }}>
                {t(
                  guestsCount && guestsCount > 1
                    ? answerInfos?.labelPlural || 'GUESTS'
                    : answerInfos?.label || 'GUEST',
                )}
              </Typography>
            </Button>
          );
        })}
      </Box>
      <Divider />
      <Button
        color="inherit"
        onClick={() => {
          setDialogSelectedTab(0);
          openDialog();
        }}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          my: 1,
          alignSelf: 'center',
          pl: 2,
          py: 1,
        }}
      >
        <AvatarGroup
          max={isMobile ? 8 : 16}
          slotProps={{
            additionalAvatar: {
              sx: {
                backgroundColor: 'transparent',
                borderColor: `${theme.palette.grey[500]} !important`,
                color: theme.palette.grey[500],
              },
            },
          }}
        >
          {guests
            ? sortedGuests.map((guest) => (
                <GuestAvatar key={guest.id} guest={guest} disableClick />
              ))
            : Array.from(Array(7)).map((_, i) => (
                <Skeleton key={i} variant="circular" height={40} width={40} />
              ))}
        </AvatarGroup>
      </Button>
    </Paper>
  );
};

import { Box } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Sx } from '../utils/sx';

interface CenterBoxProps {
  sx?: Sx;
}

export const CenterBox: FC<PropsWithChildren<CenterBoxProps>> = ({
  sx,
  children,
}) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

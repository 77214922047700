import { useIonRouter } from '@ionic/react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventPost } from '../../components/fields/EventPost';
import { paths } from '../../router';
import { useGetUser } from '../../services/useAuth';
import {
  EVENT_EXPIRATION_IN_DAYS,
  useDeleteEvent,
  useGetEvent,
  useUpdateEvent,
} from '../../services/useEvent';
import {
  NewPost,
  Range,
  convertNewPostToPost,
  useCreatePost,
} from '../../services/usePost';
import { eventIsPast } from '../../utils/eventDate';
import { useNotification } from '../../utils/useNotification';

interface CancelDialogProps {
  open: boolean;
  onClose: () => void;
}

export const CancelDialog: FC<CancelDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const [loading, setLoading] = useState(false);
  const { pushError } = useNotification();
  const { updateEvent } = useUpdateEvent();
  const { deleteEvent } = useDeleteEvent();
  const router = useIonRouter();
  const { user } = useGetUser();
  const { createPost } = useCreatePost();
  const [cancelPost, setCancelPost] = useState<NewPost>({
    range: Range.PUBLIC,
    message: null,
  });

  if (!event) return null;

  const deleteEnabled = eventIsPast(event) || !!event.canceledAt;

  const onCancelDelete = async () => {
    if (!user) return;
    setLoading(true);
    try {
      if (deleteEnabled) {
        await deleteEvent(event);
        onClose();
        router.push(paths.HOME, 'root');
      } else {
        if (cancelPost.message?.trim()) {
          await createPost({
            ...cancelPost,
            eventId: event.id,
            userId: user.id,
          });
        }
        await updateEvent(event.id, { canceledAt: new Date() });
        onClose();
      }
    } catch (e) {
      pushError(e);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        {t(deleteEnabled ? 'DELETE_EVENT' : 'CANCEL_EVENT', {
          expiration: EVENT_EXPIRATION_IN_DAYS,
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(deleteEnabled ? 'DELETE_EVENT_TEXT' : 'CANCEL_EVENT_TEXT', {
            expiration: EVENT_EXPIRATION_IN_DAYS,
          })}
        </DialogContentText>
        {!deleteEnabled && (
          <EventPost
            sx={{ mt: 3 }}
            placeholder={t('CANCEL_EVENT_POST_TEXT')}
            post={convertNewPostToPost(cancelPost)}
            onMessageChange={(message) =>
              setCancelPost((prev) => ({ ...prev, message }))
            }
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CLOSE')}</Button>
        <LoadingButton
          variant="contained"
          color="error"
          loading={loading}
          onClick={onCancelDelete}
        >
          {t(deleteEnabled ? 'DELETE_EVENT' : 'CANCEL_EVENT')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { RateApp } from 'capacitor-rate-app';
import { addMonths, addWeeks, isFuture, isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { ls } from './localStorage';
import { isApp } from './platform';
import { useInView } from './useInView';

const LS_NEXT_RATE_DATE = 'LS_NEXT_RATE_DATE';

export const useRateApp = () => {
  const [loading, setLoading] = useState(false);
  const inView = useInView();

  useEffect(() => {
    if (!isApp || !inView) return;

    const rateApp = async () => {
      const nextRateDateLs = await ls.get<string>(LS_NEXT_RATE_DATE);

      if (!nextRateDateLs) {
        await ls.set(LS_NEXT_RATE_DATE, addWeeks(new Date(), 2));
        return;
      }

      const nextRateDate = new Date(nextRateDateLs);

      if (!isValid(nextRateDate) || isFuture(nextRateDate) || loading) return;

      setLoading(true);
      await RateApp.requestReview();
      await ls.set(LS_NEXT_RATE_DATE, addMonths(new Date(), 3));
      setLoading(false);
    };

    const timeout = setTimeout(() => {
      if (!inView) return;
      rateApp().catch(() => {});
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [loading, inView]);
};

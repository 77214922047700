import { createTheme } from '@mui/material';
import { common, lightBlue } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const containerProp = { container: () => document.querySelector('ion-app') };

export const theme = createTheme({
  palette: {
    primary: {
      main: lightBlue[800],
    },
    secondary: {
      main: common.white,
      contrastText: lightBlue[800],
    },
    background: {
      default: '#015384',
    },
  },
  components: {
    MuiPopper: { defaultProps: containerProp },
    MuiAutocomplete: {
      defaultProps: { componentsProps: { popper: containerProp } },
    },
    MuiModal: { defaultProps: containerProp },
    MuiPopover: { defaultProps: containerProp },
  },
});

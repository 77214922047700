import { useIonRouter } from '@ionic/react';
import {
  AccountCircle,
  AddBox,
  Edit,
  Event,
  Person,
  PersonOff,
  PowerSettingsNew,
} from '@mui/icons-material';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from '../router';
import { useGetUser, useSignOut } from '../services/useAuth';
import { useGetEvent } from '../services/useEvent';
import { getLSGuestKey, useGetGuest } from '../services/useGuest';
import { ls } from '../utils/localStorage';
import { isApp } from '../utils/platform';
import { useNotification } from '../utils/useNotification';
import { LS_USER_NAME_KEY, getFullName } from '../utils/userName';

interface UserGuestMenuProps {
  onClose: () => void;
  openUserDialog: () => void;
  openLoginDialog: () => void;
  openRenameDialog: () => void;
}

export const UserGuestMenu: FC<UserGuestMenuProps> = ({
  onClose,
  openUserDialog,
  openLoginDialog,
  openRenameDialog,
}) => {
  const { t } = useTranslation();
  const { user } = useGetUser();
  const { event } = useGetEvent();
  const { pushError } = useNotification();
  const router = useIonRouter();
  const { guest } = useGetGuest();
  const signOut = useSignOut();

  const triggerSignOut = async () => {
    try {
      await signOut();
    } catch (e) {
      pushError(e);
    }
  };

  if (user) {
    return (
      <>
        {!isApp && (
          <>
            <MenuItem
              href={paths.NEW_EVENT}
              onClick={(e) => {
                e.preventDefault();
                router.push(paths.NEW_EVENT);
                onClose();
              }}
            >
              <ListItemIcon>
                <AddBox fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('CREATE_EVENT')}</ListItemText>
            </MenuItem>
            <MenuItem
              href={paths.MY_EVENTS}
              onClick={(e) => {
                e.preventDefault();
                router.push(paths.MY_EVENTS);
                onClose();
              }}
            >
              <ListItemIcon>
                <Event fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('MY_EVENTS')}</ListItemText>
            </MenuItem>
          </>
        )}

        <MenuItem
          onClick={() => {
            openUserDialog();
            onClose();
          }}
        >
          <ListItemIcon>
            <AccountCircle fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('MANAGE_ACCOUNT')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            triggerSignOut();
            onClose();
          }}
        >
          <ListItemIcon>
            <PowerSettingsNew fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('SIGN_OUT')}</ListItemText>
        </MenuItem>
      </>
    );
  }

  return (
    <>
      <Typography color="GrayText" sx={{ px: 2, py: 1 }}>
        {t('YOU_ARE_NOT_LOGGED_IN', { name: guest && getFullName(guest) })}
      </Typography>
      <MenuItem
        onClick={() => {
          openLoginDialog();
          onClose();
        }}
      >
        <ListItemIcon>
          <Person fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('SIGN_IN')}</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          openRenameDialog();
          onClose();
        }}
      >
        <ListItemIcon>
          <Edit fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('EDIT_NAME')}</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={async () => {
          event && (await ls.remove(getLSGuestKey(event.id)));
          await ls.remove(LS_USER_NAME_KEY);
          onClose();
          location.reload();
        }}
      >
        <ListItemIcon>
          <PersonOff fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('ITS_NOT_ME')}</ListItemText>
      </MenuItem>
    </>
  );
};

import { useMediaQuery } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { FC, useState } from 'react';
import { theme } from '../../theme';
import { Sx } from '../../utils/sx';

interface DatePickerProps {
  value: Date;
  onChange?: (value: Date) => void;
  label?: string;
  minDate?: Date;
  disablePast?: boolean;
  disabled?: boolean;
  sx?: Sx;
}

export const DatePicker: FC<DatePickerProps> = ({
  value,
  onChange,
  label,
  minDate,
  disablePast,
  disabled,
  sx,
}) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <MuiDatePicker
      disabled={disabled}
      disablePast={!disabled && disablePast}
      minDate={!disabled && minDate}
      label={label}
      value={value}
      format={isMobile ? 'PP' : 'PPP'}
      onChange={(val) => {
        val && onChange?.(val);
        setOpen(false);
      }}
      slotProps={{
        textField: {
          sx,
          InputProps: {
            onClick: () => !disabled && setOpen(true),
          },
        },
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    />
  );
};

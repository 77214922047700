import { OptionsObject, SnackbarKey, useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilState } from 'recoil';
import { Guest, answers } from '../services/useGuest';
import { getFullName } from './userName';

const answerDisabledAtom = atom<boolean>({
  key: 'answerDisabled',
  default: false,
});

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [answerNotificationsDisabled, setAnswerNotificationsDisabled] =
    useRecoilState(answerDisabledAtom);
  const { t } = useTranslation();

  const pushNotification = useCallback(
    (message: string, options?: OptionsObject) => {
      const snackbarKey: SnackbarKey = enqueueSnackbar(message, {
        ...options,
        SnackbarProps: {
          ...options?.SnackbarProps,
          onClick: () => closeSnackbar(snackbarKey),
        },
      });
    },
    [closeSnackbar, enqueueSnackbar],
  );

  const pushError = useCallback(
    (error: any) =>
      pushNotification((error as Error)?.message || t('AN_ERROR_OCCURED'), {
        variant: 'error',
      }),
    [pushNotification, t],
  );

  const pushAnswer = useCallback(
    (guest: Guest) => {
      const answerInfos = guest.answer && answers[guest.answer];
      if (!answerNotificationsDisabled && answerInfos) {
        pushNotification(
          t('GUEST_ANSWER_NOTIFICATION', {
            name: getFullName(guest),
            answer: t(answerInfos.labelMe),
          }),
          {
            preventDuplicate: true,
            variant: 'guestAnswer' as any,
            answer: guest.answer,
            autoHideDuration: 3000,
          } as any,
        );
      }
    },
    [answerNotificationsDisabled, pushNotification, t],
  );

  return {
    pushNotification,
    pushError,
    pushAnswer,
    setAnswerNotificationsDisabled,
  };
};

import { generatePath } from 'react-router-dom';
import { paths } from '../router';
import { isApp } from './platform';

const BASE_URL = 'https://hop-hop.app';

export const getEventUrl = (eventId: string) =>
  `${isApp ? BASE_URL : window.location.origin}${generatePath(paths.EVENT, {
    eventId,
  })}`;

import { alpha, ButtonBase } from '@mui/material';
import { FC } from 'react';
import { Event } from '../services/useEvent';
import { theme } from '../theme';
import { getDefaultImage } from '../utils/getDefaultImage';
import { useAppBarHeight } from '../utils/useAppBarHeight';
import { useDialog } from '../utils/useDialog';
import { ImageDialog } from './ImageDialog';

interface EventBackgroundImageProps {
  event: Pick<Event, 'backgroundImageURL' | 'thumbnailURL' | 'id'> | undefined;
  height: string;
}

export const EventBackgroundImage: FC<EventBackgroundImageProps> = ({
  event,
  height,
}) => {
  const appBarHeight = useAppBarHeight();
  const adjustedHeight = `${appBarHeight}px + ${height}`;

  const [openDialog, closeDialog] = useDialog((open) =>
    event?.backgroundImageURL ? (
      <ImageDialog
        open={open}
        onClose={closeDialog}
        src={event.backgroundImageURL}
      />
    ) : null,
  );

  const imageURL = event
    ? event.backgroundImageURL || getDefaultImage(event.id)
    : undefined;

  return (
    <ButtonBase
      onClick={() => openDialog()}
      disabled={!event?.backgroundImageURL}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        backgroundImage: [
          `url("${imageURL}")`,
          `url("${imageURL}")`,
          `linear-gradient(${alpha(
            theme.palette.background.default,
            0,
          )}, ${alpha(theme.palette.background.default, 0)} 50%, ${alpha(
            theme.palette.background.default,
            1,
          )}), url("${imageURL}")`,
        ],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: [
          `calc(${adjustedHeight} + ${theme.shape.borderRadius}px)`,
          `calc(${adjustedHeight} + ${theme.shape.borderRadius}px)`,
          `calc(${adjustedHeight} + 20vh)`,
        ],
        width: '100%',
        '&:after': {
          content: [undefined, undefined, '""'],
          position: 'absolute',
          bottom: -1,
          left: 0,
          right: 0,
          height: '2px',
          bgcolor: theme.palette.background.default,
        },
      }}
    />
  );
};

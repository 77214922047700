import {
  Cancel,
  Delete,
  Event,
  NotInterested,
  Report,
} from '@mui/icons-material';
import { Box, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetEvent } from '../../services/useEvent';
import { useGetGuest, useUpdateGuest } from '../../services/useGuest';
import { eventIsPast } from '../../utils/eventDate';
import { useAddToCalendar } from '../../utils/useAddToCalendar';
import { useGetHost } from '../../utils/useGetHost';
import { useNotification } from '../../utils/useNotification';

interface ActionsMenuProps {
  onClose: () => void;
  onCancel: () => void;
  openReportDialog: () => void;
  onQuitEvent: () => void;
}

export const ActionsMenu: FC<ActionsMenuProps> = ({
  onClose,
  onCancel,
  openReportDialog,
  onQuitEvent,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { isHost } = useGetHost();
  const { guest } = useGetGuest();
  const { updateGuest } = useUpdateGuest();
  const { pushError } = useNotification();
  const addToCalendar = useAddToCalendar();

  const deleteEnabled = eventIsPast(event) || !!event?.canceledAt;

  const onAddToCalendar = async () => {
    if (!event || !guest) return;
    onClose();

    try {
      await addToCalendar(event);
      await updateGuest(event.id, guest.id, { addedToCalendar: true });
    } catch (e) {
      pushError(e);
    }
  };

  return (
    <Box>
      {event && !eventIsPast(event) && !event.canceledAt && (
        <MenuItem onClick={onAddToCalendar}>
          <ListItemIcon>
            <Event fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('ADD_TO_CALENDAR')}</ListItemText>
        </MenuItem>
      )}
      {isHost ? (
        <MenuItem
          onClick={() => {
            onCancel();
            onClose();
          }}
        >
          <ListItemIcon>
            {deleteEnabled ? (
              <Delete fontSize="small" />
            ) : (
              <Cancel fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>
            {t(deleteEnabled ? 'DELETE_EVENT' : 'CANCEL_EVENT')}
          </ListItemText>
        </MenuItem>
      ) : (
        <>
          {guest && (
            <MenuItem
              onClick={() => {
                onQuitEvent();
                onClose();
              }}
            >
              <ListItemIcon>
                <NotInterested />
              </ListItemIcon>
              <ListItemText>{t('QUIT_EVENT')}</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              openReportDialog();
              onClose();
            }}
          >
            <ListItemIcon>
              <Report />
            </ListItemIcon>
            <ListItemText>{t('REPORT')}</ListItemText>
          </MenuItem>
        </>
      )}
    </Box>
  );
};

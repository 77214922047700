import { TextField } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Sx } from '../../utils/sx';
import { DESCRIPTION_MAX_CHARACTERS, isEventDescriptionValid } from './utils';

interface EventDescriptionProps {
  value: string | null;
  onChange?: (value: string | null) => void;
  sx?: Sx;
}

export const EventDescription: FC<EventDescriptionProps> = ({
  value,
  onChange,
  sx,
}) => {
  const { t } = useTranslation();
  const isValid = isEventDescriptionValid(value);

  return (
    <TextField
      sx={sx}
      minRows={4}
      label={t('DESCRIPTION')}
      fullWidth
      multiline
      value={value || ''}
      onChange={({ target }) => onChange?.(target.value || null)}
      error={!isValid}
      helperText={
        !isValid &&
        t('MAX_CHARACTERS', {
          maxCharacters: DESCRIPTION_MAX_CHARACTERS,
        })
      }
    />
  );
};

import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { isApp } from './platform';

interface MinHeight {
  minHeight: number;
}

export const useAppBarHeight = () => {
  const {
    mixins: { toolbar },
    breakpoints,
  } = useTheme();
  const toolbarDesktopQuery = breakpoints.up('sm');
  const toolbarLandscapeQuery = `${breakpoints.up(
    'xs',
  )} and (orientation: landscape)`;
  const isDesktop = useMediaQuery(toolbarDesktopQuery);
  const isLandscape = useMediaQuery(toolbarLandscapeQuery);

  return useMemo(() => {
    if (isApp) {
      return 0;
    }

    if (isDesktop || isLandscape) {
      return (
        (
          toolbar[
            isDesktop ? toolbarDesktopQuery : toolbarLandscapeQuery
          ] as MinHeight
        )?.minHeight || 52
      );
    }
    return (toolbar as MinHeight)?.minHeight;
  }, [
    isDesktop,
    isLandscape,
    toolbar,
    toolbarDesktopQuery,
    toolbarLandscapeQuery,
  ]);
};

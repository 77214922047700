import { Button, Paper } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GuestAvatar } from '../../components/GuestAvatar';
import { useGetGuest } from '../../services/useGuest';
import { Sx } from '../../utils/sx';
import { useDialog } from '../../utils/useDialog';
import { AddPostDialog } from './AddPostDialog';

interface AddPostProps {
  sx?: Sx;
}

export const AddPost: FC<AddPostProps> = ({ sx }) => {
  const { t } = useTranslation();
  const { guest } = useGetGuest();

  const [openDialog, closeDialog] = useDialog((open) => (
    <AddPostDialog open={open} onClose={closeDialog} />
  ));

  if (!guest) return null;

  return (
    <Paper sx={{ p: 2, display: 'flex', alignItems: 'center', ...sx }}>
      <GuestAvatar guest={guest} sx={{ mr: 2 }} />
      <Button variant="contained" sx={{ flex: 1 }} onClick={() => openDialog()}>
        {t('ADD_POST')}
      </Button>
    </Paper>
  );
};

import { Lock, Public } from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { collection, query as fsQuery, orderBy } from 'firebase/firestore';
import { useCallback, useMemo } from 'react';
import { useGetUser } from './useAuth';
import {
  useCreate,
  useDelete,
  useGetList,
  useMutate,
  useUpdate,
} from './useEntity';
import { eventsCollection, useGetEvent } from './useEvent';
import { useGetGuest } from './useGuest';

export enum Range {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
}

export interface NewPost {
  range: Range;
  message: string | null;
}

export interface Post extends NewPost {
  id: string;
  updatedAt: Date | null;
  createdAt: Date;
  guestId: string;
  userId: string | null;
}

export const convertNewPostToPost = (newPost: NewPost): Post => ({
  ...newPost,
  id: '',
  createdAt: new Date(),
  updatedAt: null,
  guestId: '',
  userId: null,
});

export const ranges: Record<
  Range,
  {
    label: string;
    tooltip: string;
    Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
  }
> = {
  [Range.PUBLIC]: {
    label: 'PUBLIC',
    tooltip: 'PUBLIC_RANGE_TOOLTIP',
    Icon: Public,
  },
  [Range.PRIVATE]: {
    label: 'PRIVATE',
    tooltip: 'PRIVATE_RANGE_TOOLTIP',
    Icon: Lock,
  },
};

const POSTS_COLLECTION = 'posts';
export const getPostsCollection = (eventId: string) =>
  collection(eventsCollection, eventId, POSTS_COLLECTION);

export const useGetPosts = (skip?: boolean) => {
  const { user } = useGetUser();
  const { event } = useGetEvent();
  const isEventAdmin = !!user && user.id === event?.userId;

  const query = useMemo(
    () =>
      event?.id
        ? fsQuery(getPostsCollection(event.id), orderBy('createdAt', 'desc'))
        : undefined,
    [event?.id],
  );
  const { data, loading, error } = useGetList<Post>(query, skip);

  const filteredPosts = useMemo(() => {
    if (isEventAdmin) {
      return data;
    }

    return data?.filter(
      ({ range, userId }) =>
        (user && userId === user.id) || range === Range.PUBLIC,
    );
  }, [data, isEventAdmin, user]);

  return {
    posts: filteredPosts,
    loading,
    error,
  };
};

export const useCreatePost = () => {
  const { guest } = useGetGuest();
  const { create } = useCreate();

  const createPost = useCallback(
    async ({
      eventId,
      range,
      message,
      userId,
    }: {
      eventId: string;
      range: Range;
      message: string | null;
      userId: string | null;
    }) => {
      if (!guest) {
        throw new Error("Can't create post if guest is null");
      }

      return create<Post>(getPostsCollection(eventId), {
        createdAt: new Date(),
        updatedAt: null,
        userId,
        guestId: guest.id,
        message,
        range,
      });
    },
    [create, guest],
  );

  return { createPost };
};

export const useUpdatePost = () => {
  const { update, loading } = useUpdate();

  const updatePost = useCallback(
    (eventId: string, postId: string, fields: Partial<Post>) =>
      update(getPostsCollection(eventId), postId, fields),
    [update],
  );

  return { updatePost, loading };
};

export const useDeletePost = () => {
  const { loading } = useMutate();
  const { deleteEntity } = useDelete();

  const deletePost = useCallback(
    (eventId: string, postId: string) =>
      deleteEntity(getPostsCollection(eventId), postId),
    [deleteEntity],
  );

  return { deletePost, loading };
};

import { IonContent, IonPage } from '@ionic/react';
import {
  CheckCircle,
  Email,
  Event as EventIcon,
  Home,
  Restore,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  Chip,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
  Typography,
  alpha,
  useMediaQuery,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Grid from '@mui/material/Unstable_Grid2';
import { formatDuration } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { EventCard } from '../../components/EventCard';
import { SkeletonCard } from '../../components/SkeletonCard';
import { TopBar } from '../../components/TopBar';
import { useGetUser } from '../../services/useAuth';
import { EVENT_EXPIRATION_IN_DAYS } from '../../services/useEvent';
import { Answer, useGetMyGuestsEvents } from '../../services/useGuest';
import { theme } from '../../theme';
import { eventIsPast } from '../../utils/eventDate';
import { isApp } from '../../utils/platform';
import { useAppBarHeight } from '../../utils/useAppBarHeight';
import { useInView } from '../../utils/useInView';

export const FILTER_PARAM = 'filter';

export enum FilterId {
  INCOMING = 'INCOMING',
  PARTICIPATE = 'PARTICIPATE',
  INVITATION = 'INVITATION',
  ORGANIZER = 'ORGANIZER',
  PAST = 'PAST',
}

export const MyEvents = () => {
  const { t } = useTranslation();
  const inView = useInView();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const appBarHeight = useAppBarHeight();
  const defaultFilter = FilterId.INCOMING;
  const [selectedFilterId, setSelectedFilterId] =
    useState<FilterId>(defaultFilter);
  const { user } = useGetUser();
  const [filterIdUrl, setFilterIdUrl] = useQueryParam(
    FILTER_PARAM,
    StringParam,
  );

  useEffect(() => {
    filterIdUrl && setSelectedFilterId(filterIdUrl as FilterId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilterIdUrl(
      inView && selectedFilterId !== defaultFilter
        ? selectedFilterId
        : undefined,
      'replaceIn',
    );
  }, [defaultFilter, inView, selectedFilterId, setFilterIdUrl]);

  const filters: {
    id: FilterId;
    label: string;
    shortLabel: string;
    title: string;
    Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
    level: number;
  }[] = [
    {
      id: FilterId.INCOMING,
      label: t('EVENT_FILTER_INCOMING_LABEL'),
      shortLabel: t('EVENT_FILTER_INCOMING_SHORT_LABEL'),
      title: t('EVENT_FILTER_INCOMING_TITLE'),
      Icon: EventIcon,
      level: 0,
    },
    {
      id: FilterId.PARTICIPATE,
      label: t('EVENT_FILTER_PARTICIPATE_LABEL'),
      shortLabel: t('EVENT_FILTER_PARTICIPATE_SHORT_LABEL'),
      title: t('EVENT_FILTER_PARTICIPATE_TITLE'),
      Icon: CheckCircle,
      level: 1,
    },
    {
      id: FilterId.INVITATION,
      label: t('EVENT_FILTER_INVITATION_LABEL'),
      shortLabel: t('EVENT_FILTER_INVITATION_SHORT_LABEL'),
      title: t('EVENT_FILTER_INVITATION_TITLE'),
      Icon: Email,
      level: 1,
    },
    {
      id: FilterId.ORGANIZER,
      label: t('EVENT_FILTER_ORGANIZER_LABEL'),
      shortLabel: t('EVENT_FILTER_ORGANIZER_SHORT_LABEL'),
      title: t('EVENT_FILTER_ORGANIZER_TITLE'),
      Icon: Home,
      level: 1,
    },
    {
      id: FilterId.PAST,
      label: t('EVENT_FILTER_PAST_LABEL'),
      shortLabel: t('EVENT_FILTER_PAST_SHORT_LABEL'),
      title: t('EVENT_FILTER_PAST_TITLE'),
      Icon: Restore,
      level: 0,
    },
  ];

  const { data, loading, error } = useGetMyGuestsEvents(
    selectedFilterId === FilterId.PAST ? 'desc' : 'asc',
  );

  const getFilteredEvents = useCallback(
    (filterId: FilterId) =>
      data.filter(({ event, answer }) => {
        if (!event) return false;
        switch (filterId) {
          case FilterId.INCOMING:
            return !eventIsPast(event);
          case FilterId.PARTICIPATE:
            return !eventIsPast(event) && answer === Answer.PARTICIPATE;
          case FilterId.INVITATION:
            return !eventIsPast(event) && !event.canceledAt && answer === null;
          case FilterId.ORGANIZER:
            return user && !eventIsPast(event) && event.userId === user.id;
          case FilterId.PAST:
            return eventIsPast(event);
        }
      }),
    [data, user],
  );

  const renderMobileFilters = () => (
    <Box
      sx={{
        display: 'flex',
        py: 2,
        px: 1.5,
        overflowX: 'auto',
        bgcolor: theme.palette.background.default,
      }}
    >
      {filters.map((filter) => (
        <Chip
          key={filter.id}
          label={
            filter.id === FilterId.INVITATION &&
            getFilteredEvents(filter.id).length ? (
              <Badge
                badgeContent={getFilteredEvents(filter.id).length}
                color="error"
                componentsProps={{
                  badge: { style: { top: 12, right: 5 } },
                }}
              >
                <Box sx={{ pr: '20px' }}>{filter.shortLabel}</Box>
              </Badge>
            ) : (
              filter.shortLabel
            )
          }
          icon={
            <filter.Icon
              sx={{
                color:
                  selectedFilterId === filter.id
                    ? undefined
                    : `${theme.palette.common.white} !important`,
              }}
            />
          }
          color={selectedFilterId === filter.id ? 'secondary' : undefined}
          sx={{
            color:
              selectedFilterId === filter.id
                ? undefined
                : theme.palette.common.white,
            mx: 0.5,
            fontSize: 16,
            bgcolor:
              selectedFilterId === filter.id
                ? `${theme.palette.common.white} !important`
                : undefined,
          }}
          onClick={() => setSelectedFilterId(filter.id)}
        />
      ))}
    </Box>
  );

  return (
    <IonPage>
      {isApp && (
        <TopBar
          title={t('MY_EVENTS')}
          additionnalContent={smallScreen && renderMobileFilters()}
        />
      )}

      <IonContent>
        {!isApp && (
          <TopBar
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1,
              bgcolor: theme.palette.background.default,
            }}
            additionnalContent={smallScreen && renderMobileFilters()}
          />
        )}

        <Box
          sx={{
            display: 'flex',
            pt: isApp ? 0 : `${appBarHeight + (smallScreen ? 64 : 0)}px`,
            height: '100%',
            overflow: 'hidden',
            boxSizing: 'border-box',
          }}
        >
          {!smallScreen && (
            <List
              sx={{
                color: theme.palette.common.white,
                borderRight: `1px solid ${alpha(
                  theme.palette.common.white,
                  0.2,
                )}`,
                pt: 2,
                pb: `max(${theme.spacing(2)}, env(safe-area-inset-bottom))`,
                flexShrink: 0,
              }}
            >
              {filters.map(({ id, label, Icon, level }) => (
                <ListItemButton
                  key={id}
                  sx={{
                    pl: 2 + level * 2,
                    bgcolor:
                      selectedFilterId === id
                        ? `${alpha(theme.palette.common.white, 0.2)} !important`
                        : undefined,
                  }}
                  onClick={() => {
                    setSelectedFilterId(id);
                  }}
                >
                  <ListItemIcon>
                    <Icon sx={{ color: theme.palette.common.white }} />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                  <Badge
                    invisible={id !== FilterId.INVITATION}
                    badgeContent={getFilteredEvents(id).length}
                    color="error"
                    componentsProps={{
                      badge: { style: { top: 0, right: 15 } },
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          )}

          <Box
            sx={{
              pb: `max(${theme.spacing(2)}, env(safe-area-inset-bottom))`,
              pt: [0, 0, 2],
              flexGrow: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {(error ||
              (!loading && !getFilteredEvents(selectedFilterId).length)) && (
              <Typography
                color="secondary"
                sx={{
                  alignSelf: 'center',
                  fontSize: 20,
                  my: 'auto',
                  textAlign: 'center',
                  p: 2,
                }}
              >
                {!getFilteredEvents(selectedFilterId).length
                  ? t('NO_EVENT')
                  : error?.message}
              </Typography>
            )}

            <Grid container spacing={2} sx={{ px: 2 }}>
              {loading
                ? Array.from(Array(5)).map((_, i) => (
                    <Grid key={i} xs={12} md={6} lg={4} xl={3}>
                      <SkeletonCard />
                    </Grid>
                  ))
                : getFilteredEvents(selectedFilterId).map((guest) => (
                    <Grid key={guest.id} xs={12} md={6} lg={4} xl={3}>
                      <EventCard guest={guest} />
                    </Grid>
                  ))}
            </Grid>

            {!loading && !error && selectedFilterId === FilterId.PAST && (
              <Typography
                sx={{
                  textAlign: 'center',
                  color: theme.palette.common.white,
                  mt: 2,
                  px: 2,
                }}
              >
                {t('OLD_EVENTS_ARE_DELETED', {
                  expiration: formatDuration({
                    days: EVENT_EXPIRATION_IN_DAYS,
                  }),
                })}
              </Typography>
            )}
          </Box>
        </Box>
      </IonContent>
    </IonPage>
  );
};

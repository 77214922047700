import { Box, TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sx } from '../../utils/sx';
import { NAME_MAX_CHARACTERS, NAME_MIN_CHARACTERS, isNameValid } from './utils';

interface FirstAndLastNameProps {
  sx?: Sx;
  firstName: string;
  lastName: string;
  onFirstNameChange?: (firstName: string) => void;
  onLastNameChange?: (lastName: string) => void;
  disabled?: boolean;
}

export const FirstAndLastName: FC<FirstAndLastNameProps> = ({
  sx,
  firstName,
  lastName,
  onFirstNameChange,
  onLastNameChange,
  disabled,
}) => {
  const { t } = useTranslation();
  const [firstIsFocus, setFirstIsFocus] = useState(false);
  const [lastIsFocus, setLastIsFocus] = useState(false);
  const firstNameIsValid = isNameValid(firstName);
  const lastNameIsValid = isNameValid(lastName);

  return (
    <Box sx={{ display: 'flex', ...sx }}>
      <TextField
        onFocus={() => setFirstIsFocus(true)}
        onBlur={() => setFirstIsFocus(false)}
        sx={{ mr: 2, flex: 1 }}
        label={t('FIRST_NAME')}
        value={firstName}
        onChange={({ target }) => onFirstNameChange?.(target.value)}
        disabled={disabled}
        error={!!firstName && !firstIsFocus && !firstNameIsValid}
        helperText={
          !!firstName &&
          !firstIsFocus &&
          !firstNameIsValid &&
          t(
            firstName.trim().length < NAME_MIN_CHARACTERS
              ? 'MIN_CHARACTERS'
              : firstName.trim().length > NAME_MAX_CHARACTERS
              ? 'MAX_CHARACTERS'
              : 'NO_SPECIAL_CHARACTERS',
            {
              minCharacters: NAME_MIN_CHARACTERS,
              maxCharacters: NAME_MAX_CHARACTERS,
            },
          )
        }
      />
      <TextField
        onFocus={() => setLastIsFocus(true)}
        onBlur={() => setLastIsFocus(false)}
        sx={{ flex: 1 }}
        label={t('LAST_NAME')}
        value={lastName}
        onChange={({ target }) => onLastNameChange?.(target.value)}
        disabled={disabled}
        error={!!lastName && !lastIsFocus && !lastNameIsValid}
        helperText={
          !!lastName &&
          !lastIsFocus &&
          !lastNameIsValid &&
          t(
            lastName.trim().length < NAME_MIN_CHARACTERS
              ? 'MIN_CHARACTERS'
              : 'NO_SPECIAL_CHARACTERS',
            {
              minCharacters: NAME_MIN_CHARACTERS,
            },
          )
        }
      />
    </Box>
  );
};

import { Helmet } from 'react-helmet-async';
import { Event } from '../services/useEvent';

interface HeadProps {
  event?: Event;
}

export const Head = ({ event }: HeadProps) => {
  const tabTitle = event
    ? `${event.title?.substring(0, 35)} | Hop Hop`
    : 'Hop Hop';
  const title = event?.title?.substring(0, 35) || 'Hop Hop';
  const image = event?.thumbnailURL;
  const description = event?.description?.substring(0, 65);
  const url = `${window.location.hostname}${window.location.pathname}`;

  return (
    <Helmet>
      <title>{tabTitle}</title>
      {description && <meta name="description" content={description} />}
      {image && <meta name="image" content={image} />}

      {/* Common */}
      <meta property="og:url" content={url} />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}

      {/* Twitter */}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
    </Helmet>
  );
};

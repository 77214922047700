import { Edit } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Sx } from '../utils/sx';

interface PostCreatedAtProps {
  createdAt: Date;
  updatedAt?: Date | null;
  sx?: Sx;
}

export const PostCreatedAt: FC<PostCreatedAtProps> = ({
  createdAt,
  updatedAt,
  sx,
}) => {
  const { t } = useTranslation();

  return (
    <Typography
      color="GrayText"
      variant="caption"
      component="div"
      sx={{
        lineHeight: '10px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ...sx,
      }}
    >
      {format(createdAt, 'PPp')}
      {updatedAt && (
        <Tooltip
          title={t('POST_UPDATED_AT', {
            date: format(updatedAt, 'PPp'),
          })}
        >
          <Edit sx={{ color: 'GrayText', fontSize: 11, ml: 1 }} />
        </Tooltip>
      )}
    </Typography>
  );
};

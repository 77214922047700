import { useIonRouter } from '@ionic/react';
import { Email } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FirstAndLastName } from '../../components/fields/FirstAndLastName';
import { isFirstAndLastNameValid } from '../../components/fields/utils';
import { paths } from '../../router';
import { useGetEvent } from '../../services/useEvent';
import { theme } from '../../theme';
import { ls } from '../../utils/localStorage';
import { isApp } from '../../utils/platform';
import { useGetHost } from '../../utils/useGetHost';
import {
  LS_USER_NAME_KEY,
  Name,
  formatName,
  getFullName,
} from '../../utils/userName';

interface GuestNameDialogProps {
  open: boolean;
  onValidate?: (val: Name) => void;
}

export const GuestNameDialog: FC<GuestNameDialogProps> = ({
  open,
  onValidate,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const { eventHost } = useGetHost();
  const router = useIonRouter();

  if (!event || !eventHost) return null;

  return (
    <Dialog open={open} fullWidth PaperProps={{ sx: { overflowY: 'visible' } }}>
      <Avatar
        sx={{
          height: 100,
          width: 100,
          bgcolor: theme.palette.primary.main,
          borderWidth: 4,
          borderColor: theme.palette.common.white,
          borderStyle: 'solid',
          transform: 'translateY(-50%)',
          mx: 'auto',
          mb: '-50px',
        }}
        src={eventHost.thumbnailURL || undefined}
      >
        <Email sx={{ fontSize: 60, color: theme.palette.common.white }} />
      </Avatar>

      <DialogTitle sx={{ textAlign: 'center' }} color="primary">
        {t('GUEST_DIALOG_TITLE', {
          name: getFullName(eventHost),
        })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('GUEST_DIALOG_TEXT', {
            firstName: formatName(eventHost.firstName),
          })}
        </DialogContentText>
        <FirstAndLastName
          sx={{ mt: 3 }}
          firstName={firstName}
          lastName={lastName}
          onFirstNameChange={setFirstName}
          onLastNameChange={setLastName}
        />
      </DialogContent>
      <DialogActions>
        {isApp && (
          <Button
            onClick={() =>
              router.canGoBack()
                ? router.goBack()
                : router.push(paths.HOME, 'root')
            }
          >
            {t('BACK')}
          </Button>
        )}
        <Button
          variant="contained"
          disabled={!isFirstAndLastNameValid(firstName, lastName)}
          onClick={async () => {
            await ls.set(LS_USER_NAME_KEY, { firstName, lastName });
            onValidate?.({ firstName, lastName });
          }}
        >
          {t('SEE_EVENT')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import {
  amber,
  blue,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';

const guestColors = [
  red[800],
  pink[800],
  purple[800],
  deepPurple[800],
  indigo[800],
  blue[800],
  lightBlue[800],
  cyan[800],
  teal[800],
  green[800],
  lightGreen[800],
  lime[800],
  yellow[800],
  amber[800],
  orange[800],
  deepOrange[800],
  brown[800],
];

export const getRandomGuestColor = () =>
  Math.floor(Math.random() * guestColors.length);

export const getGuestColor = (color: number) => guestColors[color] || grey[500];

import { IonContent, IonPage } from '@ionic/react';
import { Container, Link, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TopBar } from '../../components/TopBar';
import { theme } from '../../theme';
import { isApp } from '../../utils/platform';

const EMAIL = 'contact.hophop.app@gmail.com';

export const Contact: FC = () => {
  const { t } = useTranslation();

  return (
    <IonPage>
      {isApp && <TopBar title={t('CONTACT')} />}
      <IonContent>
        {!isApp && <TopBar />}
        <Container
          maxWidth="md"
          sx={{
            pb: `max(${theme.spacing(2)}, env(safe-area-inset-bottom))`,
            pt: 2,
            color: theme.palette.common.white,
          }}
        >
          <Typography variant="h3">{t('CONTACT')}</Typography>
          <Typography sx={{ mt: 2 }}>
            {t('SEND_MAIL')}
            {' : '}
            <Link color="secondary" href={`mailto:${EMAIL}`}>
              {EMAIL}
            </Link>
          </Typography>
        </Container>
      </IonContent>
    </IonPage>
  );
};

import {
  collection,
  limit as fsLimit,
  query as fsQuery,
  getCountFromServer,
  orderBy,
} from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCreate, useDelete, useGetList, useUpdate } from './useEntity';
import { useGetEvent } from './useEvent';
import { useGetGuest } from './useGuest';
import { getPostsCollection } from './usePost';

export interface Comment {
  id: string;
  updatedAt: Date | null;
  createdAt: Date;
  userId: string | null;
  message: string | null;
}

const COMMENTS_COLLECTION = 'comments';
const getCommentsCollection = (eventId: string, postId: string) =>
  collection(getPostsCollection(eventId), postId, COMMENTS_COLLECTION);

export const useGetComments = (
  postId: string | undefined,
  lastOne?: boolean,
  skip?: boolean,
) => {
  const { event } = useGetEvent();
  const query = useMemo(
    () =>
      event?.id && postId
        ? fsQuery(
            getCommentsCollection(event.id, postId),
            orderBy('createdAt', lastOne ? 'desc' : 'asc'),
            ...(lastOne ? [fsLimit(1)] : []),
          )
        : undefined,
    [event?.id, lastOne, postId],
  );
  const { data, loading, error } = useGetList<Comment>(query, skip);

  return { comments: data, loading, error };
};

export const useGetCommentsCount = (postId: string | undefined) => {
  const { event } = useGetEvent();
  const [commentsCount, setCommentsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const { comments } = useGetComments(postId, true);

  useEffect(() => {
    if (!event || !postId) return;
    setLoading(true);
    getCountFromServer(fsQuery(getCommentsCollection(event.id, postId)))
      .then((res) => setCommentsCount(res?.data().count || 0))
      .finally(() => setLoading(false));
  }, [event, postId, comments]);

  return { commentsCount, loading };
};

export const useCreateComment = () => {
  const { guest } = useGetGuest();
  const { create, loading } = useCreate();

  const createComment = useCallback(
    async ({
      eventId,
      postId,
      message,
      userId,
    }: {
      eventId: string;
      postId: string;
      message: string | null;
      userId: string | null;
    }) => {
      if (!guest) {
        throw new Error("Can't create Comment if guest is null");
      }

      return create<Comment>(getCommentsCollection(eventId, postId), {
        createdAt: new Date(),
        updatedAt: null,
        userId,
        message,
      });
    },
    [create, guest],
  );

  return { createComment, loading };
};

export const useUpdateComment = () => {
  const { update, loading } = useUpdate();

  const updateComment = useCallback(
    (
      eventId: string,
      postId: string,
      commentId: string,
      fields: Partial<Comment>,
    ) => update(getCommentsCollection(eventId, postId), commentId, fields),
    [update],
  );

  return { updateComment, loading };
};

export const useDeleteComment = () => {
  const { deleteEntity, loading } = useDelete();

  const deleteComment = useCallback(
    (eventId: string, postId: string, commentId: string) =>
      deleteEntity(getCommentsCollection(eventId, postId), commentId),
    [deleteEntity],
  );

  return { deleteComment, loading };
};

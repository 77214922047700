import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { storage } from '../firebase';
import { retryPromise } from '../utils/retryPromise';

enum ImageSize {
  SMALL = 400,
  LARGE = 1600,
}

const EVENTS_ROOT = 'events';
const BACKGROUND_IMAGE = 'background-image';
const BACKGROUND_IMAGE_MAX_SIZE_IN_MO = 30;

const getBackgroundImageRef = (eventId: string, size?: ImageSize) =>
  ref(
    storage,
    `${EVENTS_ROOT}/${eventId}/${BACKGROUND_IMAGE}${
      size ? `_${size}x${size}` : ''
    }`,
  );

export const useBackgroundImage = () => {
  const { t } = useTranslation();

  const uploadBackgroundImage = useCallback(
    async (eventId: string, file: File) => {
      if (file && file.size >= BACKGROUND_IMAGE_MAX_SIZE_IN_MO * 1024 * 1024) {
        throw new Error(
          t('MAX_IMAGE_SIZE', { size: BACKGROUND_IMAGE_MAX_SIZE_IN_MO }),
        );
      }

      try {
        await deleteObject(getBackgroundImageRef(eventId, ImageSize.SMALL));
        await deleteObject(getBackgroundImageRef(eventId, ImageSize.LARGE));
      } catch (e) {}

      await uploadBytes(getBackgroundImageRef(eventId), file);

      const small = await retryPromise(
        () => getDownloadURL(getBackgroundImageRef(eventId, ImageSize.SMALL)),
        1000,
        60,
      );

      const large = await retryPromise(
        () => getDownloadURL(getBackgroundImageRef(eventId, ImageSize.LARGE)),
        1000,
        60,
      );

      return { small, large };
    },
    [t],
  );

  const deleteBackgroundImage = useCallback(async (eventId: string) => {
    await deleteObject(getBackgroundImageRef(eventId, ImageSize.SMALL));
    await deleteObject(getBackgroundImageRef(eventId, ImageSize.LARGE));
  }, []);

  return { uploadBackgroundImage, deleteBackgroundImage };
};

const USERS_ROOT = 'users';
const PHOTO = 'photo';
const PHOTO_MAX_SIZE_IN_MO = 30;

const getUserPhotoRef = (userId: string, size?: ImageSize) =>
  ref(
    storage,
    `${USERS_ROOT}/${userId}/${PHOTO}${size ? `_${size}x${size}` : ''}`,
  );

export const useUserPhoto = () => {
  const { t } = useTranslation();

  const uploadUserPhoto = useCallback(
    async (userId: string, file: File) => {
      if (file && file.size >= PHOTO_MAX_SIZE_IN_MO * 1024 * 1024) {
        throw new Error(t('MAX_IMAGE_SIZE', { size: PHOTO_MAX_SIZE_IN_MO }));
      }

      try {
        await deleteObject(getUserPhotoRef(userId, ImageSize.SMALL));
        await deleteObject(getUserPhotoRef(userId, ImageSize.LARGE));
      } catch (e) {}

      await uploadBytes(getUserPhotoRef(userId), file);

      const small = await retryPromise(
        () => getDownloadURL(getUserPhotoRef(userId, ImageSize.SMALL)),
        1000,
        60,
      );

      const large = await retryPromise(
        () => getDownloadURL(getUserPhotoRef(userId, ImageSize.LARGE)),
        1000,
        60,
      );

      return { small, large };
    },
    [t],
  );

  return { uploadUserPhoto };
};

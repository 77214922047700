import { KeyboardArrowRight } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User, useSignIn } from '../../services/useAuth';
import { useNotification } from '../../utils/useNotification';
import { ConfirmationCode } from '../fields/ConfirmationCode';
import { Phone } from '../fields/Phone';
import { isConfirmationCodeValid, isPhoneValid } from '../fields/utils';

interface UserLoginDialogProps {
  open: boolean;
  onClose?: () => void;
  onCancel?: () => void;
  onSuccess?: (user: User) => void;
  title?: string;
  text?: string;
}

export const UserLoginDialog: FC<UserLoginDialogProps> = ({
  open,
  onClose,
  onCancel,
  onSuccess,
  title,
  text,
}) => {
  const { t } = useTranslation();
  const submitRef = useRef<HTMLButtonElement>(null);
  const { signIn, confirmCode } = useSignIn(submitRef.current);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [phoneStep, setPhoneStep] = useState(true);
  const { pushError } = useNotification();
  const [loading, setLoading] = useState(false);

  const onValidate = async () => {
    setLoading(true);
    try {
      if (phoneStep) {
        await signIn(phoneNumber);
        setPhoneStep(false);
      } else {
        const loggedUser = await confirmCode(confirmationCode);
        onClose?.();
        onSuccess?.(loggedUser);
      }
    } catch (e) {
      pushError(e);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>{title || t('SIGN_IN')}</DialogTitle>
      <DialogContent>
        {text && <DialogContentText sx={{ mb: 4 }}>{text}</DialogContentText>}
        {phoneStep ? (
          <Phone sx={{ mt: 1 }} value={phoneNumber} onChange={setPhoneNumber} />
        ) : (
          <ConfirmationCode
            sx={{ mt: 1 }}
            value={confirmationCode}
            onChange={setConfirmationCode}
            onValidate={onValidate}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onClose?.();
            onCancel?.();
          }}
        >
          {t('CANCEL')}
        </Button>
        <LoadingButton
          ref={submitRef}
          id={'UserLoginDialog-submit-button'}
          loading={loading}
          disabled={
            phoneStep
              ? !isPhoneValid(phoneNumber)
              : !isConfirmationCodeValid(confirmationCode)
          }
          variant="contained"
          onClick={onValidate}
          endIcon={phoneStep && <KeyboardArrowRight />}
        >
          {t(phoneStep ? 'CONTINUE' : 'SIGN_IN')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Icon,
  Paper,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AppIcon } from '../../images/HopHopIcon.svg';
import { useGetEvent } from '../../services/useEvent';
import {
  Answer,
  answers,
  useGetGuest,
  useUpdateGuest,
} from '../../services/useGuest';
import { theme } from '../../theme';
import { eventIsPast } from '../../utils/eventDate';
import { appStoreLink, canInstallApp } from '../../utils/platform';
import { Sx } from '../../utils/sx';
import { useDialog } from '../../utils/useDialog';
import { useGetHost } from '../../utils/useGetHost';
import { useNotification } from '../../utils/useNotification';
import { formatName } from '../../utils/userName';
import { DeclinePostDialog } from './DeclinePostDialog';

export const GuestAnswerPopUp: FC = () => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { guest } = useGetGuest();
  const { updateGuest } = useUpdateGuest();
  const { pushError } = useNotification();
  const [loadingIndex, setLoadingIndex] = useState<number>();
  const { eventHost } = useGetHost();

  const [openPostDialog, closePostDialog] = useDialog((open) => (
    <DeclinePostDialog open={open} onClose={closePostDialog} />
  ));

  if (
    !event ||
    !guest ||
    !eventHost ||
    eventIsPast(event) ||
    event?.canceledAt ||
    guest.answer === Answer.DO_NOT_PARTICIPATE ||
    (guest.answer && !canInstallApp)
  ) {
    return null;
  }

  const onUpdateAnswer = async (answer: Answer, index?: number) => {
    setLoadingIndex(index);
    try {
      await updateGuest(event.id, guest.id, { answer, answeredAt: new Date() });
      if (
        answer === Answer.DO_NOT_PARTICIPATE &&
        (guest.userId || !guest.hasPostedDecline)
      ) {
        openPostDialog();
      }
    } catch (e) {
      pushError(e);
    }
    setLoadingIndex(undefined);
  };

  const renderPopUp = (sx: Sx) => {
    return (
      <Paper
        sx={{
          width: '100%',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          p: 1,
          borderTop: `1px solid ${theme.palette.divider}`,
          pb: `max(${theme.spacing(1)}, env(safe-area-inset-bottom))`,
          ...sx,
        }}
        elevation={10}
      >
        {!guest.answer ? (
          [Answer.PARTICIPATE, Answer.MAYBE, Answer.DO_NOT_PARTICIPATE].map(
            (answ, i) => {
              const answerInfos = answ && answers[answ];
              const AnswerIcon = answerInfos?.Icon;
              return (
                <ThemeProvider
                  key={i}
                  theme={{
                    ...theme,
                    palette: {
                      ...theme.palette,
                      primary: { main: answerInfos?.color },
                    },
                  }}
                >
                  <LoadingButton
                    onClick={() => onUpdateAnswer(answ, i)}
                    loading={loadingIndex === i}
                    startIcon={<AnswerIcon />}
                    variant="contained"
                    sx={{
                      m: 1,
                      color: theme.palette.common.white,
                    }}
                  >
                    {answerInfos && t(answerInfos.labelMe)}
                  </LoadingButton>
                </ThemeProvider>
              );
            },
          )
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Button
              startIcon={
                <Icon sx={{ color: theme.palette.common.white }}>
                  <AppIcon />
                </Icon>
              }
              variant="contained"
              sx={{ m: 1 }}
              href={appStoreLink}
            >
              {t('OPEN_IN_APP')}
            </Button>
            <Typography variant="caption">
              {t('INSTALL_APP_TEXT', {
                hostName: formatName(eventHost?.firstName),
              })}
            </Typography>
          </Box>
        )}
      </Paper>
    );
  };

  return (
    <>
      {renderPopUp({
        position: 'fixed',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: theme.zIndex.speedDial,
        maxWidth: 900,
      })}
      {renderPopUp({ visibility: 'hidden', pointerEvents: 'none' })}
    </>
  );
};

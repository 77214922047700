import { App } from '@capacitor/app';
import { IonRouterOutlet, useIonRouter } from '@ionic/react';
import { FC, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Contact } from './pages/Contact';
import { Event } from './pages/Event';
import { Home } from './pages/Home';
import { MyEvents } from './pages/MyEvents';
import { NewEvent } from './pages/NewEvent';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { useGuestsInLS } from './services/useGuest';
import { appStoreLink, isAndroid, isApp, isIos } from './utils/platform';
import { useDeviceNotifications } from './utils/useDeviceNotifications';

export const paths = {
  HOME: '/',
  EVENT: '/e/:eventId',
  MY_EVENTS: '/my-events',
  NEW_EVENT: '/new',
  PRIVACY_POLICY: '/privacy-policy',
  CONTACT: '/contact',
  DOWNLOAD: '/download',
};

export const Router: FC = () => {
  const router = useIonRouter();
  useGuestsInLS();
  useDeviceNotifications();

  useEffect(() => {
    App.addListener('appUrlOpen', (event) => {
      const { pathname } = new URL(event.url);
      const currentPathname = window.location.pathname;

      if (pathname && pathname !== currentPathname) {
        router.push(pathname, 'none');
      }
    });
  }, [router]);

  return (
    <IonRouterOutlet>
      <Route path={paths.HOME} exact component={Home} />
      <Route path={paths.EVENT} exact component={Event} />
      <Route path={paths.MY_EVENTS} exact component={MyEvents} />
      <Route path={paths.NEW_EVENT} exact component={NewEvent} />
      <Route path={paths.PRIVACY_POLICY} exact component={PrivacyPolicy} />
      <Route path={paths.CONTACT} exact component={Contact} />
      <Route path={paths.DOWNLOAD} exact>
        {() => {
          if (!isApp && (isIos || isAndroid)) {
            // eslint-disable-next-line immutable/no-mutation
            window.location.href = appStoreLink;
          }
          return <Redirect to={paths.HOME} />;
        }}
      </Route>
      <Redirect to={paths.HOME} />
    </IonRouterOutlet>
  );
};

import { isEqual } from 'date-fns';
import { Event } from '../services/useEvent';

const fieldShouldBeUpdated = (
  field: keyof Event,
  oldEvent: Event,
  newEvent: Event,
) => {
  switch (field) {
    case 'title':
    case 'description':
    case 'locationPhotoURL':
    case 'backgroundImageURL':
    case 'thumbnailURL':
      return oldEvent[field] !== newEvent[field];

    case 'startDate':
    case 'endDate':
    case 'locationPhotoURLCreatedAt':
      return oldEvent[field]
        ? !isEqual(oldEvent[field] as Date, newEvent[field] as Date)
        : !!newEvent[field];

    case 'location':
      return (
        oldEvent.location?.description !== newEvent.location?.description ||
        oldEvent.location?.placeId !== newEvent.location?.placeId
      );

    default:
      return false;
  }
};

export const getEventFieldsToUpdate = (oldEvent: Event, newEvent: Event) => {
  let result: Partial<Event> = {};

  Object.entries(newEvent).forEach(([key, value]) => {
    if (fieldShouldBeUpdated(key as keyof Event, oldEvent, newEvent)) {
      result = { ...result, [key]: value };
    }
  });

  return result;
};

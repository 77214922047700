import { Device } from '@capacitor/device';
import { Locale, setDefaultOptions } from 'date-fns';
import { enUS as enUSLocale, fr as frLocale } from 'date-fns/locale';
import { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { isApp } from '../utils/platform';
import enTranslation from './en.json';
import frTranslation from './fr.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

const i18next = use(LanguageDetector).use(initReactI18next);

const locales: Record<string, Locale> = {
  fr: frLocale,
};

export const getDateFnsLocale = (language: string | undefined) =>
  (language && locales[language]) || enUSLocale;

i18next
  .init({
    fallbackLng: 'en',
    resources: {
      en: { translation: enTranslation },
      fr: { translation: frTranslation },
    },
    returnNull: false,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    if (isApp) {
      Device.getLanguageCode().then(({ value }) => {
        i18next.changeLanguage(value);
        setDefaultOptions({
          locale: getDateFnsLocale(value),
        });
      });
    } else {
      setDefaultOptions({
        locale: getDateFnsLocale(i18next.resolvedLanguage),
      });
    }
  });

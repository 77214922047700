import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { Add, Event } from '@mui/icons-material';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EventCard } from '../../components/EventCard';
import { SkeletonCard } from '../../components/SkeletonCard';
import { TopBar } from '../../components/TopBar';
import { ReactComponent as Icon } from '../../images/HopHopIcon.svg';
import { paths } from '../../router';
import { Answer, useGetMyGuestsEvents } from '../../services/useGuest';
import { theme } from '../../theme';
import { eventIsPast } from '../../utils/eventDate';
import { useAppBarHeight } from '../../utils/useAppBarHeight';
import { useDialog } from '../../utils/useDialog';
import { useRateApp } from '../../utils/useRateApp';
import { JoinEventDialog } from './JoinEventDialog';

export const AppHome: FC = () => {
  const { t } = useTranslation();
  const appBarHeight = useAppBarHeight();
  const router = useIonRouter();
  const { data, loading } = useGetMyGuestsEvents('asc');
  useRateApp();

  const nextEvents = data.filter(({ event, answer }) => {
    if (!event) return false;
    return (
      !eventIsPast(event) &&
      answer !== Answer.DO_NOT_PARTICIPATE &&
      !event.canceledAt
    );
  });

  const invitations = data.filter(({ event, answer }) => {
    if (!event) return false;
    return !eventIsPast(event) && answer === null && !event.canceledAt;
  }).length;

  const [openJoinEventDialog, closeJoinEventDialog] = useDialog((open) => (
    <JoinEventDialog open={open} onClose={closeJoinEventDialog} />
  ));

  return (
    <IonPage>
      <TopBar title="Hop Hop" invitations={invitations} />
      <IonContent>
        <Container
          maxWidth="md"
          sx={{
            pb: `calc(${theme.spacing(2)} + env(safe-area-inset-bottom))`,
            display: 'flex',
            flexDirection: 'column',
            minHeight: `calc(100% - ${appBarHeight}px)`,
          }}
          disableGutters
        >
          <Box
            component={Icon}
            sx={{
              px: 1,
              mt: 4,
              height: 100,
            }}
          >
            Logo
          </Box>
          <Typography
            variant="h4"
            sx={{
              color: theme.palette.common.white,
              px: 1,
              fontFamily: 'LeckerliOne',
              whiteSpace: 'nowrap',
              textAlign: 'center',
              mt: 1,
              mb: 'auto',
            }}
          >
            Hop Hop
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mt: 4,
              mb: 'auto',
            }}
          >
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              sx={{ mb: 2 }}
              startIcon={<Add />}
              onClick={() => router.push(paths.NEW_EVENT)}
            >
              {t('CREATE_EVENT')}
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              startIcon={<Event />}
              onClick={() => openJoinEventDialog()}
            >
              {t('JOIN_EVENT')}
            </Button>
          </Box>

          {(loading || !!nextEvents.length) && (
            <Typography
              variant="h6"
              color="secondary"
              sx={{
                p: 2,
              }}
            >
              {t('YOUR_NEXT_EVENTS')}
            </Typography>
          )}

          <Grid container spacing={2} sx={{ px: 2 }}>
            {loading
              ? Array.from(Array(5)).map((_, i) => (
                  <Grid key={i} xs={12} sm={6} md={4}>
                    <SkeletonCard />
                  </Grid>
                ))
              : nextEvents.map((guest) => (
                  <Grid key={guest.id} xs={12} sm={6} md={4}>
                    <EventCard guest={guest} />
                  </Grid>
                ))}
          </Grid>

          <Button
            variant="outlined"
            color="secondary"
            size="large"
            sx={{ alignSelf: 'center', mt: 4, mb: 'auto', mx: 2 }}
            onClick={() => router.push(paths.MY_EVENTS)}
          >
            {t('SEE_ALL_EVENTS')}
          </Button>
        </Container>
      </IonContent>
    </IonPage>
  );
};

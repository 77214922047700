import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FirstAndLastName } from '../../components/fields/FirstAndLastName';
import { isFirstAndLastNameValid } from '../../components/fields/utils';
import { useGetEvent } from '../../services/useEvent';
import { Guest, useUpdateGuest } from '../../services/useGuest';
import { useNotification } from '../../utils/useNotification';

interface AdminGuestReNameDialogProps {
  open: boolean;
  onClose: () => void;
  guest: Guest | undefined;
}

export const AdminGuestReNameDialog: FC<AdminGuestReNameDialogProps> = ({
  open,
  onClose,
  guest,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { updateGuest, loading } = useUpdateGuest();
  const [firstName, setFirstName] = useState(guest?.firstName || '');
  const [lastName, setLastName] = useState(guest?.lastName || '');
  const { pushError } = useNotification();

  if (!guest || !event) return null;

  const onValidate = async () => {
    try {
      await updateGuest(event.id, guest.id, {
        firstName,
        lastName,
      });
      onClose();
    } catch (e) {
      pushError(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t('EDIT_NAME')}</DialogTitle>
      <DialogContent>
        <FirstAndLastName
          sx={{ mt: 3 }}
          firstName={firstName}
          lastName={lastName}
          onFirstNameChange={setFirstName}
          onLastNameChange={setLastName}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          disabled={
            !isFirstAndLastNameValid(firstName, lastName) ||
            (firstName === guest.firstName && lastName === guest.lastName)
          }
          onClick={onValidate}
        >
          {t('APPLY')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

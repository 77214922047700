import { useIonRouter } from '@ionic/react';
import { Close, Email, History, Home, Place } from '@mui/icons-material';
import {
  Badge,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  SvgIconTypeMap,
  Typography,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { grey, red } from '@mui/material/colors';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { paths } from '../router';
import { useGetUser } from '../services/useAuth';
import { Guest, answers } from '../services/useGuest';
import { theme } from '../theme';
import { eventIsPast, formatStartEndDates } from '../utils/eventDate';
import { getDefaultImage } from '../utils/getDefaultImage';
import { Sx } from '../utils/sx';

interface EventCardProps {
  guest: Guest;
  sx?: Sx;
}

export const EventCard: FC<EventCardProps> = ({ guest, sx }) => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { event } = guest;
  const [firstDateLine] = formatStartEndDates(event, t);
  const eventPath = generatePath(paths.EVENT, { eventId: event?.id });
  const eventIsPastOrCanceled = eventIsPast(event) || !!event?.canceledAt;
  const dateColor = eventIsPastOrCanceled ? grey[500] : red[500];
  const guestAnswerInfos = guest.answer && answers[guest.answer];
  const { user } = useGetUser();
  const isOrganizer = user?.id === event?.userId;
  const answerColor =
    isOrganizer || !guestAnswerInfos
      ? theme.palette.primary.main
      : guestAnswerInfos.color;
  const AnswerIcon: OverridableComponent<
    SvgIconTypeMap<Record<string, unknown>, 'svg'>
  > = isOrganizer ? Home : guestAnswerInfos ? guestAnswerInfos.Icon : Email;

  if (!event) return null;

  return (
    <Card sx={{ height: '100%', ...sx }}>
      <CardActionArea
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
        href={eventPath}
        onClick={(e) => {
          e.preventDefault();
          router.push(eventPath);
        }}
      >
        <CardMedia
          image={event.thumbnailURL || getDefaultImage(event.id)}
          sx={{
            height: 150,
            backgroundColor: grey[300],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        />
        <CardContent
          sx={{ pt: 1, display: 'flex', flexDirection: 'column', flex: 1 }}
        >
          {eventIsPastOrCanceled && (
            <Typography
              color="GrayText"
              sx={{ display: 'flex', alignItems: 'center', fontSize: 14 }}
            >
              {event.canceledAt ? (
                <Close sx={{ fontSize: 16, mr: 1 }} />
              ) : (
                <History sx={{ fontSize: 16, mr: 1 }} />
              )}
              {t(event.canceledAt ? 'EVENT_IS_CANCELED' : 'EVENT_IS_PAST')}
            </Typography>
          )}
          <Typography sx={{ color: dateColor, fontSize: 17, fontWeight: 500 }}>
            {firstDateLine}
          </Typography>
          <Typography
            sx={{
              overflow: 'hidden',
              textDecoration: event.canceledAt ? 'line-through' : undefined,
              fontSize: 20,
              fontWeight: 500,
              maxHeight: '60px',
              mb: 1,
            }}
          >
            {event.title}
          </Typography>
          {event.locationName && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1,
              }}
            >
              <Place
                sx={{ mr: 0.4, color: theme.palette.grey[500], fontSize: 20 }}
              />
              <Typography
                component="span"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: 17,
                  fontWeight: 500,
                  color: theme.palette.grey[600],
                }}
              >
                {event.locationName}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              alignSelf: 'flex-end',
              mt: 'auto',
              border: `1px solid ${answerColor}`,
              px: 1,
              py: 0.5,
              borderRadius: '4px',
            }}
          >
            <Badge
              variant="dot"
              color="error"
              componentsProps={{
                badge: { style: { top: 5, right: 10 } },
              }}
              invisible={
                guest.answer !== null ||
                !!event.canceledAt ||
                eventIsPast(event)
              }
            >
              <AnswerIcon
                sx={{
                  color: answerColor,
                  mr: 1,
                }}
              />
            </Badge>
            <Typography
              sx={{
                color: answerColor,
              }}
            >
              {t(
                isOrganizer ? 'ORGANIZER' : guestAnswerInfos?.label || 'GUEST',
              )}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

import { StatusBar } from '@capacitor/status-bar';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { setupIonicReact } from '@ionic/react';
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './i18n';
import './index.css';
import { theme } from './theme';
import { isAndroid, isApp, isTablet } from './utils/platform';

setupIonicReact({ animated: isApp, scrollAssist: true });

if (isApp && isAndroid && !isTablet) {
  (window.screen.orientation as any).lock('portrait');
}

window.addEventListener('keyboardDidShow', () => {
  if (!isApp) return;
  document
    .querySelector(':focus')
    ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

defineCustomElements(window);

if (isAndroid) {
  StatusBar.setBackgroundColor({ color: theme.palette.primary.main });
}

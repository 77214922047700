import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import { FC } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { ModalProvider } from 'react-modal-hook';
import { RecoilRoot } from 'recoil';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { ErrorBoundary } from './components/ErrorBoundary';
import { GuestAnswerSnackbar } from './components/GuestAnswerSnackbar';
import { Head } from './components/Head';
import { getDateFnsLocale } from './i18n';
import { Router } from './router';
import { theme } from './theme';
import { FileUploadProvider } from './utils/fileUpload';

export const App: FC = () => {
  const { i18n } = useTranslation();

  return (
    <IonApp>
      <IonReactRouter>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <ErrorBoundary>
              <QueryParamProvider adapter={ReactRouter5Adapter}>
                <Head />
                <CssBaseline enableColorScheme />
                <RecoilRoot>
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={getDateFnsLocale(
                      i18n.resolvedLanguage || 'en',
                    )}
                  >
                    <SnackbarProvider
                      Components={{ guestAnswer: GuestAnswerSnackbar } as any}
                    >
                      <FileUploadProvider>
                        <ModalProvider>
                          <Router />
                        </ModalProvider>
                      </FileUploadProvider>
                    </SnackbarProvider>
                  </LocalizationProvider>
                </RecoilRoot>
              </QueryParamProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </HelmetProvider>
      </IonReactRouter>
    </IonApp>
  );
};

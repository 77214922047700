import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Comment, useDeleteComment } from '../../services/useComment';
import { useGetEvent } from '../../services/useEvent';
import { Post } from '../../services/usePost';
import { useNotification } from '../../utils/useNotification';

interface DeleteCommentDialogProps {
  open: boolean;
  onClose: () => void;
  comment: Comment;
  post: Post;
}

export const DeleteCommentDialog: FC<DeleteCommentDialogProps> = ({
  open,
  onClose,
  comment,
  post,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { deleteComment, loading } = useDeleteComment();
  const { pushError } = useNotification();

  const onDelete = async () => {
    if (!event) return;
    try {
      await deleteComment(event.id, post.id, comment.id);
      onClose();
    } catch (e) {
      pushError(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('DELETE_COMMENT')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('DELETE_COMMENT_TEXT')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          color="error"
          onClick={onDelete}
        >
          {t('DELETE')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { format, getDay, setHours, setMinutes } from 'date-fns';
import { TFunction } from 'i18next';
import { Event } from '../services/useEvent';
import { Location } from '../services/useGooglePlaces';

export const getLocationName = (location: Location) =>
  location.details?.name || location.description;

export const getLocationAddress = (location: Location) => {
  let result = [location.description];

  if (location.details?.formatted_address) {
    if (
      !location.details?.name ||
      location.details?.formatted_address.includes(location.details?.name)
    ) {
      result = [location.details?.formatted_address];
    } else {
      result = [location.details?.name, location.details?.formatted_address];
    }
  }

  return result.join(', ');
};

const formatHours = (date: Date, hours: number, minutes: number) =>
  format(setMinutes(setHours(date, hours), minutes), 'p');

export const getOpeningHours = (
  event: Event | undefined,
  t: TFunction<'translation', undefined>,
) => {
  const openingHours = event?.location?.details?.openingHours;
  if (!event || !openingHours) return undefined;

  const openHours = openingHours
    .filter(({ open }) => open.day === getDay(event.startDate))
    .map(({ open, close }) => {
      const res = [
        t(close ? 'FROM' : 'AT'),
        formatHours(event.startDate, open.hours, open.minutes),
        ...(close
          ? [t('TO'), formatHours(event.startDate, close.hours, close.minutes)]
          : []),
      ];
      return res.join(' ');
    })
    .join(` ${t('AND')} `);

  const onDayOfTheWeek = `${t('ON')} ${format(event.startDate, 'eeee')}`;

  if (openHours.length) {
    return {
      open: true,
      text: `${t('OPEN')} ${openHours} ${onDayOfTheWeek}`,
    };
  }
  return { open: false, text: `${t('CLOSED')} ${onDayOfTheWeek}` };
};

import { isPlatform } from '@ionic/react';

export const IOS_STORE_LINK =
  'https://apps.apple.com/fr/app/hop-hop/id6456521394';
export const ANDROID_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.victorjouin.event';

export const isApp = isPlatform('capacitor');
export const isAndroid = isPlatform('android');
export const isIos = isPlatform('ios');
export const isTablet = isPlatform('tablet');

export const canInstallApp = (isIos || isAndroid) && !isApp;

export const appStoreLink = isIos ? IOS_STORE_LINK : ANDROID_STORE_LINK;

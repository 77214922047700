import { compareTwoStrings } from 'string-similarity';

const normalizeString = (string: string) =>
  string
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

const removeSpaces = (string: string) => string.replace(/[^a-zA-Z]/g, '');

const getStringInitials = (string: string) =>
  normalizeString(string)
    .split(/[\s-]+/)
    .map((word) => word[0])
    .join('');

export const stringsAreSimilar = (string1: string, string2: string) => {
  const cleanedString1 = removeSpaces(normalizeString(string1));
  const cleanedString2 = removeSpaces(normalizeString(string2));

  const initials1 = getStringInitials(string1);
  const initials2 = getStringInitials(string2);

  return (
    cleanedString1.includes(cleanedString2) ||
    cleanedString2.includes(cleanedString1) ||
    (initials1.length > 1 && initials1 === cleanedString2) ||
    (initials2.length > 1 && initials2 === cleanedString1) ||
    (initials1.length > 1 && initials1 === initials2) ||
    compareTwoStrings(string1, string2) >= 0.7
  );
};

import { Edit, MoreHoriz, PersonRemove, Report } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { isBefore } from 'date-fns';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GuestAvatar } from '../../components/GuestAvatar';
import { UserGuestMenu } from '../../components/UserGuestMenu';
import { UserLoginDialog } from '../../components/user/UserLoginDialog';
import { UserProfileDialog } from '../../components/user/UserProfileDialog';
import { useGetEvent } from '../../services/useEvent';
import {
  Answer,
  Guest,
  answers,
  useGetGuest,
  useGetGuests,
} from '../../services/useGuest';
import { theme } from '../../theme';
import { useDialog } from '../../utils/useDialog';
import { useGetHost } from '../../utils/useGetHost';
import { useMenu } from '../../utils/useMenu';
import { getFullName } from '../../utils/userName';
import { AdminGuestDeleteDialog } from './AdminGuestDeleteDialog';
import { AdminGuestReNameDialog } from './AdminGuestReNameDialog';
import { DeclinePostDialog } from './DeclinePostDialog';
import { GuestAnswerMenu } from './GuestAnswerMenu';
import { GuestReNameDialog } from './GuestReNameDialog';
import { ReportDialog } from './ReportPostDialog';

interface GuestListDialogProps {
  open: boolean;
  onClose: () => void;
  selectedTab?: number;
}

export const GuestListDialog: FC<GuestListDialogProps> = ({
  open: dialogOpen,
  onClose,
  selectedTab,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { guests } = useGetGuests();
  const { guest } = useGetGuest();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isHost } = useGetHost();
  const [activeTab, setActiveTab] = useState(selectedTab || 0);
  const [guestBeeingEdited, setGuestBeeingEdited] = useState<Guest>();

  const [openRenameDialog, closeRenameDialog] = useDialog((open) => (
    <AdminGuestReNameDialog
      open={open}
      onClose={closeRenameDialog}
      guest={guestBeeingEdited}
    />
  ));

  const [openDeleteDialog, closeDeleteDialog] = useDialog((open) => (
    <AdminGuestDeleteDialog
      open={open}
      onClose={closeDeleteDialog}
      guest={guestBeeingEdited}
    />
  ));

  const [openDeclinePostDialog, closeDeclinePostDialog] = useDialog((open) => (
    <DeclinePostDialog open={open} onClose={closeDeclinePostDialog} />
  ));

  const [openReportDialog, closeReportDialog] = useDialog((open) => (
    <ReportDialog open={open} onClose={closeReportDialog} />
  ));

  const [openLoginDialog, closeLoginDialog] = useDialog((open) => (
    <UserLoginDialog open={open} onClose={closeLoginDialog} />
  ));

  const [openUserDialog, closeUserDialog] = useDialog((open) => (
    <UserProfileDialog open={open} onClose={closeUserDialog} />
  ));

  const [openUserRenameDialog, closeUserRenameDialog] = useDialog((open) => (
    <GuestReNameDialog
      open={open}
      onClose={closeUserRenameDialog}
      onOpenLogin={() => {
        openUserDialog();
        openLoginDialog();
      }}
    />
  ));

  const answerList = [
    Answer.PARTICIPATE,
    Answer.MAYBE,
    null,
    Answer.DO_NOT_PARTICIPATE,
  ];

  const [openMenu, closeMenu] = useMenu<Guest>((guestItem) =>
    event && guestItem ? (
      <Box>
        {guestItem.userId !== event.userId &&
          (guestItem.id === guest?.id || isHost) && (
            <GuestAnswerMenu
              guest={guestItem}
              onClose={closeMenu}
              openDeclinePostDialog={openDeclinePostDialog}
            />
          )}

        {guestItem.id === guest?.id ? (
          <UserGuestMenu
            onClose={closeMenu}
            openUserDialog={openUserDialog}
            openLoginDialog={openLoginDialog}
            openRenameDialog={openUserRenameDialog}
          />
        ) : (
          <>
            {isHost && (
              <>
                {!guestItem.userId && (
                  <MenuItem
                    onClick={() => {
                      setGuestBeeingEdited(guestItem);
                      openRenameDialog();
                      closeMenu();
                    }}
                  >
                    <ListItemIcon>
                      <Edit fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('EDIT_NAME')}</ListItemText>
                  </MenuItem>
                )}
                <MenuItem
                  onClick={() => {
                    setGuestBeeingEdited(guestItem);
                    openDeleteDialog();
                    closeMenu();
                  }}
                >
                  <ListItemIcon>
                    <PersonRemove fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('DELETE_GUEST')}</ListItemText>
                </MenuItem>
              </>
            )}
            <MenuItem
              onClick={() => {
                openReportDialog();
                closeMenu();
              }}
            >
              <ListItemIcon>
                <Report />
              </ListItemIcon>
              <ListItemText>{t('BLOCK_REPORT')}</ListItemText>
            </MenuItem>
          </>
        )}
      </Box>
    ) : null,
  );

  if (!event || !guests) return null;

  const filteredGuests = [
    ...guests.filter(({ answer }) => answer === answerList[activeTab] || null),
  ].sort((guestA, guestB) => {
    if (guestA.answer === guestB.answer) {
      if (guestA.answeredAt && guestB.answeredAt) {
        return isBefore(guestA.answeredAt, guestB.answeredAt) ? -1 : 1;
      }
      return isBefore(guestA.createdAt, guestB.createdAt) ? -1 : 1;
    }
    return 0;
  });

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      fullWidth
      fullScreen={isMobile}
      PaperProps={isMobile ? undefined : { sx: { height: '80%' } }}
    >
      <DialogTitle>{t('GUESTS')}</DialogTitle>
      <Tabs
        value={activeTab}
        onChange={(_, val) => setActiveTab(val)}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        {answerList.map((answ, i) => {
          const answerInfos = answ && answers[answ];
          const guestsCount = guests?.filter(
            ({ answer }) => answer === answ,
          ).length;
          return (
            <Tab
              wrapped={isMobile}
              sx={{ flex: 1, px: 1 }}
              key={i}
              label={`${t(
                guestsCount && guestsCount > 1
                  ? answerInfos?.labelPlural || 'GUESTS'
                  : answerInfos?.label || 'GUEST',
              )} (${guestsCount})`}
            />
          );
        })}
      </Tabs>

      <DialogContent sx={{ height: '100%' }}>
        <List>
          {filteredGuests.length ? (
            filteredGuests.map((guestItem) => {
              const answerInfos = guestItem.answer && answers[guestItem.answer];
              return (
                <ListItem
                  key={guestItem.id}
                  disablePadding
                  secondaryAction={
                    <IconButton
                      edge="end"
                      onClick={(e) => openMenu(e, guestItem)}
                    >
                      <MoreHoriz />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <GuestAvatar guest={guestItem} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={getFullName(guestItem)}
                    secondary={t(
                      event?.userId === guestItem.userId
                        ? 'ORGANIZER'
                        : answerInfos?.label || 'GUEST',
                    )}
                  />
                </ListItem>
              );
            })
          ) : (
            <Typography color="GrayText" sx={{ textAlign: 'center' }}>
              {t('NO_GUEST')}
            </Typography>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CLOSE')}</Button>
      </DialogActions>
    </Dialog>
  );
};

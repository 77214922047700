import { Link } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import Linkify from 'react-linkify';

export const LinkifiedText: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Linkify
      componentDecorator={(href, text, key) => (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          color="primary"
        >
          {text}
        </Link>
      )}
    >
      {children}
    </Linkify>
  );
};

const wait = (delay: number) => new Promise((r) => setTimeout(r, delay));

export const retryPromise = <T>(
  operation: () => Promise<T>,
  delay: number,
  retries: number,
): Promise<T> =>
  new Promise<T>((resolve, reject) =>
    operation()
      .then(resolve)
      .catch((reason) => {
        if (retries > 0) {
          return wait(delay)
            .then(() => retryPromise(operation, delay, retries - 1))
            .then(resolve)
            .catch(reject);
        }
        return reject(reason);
      }),
  );

import { Dispatch, SetStateAction } from 'react';

export type Updater<T> = Dispatch<SetStateAction<T>>;

export function getValue<T>(updater: SetStateAction<T>, prev: T) {
  if (typeof updater === 'function') {
    const typedUpdater = updater as (prevState: T) => T;
    return typedUpdater(prev);
  }
  return updater;
}

import { addHours } from 'date-fns';
import { CalendarOptions, ICalendar } from 'datebook';
import FileSaver from 'file-saver';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Event } from '../services/useEvent';
import { getLocationAddress } from './eventLocation';
import { getEventUrl } from './eventUrl';
import { isApp, isIos } from './platform';

export const useAddToCalendar = () => {
  const { t } = useTranslation();

  const addToCalendar = useCallback(
    (event: Event) =>
      new Promise<void>((resolve) => {
        const calendarEvent: CalendarOptions = {
          title: event.title,
          location: event.location
            ? getLocationAddress(event.location)
            : undefined,
          description: [
            ...(event.description ? [event.description] : []),
            `${t('CALENDAR_DESCRIPTION_URL', {
              url: getEventUrl(event.id),
            })}`,
            `${t('CALENDAR_DESCRIPTION_CREATED_WITH')}`,
          ].join('\n\n'),
          start: event.startDate,
          end: event.endDate || addHours(event.startDate, 1),
        };

        if (isApp) {
          isIos && (window as any).plugins?.calendar?.requestWritePermission();
          // When plugin fixed, replace the 2nd resolve by reject
          (window as any).plugins?.calendar?.createEventInteractively(
            calendarEvent.title,
            calendarEvent.location,
            calendarEvent.description,
            calendarEvent.start,
            calendarEvent.end,
            resolve,
            resolve,
          );
          return;
        }

        const calendar = new ICalendar(calendarEvent);
        const ics = calendar.render();
        const blob = new Blob([ics], {
          type: 'text/calendar',
        });

        FileSaver.saveAs(blob, `${event.title}.ics`);
        resolve();
      }),
    [t],
  );

  return addToCalendar;
};

import { AddPhotoAlternate, Collections, HideImage } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import { isAfter, isFuture, isPast } from 'date-fns';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventDateTime } from '../../components/fields/EventDateTime';
import { EventDescription } from '../../components/fields/EventDescription';
import { EventLocation } from '../../components/fields/EventLocation';
import { EventTitle } from '../../components/fields/EventTitle';
import {
  isEventDateTimeValid,
  isEventDescriptionValid,
  isEventLocationValid,
  isEventTitleValid,
} from '../../components/fields/utils';
import { Event, useUpdateEvent } from '../../services/useEvent';
import { useBackgroundImage } from '../../services/useStorage';
import { theme } from '../../theme';
import { eventIsPast } from '../../utils/eventDate';
import { useFileUpload } from '../../utils/fileUpload';
import { getDefaultImage } from '../../utils/getDefaultImage';
import { getEventFieldsToUpdate } from '../../utils/getEventFieldsToUpdate';
import { useNotification } from '../../utils/useNotification';
import { getValue } from '../../utils/valOrUpdater';

interface EditDialogProps {
  open: boolean;
  onClose: () => void;
  event: Event;
}

export const EditDialog: FC<EditDialogProps> = ({ open, onClose, event }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [internalEvent, setInternalEvent] = useState<Event>(event);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [locationOpen, setLocationOpen] = useState(false);
  const { updateEvent } = useUpdateEvent();
  const { uploadBackgroundImage, deleteBackgroundImage } = useBackgroundImage();
  const { pushError } = useNotification();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const evIsPast = eventIsPast(event);
  const { openFileSelector } = useFileUpload();

  const saveDisabled = useMemo(() => {
    const fieldsToUpdate = getEventFieldsToUpdate(event, internalEvent);
    const hasChanged = Object.keys(fieldsToUpdate).length;

    const getConditions = () => {
      const conditionsIfPastEvent = [
        isEventDescriptionValid(internalEvent.description),
      ];

      if (evIsPast) {
        return conditionsIfPastEvent;
      }

      const conditionsIfCurrentOrIncomingEvent = [
        ...conditionsIfPastEvent,
        isEventTitleValid(internalEvent.title),
        isEventLocationValid(internalEvent.location),
      ];

      if (isPast(event.startDate)) {
        return [
          ...conditionsIfCurrentOrIncomingEvent,
          !internalEvent.endDate ||
            isAfter(internalEvent.endDate, internalEvent.startDate),
          !fieldsToUpdate.startDate || isFuture(internalEvent.startDate),
        ];
      }

      return [
        ...conditionsIfCurrentOrIncomingEvent,
        isEventDateTimeValid(internalEvent.startDate, internalEvent.endDate),
      ];
    };

    return !hasChanged || getConditions().includes(false);
  }, [event, internalEvent, evIsPast]);

  const onSave = async () => {
    setLoading(true);
    let backgroundImageURL = internalEvent.backgroundImageURL || null;
    let thumbnailURL = internalEvent.thumbnailURL || null;
    try {
      if (imageFile) {
        const { small, large } = await uploadBackgroundImage(
          event.id,
          imageFile,
        );
        thumbnailURL = small;
        backgroundImageURL = large;
      } else if (!backgroundImageURL && event.backgroundImageURL) {
        await deleteBackgroundImage(event.id);
      }
      await updateEvent(
        event.id,
        getEventFieldsToUpdate(event, {
          ...internalEvent,
          backgroundImageURL,
          thumbnailURL,
        }),
      );
      onClose();
    } catch (e) {
      pushError(e);
    }
    setLoading(false);
  };

  const onFileUpload = (files: File[] | null) => {
    if (!files) return;
    setImageFile(files[0]);
    const reader = new FileReader();
    // eslint-disable-next-line immutable/no-mutation
    reader.onload = ({ target }) => {
      setInternalEvent((prev) => ({
        ...prev,
        backgroundImageURL: (target?.result as string) || null,
        thumbnailURL: (target?.result as string) || null,
      }));
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth fullScreen={isMobile}>
      <DialogTitle>{t('EDIT_EVENT')}</DialogTitle>
      <DialogContent sx={{ pb: locationOpen ? 10 : undefined }}>
        <Card
          sx={{
            backgroundImage: `url("${
              internalEvent.backgroundImageURL ||
              getDefaultImage(internalEvent.id)
            }")`,
            height: 200,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
            p: 2,
            backgroundColor: theme.palette.background.default,
          }}
        >
          {internalEvent.backgroundImageURL ? (
            <>
              <Tooltip title={t('REPLACE_IMAGE')}>
                <Fab
                  size="small"
                  color="primary"
                  onClick={(e) => openFileSelector(e, onFileUpload)}
                >
                  <Collections />
                </Fab>
              </Tooltip>
              <Tooltip title={t('DELETE_IMAGE')}>
                <Fab
                  size="small"
                  color="error"
                  sx={{ ml: 2 }}
                  onClick={() => {
                    setInternalEvent((prev) => ({
                      ...prev,
                      backgroundImageURL: null,
                      thumbnailURL: null,
                    }));
                    setImageFile(null);
                  }}
                >
                  <HideImage />
                </Fab>
              </Tooltip>
            </>
          ) : (
            <Button
              onClick={(e) => openFileSelector(e, onFileUpload)}
              variant="outlined"
              color="secondary"
              startIcon={<AddPhotoAlternate />}
              sx={{ mx: 'auto', alignSelf: 'center' }}
            >
              {t('ADD_IMAGE')}
            </Button>
          )}
        </Card>

        <EventTitle
          disabled={evIsPast}
          errorIfEmpty
          sx={{ mt: 4 }}
          value={internalEvent.title}
          onChange={(title) => setInternalEvent((prev) => ({ ...prev, title }))}
        />

        <EventDateTime
          disabled={evIsPast}
          sx={{ mt: 4 }}
          startDate={internalEvent.startDate}
          endDate={internalEvent.endDate}
          onStartDateChange={(startDate) =>
            setInternalEvent((prev) => ({
              ...prev,
              startDate,
            }))
          }
          onEndDateChange={(endDate) =>
            setInternalEvent((prev) => ({
              ...prev,
              endDate,
            }))
          }
          defaultEndDate={event.endDate}
        />

        <EventLocation
          disabled={evIsPast}
          sx={{ mt: 4 }}
          value={internalEvent}
          onChange={(updater) =>
            setInternalEvent((prev) => ({
              ...prev,
              ...getValue(updater, prev),
            }))
          }
          setOpen={setLocationOpen}
        />

        <EventDescription
          sx={{ mt: 4 }}
          value={internalEvent.description}
          onChange={(description) =>
            setInternalEvent((prev) => ({ ...prev, description }))
          }
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <LoadingButton
          disabled={saveDisabled}
          variant="contained"
          loading={loading}
          onClick={onSave}
        >
          {t('APPLY')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

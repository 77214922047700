import { Delete, Edit, MoreHoriz, Report, Send } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandableText } from '../../components/ExpandableText';
import { GuestAvatar } from '../../components/GuestAvatar';
import { PostCreatedAt } from '../../components/PostCreatedAt';
import { PostHeader } from '../../components/PostHeader';
import {
  COMMENT_MAX_CHARACTERS,
  getPostFontSize,
  isCommentValid,
} from '../../components/fields/utils';
import { UserLoginDialog } from '../../components/user/UserLoginDialog';
import { User, useGetUser } from '../../services/useAuth';
import {
  Comment,
  useCreateComment,
  useGetComments,
  useGetCommentsCount,
} from '../../services/useComment';
import { useGetEvent } from '../../services/useEvent';
import { useGetGuests } from '../../services/useGuest';
import { Post, Range, useUpdatePost } from '../../services/usePost';
import { theme } from '../../theme';
import { Sx } from '../../utils/sx';
import { useDialog } from '../../utils/useDialog';
import { useMenu } from '../../utils/useMenu';
import { useNotification } from '../../utils/useNotification';
import { getFullName } from '../../utils/userName';
import { DeleteCommentDialog } from './DeleteCommentDialog copy';
import { DeletePostDialog } from './DeletePostDialog';
import { EditCommentDialog } from './EditCommentDialog';
import { EditPostDialog } from './EditPostDialog';
import { ReportDialog } from './ReportPostDialog';

interface PostDisplayProps {
  post?: Post;
  loading?: boolean;
  sx?: Sx;
}

export const PostDisplay: FC<PostDisplayProps> = ({ post, loading, sx }) => {
  const { t } = useTranslation();
  const { user } = useGetUser();
  const { event } = useGetEvent();
  const { guests } = useGetGuests();
  const postAuthor = guests?.find(({ id, userId }) =>
    userId ? userId === post?.userId : id === post?.guestId,
  );
  const { updatePost, loading: updateLoading } = useUpdatePost();
  const { pushError } = useNotification();
  const isPostCreator = user && user.id === post?.userId;
  const isEventAdmin = user && user.id === event?.userId;
  const [expandedComments, setExpandedComments] = useState(false);
  const { comments, loading: loadingComments } = useGetComments(
    post?.id,
    !expandedComments,
  );
  const [commentMessage, setCommentMessage] = useState('');
  const { createComment, loading: createCommentLoading } = useCreateComment();
  const { commentsCount } = useGetCommentsCount(post?.id);

  const [openEditDialog, closeEditDialog] = useDialog((open) =>
    post ? (
      <EditPostDialog open={open} onClose={closeEditDialog} post={post} />
    ) : null,
  );

  const [openDeleteDialog, closeDeleteDialog] = useDialog((open) =>
    post ? (
      <DeletePostDialog open={open} onClose={closeDeleteDialog} post={post} />
    ) : null,
  );

  const [openReportDialog, closeReportDialog] = useDialog((open) => (
    <ReportDialog open={open} onClose={closeReportDialog} />
  ));

  const [openLoginDialog, closeLoginDialog] = useDialog((open) => (
    <UserLoginDialog
      open={open}
      onClose={closeLoginDialog}
      title={t('LOGIN_TO_COMMENT')}
      onSuccess={(loggedUser) => onCreateComment(loggedUser)}
    />
  ));

  const [openDeleteCommentDialog, closeDeleteCommentDialog] =
    useDialog<Comment>((open, comment) =>
      post && comment ? (
        <DeleteCommentDialog
          open={open}
          onClose={closeDeleteCommentDialog}
          post={post}
          comment={comment}
        />
      ) : null,
    );

  const [openEditCommentDialog, closeEditCommentDialog] = useDialog<Comment>(
    (open, comment) =>
      post && comment ? (
        <EditCommentDialog
          open={open}
          onClose={closeEditCommentDialog}
          post={post}
          comment={comment}
        />
      ) : null,
  );

  const [openPostMenu, closePostMenu] = useMenu(
    <Box>
      {isPostCreator && (
        <MenuItem
          onClick={() => {
            openEditDialog();
            closePostMenu();
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>{t('EDIT')}</ListItemText>
        </MenuItem>
      )}
      {(isPostCreator || isEventAdmin) && (
        <MenuItem
          onClick={() => {
            openDeleteDialog();
            closePostMenu();
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <ListItemText>{t('DELETE')}</ListItemText>
        </MenuItem>
      )}
      {!isPostCreator && (
        <MenuItem
          onClick={() => {
            openReportDialog();
            closePostMenu();
          }}
        >
          <ListItemIcon>
            <Report />
          </ListItemIcon>
          <ListItemText>{t('BLOCK_REPORT')}</ListItemText>
        </MenuItem>
      )}
    </Box>,
  );

  const [openCommentMenu, closeCommentMenu] = useMenu<Comment>((comment) => {
    const isCommentCreator = comment?.userId === user?.id;

    return (
      <Box>
        {isCommentCreator && (
          <MenuItem
            onClick={() => {
              comment && openEditCommentDialog(comment);
              closeCommentMenu();
            }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>{t('EDIT')}</ListItemText>
          </MenuItem>
        )}
        {(isCommentCreator || isEventAdmin) && (
          <MenuItem
            onClick={() => {
              comment && openDeleteCommentDialog(comment);
              closeCommentMenu();
            }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>{t('DELETE')}</ListItemText>
          </MenuItem>
        )}
        {!isCommentCreator && (
          <MenuItem
            onClick={() => {
              openReportDialog();
              closeCommentMenu();
            }}
          >
            <ListItemIcon>
              <Report />
            </ListItemIcon>
            <ListItemText>{t('BLOCK_REPORT')}</ListItemText>
          </MenuItem>
        )}
      </Box>
    );
  });

  const onRangeChange = async (range: Range) => {
    if (!event || !post) return;
    try {
      await updatePost(event.id, post.id, { range });
    } catch (e) {
      pushError(e);
    }
  };

  const onCreateComment = async (loggedUser: User | null) => {
    if (!event || !post || !isCommentValid(commentMessage)) return;

    if (!loggedUser) {
      openLoginDialog();
      return;
    }

    try {
      await createComment({
        eventId: event.id,
        postId: post.id,
        userId: loggedUser.id,
        message: commentMessage.trim(),
      });
      setExpandedComments(true);
      setCommentMessage('');
    } catch (e) {
      pushError(e);
    }
  };

  return (
    <Paper sx={{ p: 2, ...sx }}>
      {post && !loading ? (
        <>
          <Box
            sx={{
              mb: 2,
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <PostHeader
              sx={{ minWidth: 0 }}
              guest={postAuthor || null}
              post={post}
              onRangeChange={
                user?.id === post?.userId ? onRangeChange : undefined
              }
              loading={updateLoading}
            />
            <IconButton onClick={openPostMenu}>
              <MoreHoriz />
            </IconButton>
          </Box>
          {post.message && (
            <ExpandableText
              text={post.message}
              linkified
              TypographyProps={{
                sx: {
                  whiteSpace: 'pre-line',
                  fontSize: getPostFontSize(post.message),
                },
              }}
            />
          )}

          {!!commentsCount && (
            <Typography
              variant="body2"
              color="GrayText"
              sx={{ textAlign: 'right', mt: 1 }}
              component="div"
            >
              {t('COMMENT_COUNT', {
                count: commentsCount,
              })}
            </Typography>
          )}

          {(comments?.length || loadingComments) && (
            <>
              <Divider sx={{ my: 1 }} />
              {loadingComments && (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Skeleton variant="circular" height={40} width={40} />
                  <Skeleton height={40} width="80%" sx={{ ml: 2 }} />
                </Box>
              )}

              {comments &&
                commentsCount > comments.length &&
                !expandedComments && (
                  <Typography
                    sx={{ cursor: 'pointer', fontWeight: 800 }}
                    onClick={() => setExpandedComments(true)}
                  >
                    {t('SEE_PREVIOUS_COMMENTS')}
                  </Typography>
                )}

              {comments?.map((comment) => {
                const commentAuthor = guests?.find(
                  ({ userId }) => comment.userId === userId,
                );

                return (
                  <Box
                    key={comment.id}
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      mt: 1.5,
                    }}
                  >
                    <GuestAvatar guest={commentAuthor || null} />
                    <Box
                      sx={{
                        ml: 1,
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box
                          sx={{
                            bgcolor: theme.palette.grey[200],
                            p: 1,
                            borderRadius: 3,
                            mr: 1,
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: '800' }}
                          >
                            {commentAuthor
                              ? getFullName(commentAuthor)
                              : t('REMOVED_GUEST')}
                          </Typography>
                          <ExpandableText
                            text={comment.message || ''}
                            linkified
                            TypographyProps={{
                              variant: 'body2',
                              sx: {
                                whiteSpace: 'pre-line',
                              },
                            }}
                            sx={{ maxWidth: 500 }}
                          />
                        </Box>
                        <IconButton
                          onClick={(e) => openCommentMenu(e, comment)}
                        >
                          <MoreHoriz />
                        </IconButton>
                      </Box>
                      <PostCreatedAt
                        updatedAt={comment.updatedAt}
                        createdAt={comment.createdAt}
                        sx={{ mt: 0.5 }}
                      />
                    </Box>
                  </Box>
                );
              })}
            </>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <TextField
              fullWidth
              size="small"
              placeholder={t('WRITE_COMMENT')}
              value={commentMessage}
              onChange={({ target }) => setCommentMessage(target.value)}
              disabled={createCommentLoading}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  onCreateComment(user);
                }
              }}
              multiline
              maxRows={3}
              error={!!commentMessage.trim() && !isCommentValid(commentMessage)}
              helperText={
                !!commentMessage.trim() &&
                !isCommentValid(commentMessage) &&
                t('MAX_CHARACTERS', {
                  maxCharacters: COMMENT_MAX_CHARACTERS,
                })
              }
            />
            {!!commentMessage.trim() &&
              (createCommentLoading ? (
                <CircularProgress size={20} sx={{ ml: 2 }} />
              ) : (
                <Tooltip title={t('SEND')}>
                  <IconButton
                    sx={{ ml: 2 }}
                    onClick={() => onCreateComment(user)}
                  >
                    <Send />
                  </IconButton>
                </Tooltip>
              ))}
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Skeleton variant="circular" height={40} width={40} />
            <Box sx={{ ml: 1 }}>
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={80} sx={{ mt: 0.5 }} />
            </Box>
          </Box>
          <Skeleton variant="rounded" height={100} width="100%" />
        </>
      )}
    </Paper>
  );
};

import { Avatar, Badge, BadgeProps, styled, Tooltip } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetEvent } from '../services/useEvent';
import { answers, Guest } from '../services/useGuest';
import { theme } from '../theme';
import { getGuestColor } from '../utils/guestColor';
import { Sx } from '../utils/sx';
import { useDialog } from '../utils/useDialog';
import { getFullName, getInitials } from '../utils/userName';
import { ImageDialog } from './ImageDialog';

const AnswerBadge = styled(Badge)<BadgeProps & { bgcolor?: string }>(
  ({ bgcolor }) => ({
    '& .MuiBadge-badge': {
      padding: 0,
      border: `1px solid ${theme.palette.common.white}`,
      backgroundColor: bgcolor,
      color: theme.palette.common.white,
      height: 15,
      width: 15,
      minWidth: 0,
    },
  }),
);

interface GuestAvatarProps {
  guest: Guest | null;
  disableClick?: boolean;
  sx?: Sx;
}

export const GuestAvatar = forwardRef<HTMLSpanElement, GuestAvatarProps>(
  ({ guest, disableClick, sx, ...props }, ref) => {
    const { t } = useTranslation();
    const { event } = useGetEvent();

    const [openDialog, closeDialog] = useDialog((open) =>
      guest?.photoURL ? (
        <ImageDialog open={open} onClose={closeDialog} src={guest.photoURL} />
      ) : null,
    );

    if (!guest) {
      return (
        <Avatar
          sx={{
            bgcolor: theme.palette.grey[500],
            color: theme.palette.common.white,
            ...sx,
          }}
        />
      );
    }

    const answerInfos = guest.answer && answers[guest.answer];
    const Icon = answerInfos?.Icon;

    return (
      <Tooltip
        title={`${getFullName(guest)} (${t(
          event?.userId === guest.userId
            ? 'ORGANIZER'
            : answerInfos?.label || 'GUEST',
        )})`}
      >
        <AnswerBadge
          {...props}
          ref={ref}
          sx={{
            cursor: !disableClick && guest?.photoURL ? 'pointer' : undefined,
            ...sx,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          overlap="circular"
          badgeContent={Icon && <Icon sx={{ fontSize: 10, lineHeight: 0 }} />}
          bgcolor={answerInfos?.color}
          onClick={
            !disableClick && guest?.photoURL ? () => openDialog() : undefined
          }
        >
          <Avatar
            sx={{
              backgroundColor: getGuestColor(guest.color),
              color: theme.palette.common.white,
              borderColor: `${theme.palette.background.paper} !important`,
            }}
            src={guest.thumbnailURL || undefined}
          >
            {getInitials(guest)}
          </Avatar>
        </AnswerBadge>
      </Tooltip>
    );
  },
);

import {
  AvTimer,
  Event as EventIcon,
  Group,
  Person,
} from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  Skeleton,
  SvgIconTypeMap,
  Typography,
  capitalize,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
  formatDistanceStrict,
  formatDistanceToNowStrict,
  isFuture,
} from 'date-fns';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ExpandableText } from '../../components/ExpandableText';
import { useGetEvent } from '../../services/useEvent';
import { Answer, answers, useGetGuests } from '../../services/useGuest';
import { theme } from '../../theme';
import { Sx } from '../../utils/sx';
import { useDialog } from '../../utils/useDialog';
import { getFullName } from '../../utils/userName';
import { GuestListDialog } from './GuestListDialog';

interface DetailsProps {
  loading?: boolean;
  sx?: Sx;
}

export const Details: FC<DetailsProps> = ({ sx, loading }) => {
  const { t } = useTranslation();
  const { event: globalEvent } = useGetEvent();
  const event = loading ? undefined : globalEvent;
  const { guests } = useGetGuests();
  const host = guests?.find(({ userId }) => userId === event?.userId) || null;

  const [openGuestsDialog, closeGuestsDialog] = useDialog((open) => (
    <GuestListDialog open={open} onClose={closeGuestsDialog} />
  ));

  const informations: {
    key: string;
    displayIf?: boolean;
    label: string;
    Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
    content: ReactNode;
  }[] = [
    {
      key: 'guests',
      label: t('GUESTS'),
      Icon: Group,
      content: (
        <ButtonBase
          sx={{ fontSize: '16px', textAlign: 'left' }}
          onClick={() => openGuestsDialog()}
        >
          {[Answer.PARTICIPATE, Answer.MAYBE, null]
            .map((answ) => {
              const answerInfos = answ && answers[answ];
              const guestsCount = guests?.filter(
                ({ answer }) => answer === answ,
              ).length;
              return { guestsCount, answerInfos };
            })
            .filter(({ guestsCount }) => !!guestsCount)
            .map(
              ({ guestsCount, answerInfos }) =>
                `${guestsCount} ${t(
                  guestsCount && guestsCount > 1
                    ? answerInfos?.labelPlural || 'GUESTS'
                    : answerInfos?.label || 'GUEST',
                )}`,
            )
            .join(', ')}
        </ButtonBase>
      ),
    },
    {
      key: 'organizedBy',
      label: t('ORGANIZED_BY'),
      Icon: Person,
      content: host && getFullName(host),
    },
    {
      key: 'distanceToNow',
      label: t('IN'),
      Icon: EventIcon,
      displayIf: event && isFuture(event.startDate),
      content: event ? formatDistanceToNowStrict(event.startDate) : '',
    },
    {
      key: 'duration',
      label: t('DURATION'),
      Icon: AvTimer,
      displayIf: !!event?.endDate,
      content:
        event?.endDate &&
        capitalize(formatDistanceStrict(event.startDate, event.endDate)),
    },
  ];

  return (
    <Box
      sx={{
        pt: 2,
        pb: 3,
        pl: 3,
        pr: [3, 3, 1],
        overflow: 'hidden',
        width: '100%',
        ...sx,
      }}
    >
      {informations
        .filter(({ displayIf }) => displayIf !== false)
        .map(({ key, label, Icon, content }) => (
          <Box
            key={key}
            sx={{
              display: 'flex',
              alignItems: ' center',
              mt: 1,
              overflow: 'hidden',
              width: '100%',
            }}
          >
            <Icon
              sx={{ color: theme.palette.grey[500], fontSize: 22, mr: 0.5 }}
            />
            <Typography
              sx={{ fontSize: 16, color: theme.palette.grey[600], mr: 1 }}
            >
              {label}
            </Typography>
            {typeof content === 'string' ? (
              content ? (
                <Typography sx={{ fontSize: 16 }}>{content}</Typography>
              ) : (
                <Skeleton width={100} />
              )
            ) : (
              content
            )}
          </Box>
        ))}
      <Box sx={{ mt: 2, overflow: 'hidden', width: '100%' }}>
        {event ? (
          event.description ? (
            <ExpandableText
              text={event.description}
              linkified
              TypographyProps={{ sx: { whiteSpace: 'pre-line' } }}
            />
          ) : (
            <Typography variant="caption" color="GrayText">
              {t('NO_DESCRIPTION')}
            </Typography>
          )
        ) : (
          <Skeleton width="50%" />
        )}
      </Box>
    </Box>
  );
};

import {
  CollectionReference,
  DocumentData,
  collection,
  deleteDoc,
  doc,
  query as fsQuery,
  setDoc,
} from 'firebase/firestore';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilState } from 'recoil';
import { timestampConverter } from '../firebase';
import { getTimezone } from '../utils/timezone';
import { useGetUser, usersCollection } from './useAuth';
import { useGetList } from './useEntity';
import { useGetEvent } from './useEvent';
import { getGuestsCollection, useGetGuest } from './useGuest';

interface NotificationToken {
  id: string;
  createdAt: Date;
  language: string;
  timezone: string | null;
  parentId: string;
}

export const deviceNotificationTokenAtom = atom<string | null>({
  key: 'deviceNotificationToken',
  default: null,
});

export const disableTokenUploadAtom = atom<boolean>({
  key: 'disableTokenUpload',
  default: false,
});

const timezone = getTimezone();

const NOTIFICATION_TOKENS_COLLECTION = 'notificationTokens';
export const getUserNotificationTokensCollection = (userId: string) =>
  collection(usersCollection, userId, NOTIFICATION_TOKENS_COLLECTION);
export const getGuestNotificationTokensCollection = (
  eventId: string,
  guestId: string,
) =>
  collection(
    getGuestsCollection(eventId),
    guestId,
    NOTIFICATION_TOKENS_COLLECTION,
  );

export const useGetGuestNotificationTokens = (skip?: boolean) => {
  const { eventId } = useGetEvent();
  const { guest } = useGetGuest();
  const query = useMemo(
    () =>
      eventId && guest?.id
        ? fsQuery(getGuestNotificationTokensCollection(eventId, guest.id))
        : undefined,
    [eventId, guest?.id],
  );
  const { data, loading, error } = useGetList<NotificationToken>(query, skip);

  return { guestNotificationTokens: data, loading, error };
};

export const useGetUserNotificationTokens = (skip?: boolean) => {
  const { user } = useGetUser();
  const query = useMemo(
    () =>
      user ? fsQuery(getUserNotificationTokensCollection(user.id)) : undefined,
    [user],
  );
  const { data, loading, error } = useGetList<NotificationToken>(query, skip);

  return { userNotificationTokens: data, loading, error };
};

export const useUploadDeviceToken = () => {
  const { i18n } = useTranslation();
  const [deviceToken] = useRecoilState(deviceNotificationTokenAtom);
  const [disableTokenUpload, setDisableTokenUpload] = useRecoilState(
    disableTokenUploadAtom,
  );

  return useCallback(
    async (
      parentId: string,
      collectionRef: CollectionReference<DocumentData, DocumentData>,
    ) => {
      if (!deviceToken || disableTokenUpload) return;

      const docRef = doc(collectionRef, deviceToken).withConverter<
        NotificationToken,
        DocumentData
      >(timestampConverter);

      return setDoc<Omit<NotificationToken, 'id'>, DocumentData>(docRef, {
        createdAt: new Date(),
        language: i18n.resolvedLanguage || 'en',
        timezone: timezone?.timezone || null,
        parentId,
      }).catch(() => {
        setDisableTokenUpload(true);
      });
    },
    [
      deviceToken,
      disableTokenUpload,
      i18n.resolvedLanguage,
      setDisableTokenUpload,
    ],
  );
};

export const deleteDeviceToken = async (
  collectionRef: CollectionReference<DocumentData, DocumentData>,
  deviceToken: string,
) => {
  const docRef = doc(collectionRef, deviceToken);
  return deleteDoc(docRef).catch(() => {});
};

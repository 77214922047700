import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../utils/useNotification';

interface ReportPostDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ReportDialog: FC<ReportPostDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { pushNotification } = useNotification();
  const [reportMessage, setReportMessage] = useState('');

  const onReport = async () => {
    // TODO report
    pushNotification(t('REPORT_SUCCESS'), { variant: 'success' });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('REPORT')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('REPORT_TEXT')}</DialogContentText>
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          label={t('REPORT_LABEL')}
          multiline
          minRows={3}
          value={reportMessage}
          onChange={({ target }) => setReportMessage(target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={onReport}
          disabled={!reportMessage}
        >
          {t('REPORT')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

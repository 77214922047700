import {
  Box,
  SnackbarContent as MuiSnackbarContent,
  Typography,
} from '@mui/material';
import { CustomContentProps, SnackbarContent } from 'notistack';
import { forwardRef } from 'react';
import { Answer, answers } from '../services/useGuest';
import { theme } from '../theme';

interface GuestAnswerSnackbarProps extends CustomContentProps {
  answer: Answer;
}

export const GuestAnswerSnackbar = forwardRef<
  HTMLDivElement,
  GuestAnswerSnackbarProps
>(({ message, answer }, forwardedRef) => {
  const answerInfos = answers[answer];
  const { Icon } = answerInfos;

  return (
    <SnackbarContent ref={forwardedRef}>
      <MuiSnackbarContent
        sx={{ bgcolor: theme.palette.common.white }}
        message={
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box
              sx={{
                height: 20,
                width: 20,
                bgcolor: answerInfos.color,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 1,
              }}
            >
              <Icon sx={{ fontSize: 16, color: theme.palette.common.white }} />
            </Box>
            <Typography sx={{ fontSize: 14 }}>{message}</Typography>
          </Box>
        }
      ></MuiSnackbarContent>
    </SnackbarContent>
  );
});

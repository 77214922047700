import { addHours, setMinutes } from 'date-fns';
import { collection } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { db, functions } from '../firebase';
import { useDelete, useGetEntity, useMutate, useUpdate } from './useEntity';
import {
  Location,
  photoURLIsExpired,
  useGetLocationPhotoUrl,
} from './useGooglePlaces';

export const EVENT_EXPIRATION_IN_DAYS = 30;

interface NewEvent {
  title: string;
  startDate: Date;
  endDate: Date | null;
  location: Location | null;
  description: string | null;
  locationPhotoURL: string | null;
  locationPhotoURLCreatedAt: Date | null;
}

export interface Event extends NewEvent {
  id: string;
  createdAt: Date;
  userId: string;
  backgroundImageURL: string | null;
  thumbnailURL: string | null;
  canceledAt: Date | null;
}

export interface EventForListing
  extends Pick<
    Event,
    | 'id'
    | 'title'
    | 'startDate'
    | 'endDate'
    | 'thumbnailURL'
    | 'canceledAt'
    | 'userId'
  > {
  locationName: string | null;
}

export const getDefaultEvent = (): NewEvent => ({
  title: '',
  startDate: setMinutes(addHours(new Date(), 1), 0),
  endDate: null,
  location: null,
  description: null,
  locationPhotoURL: null,
  locationPhotoURLCreatedAt: null,
});

export const eventsCollection = collection(db, 'events');

export const useGetEvent = () => {
  const location = useLocation();
  const [, eventId] = location.pathname.split('/e/');

  const { data, loading, error } = useGetEntity<Event>(
    eventsCollection,
    eventId,
  );

  return { eventId, event: data, loading, error };
};

export const useCreateEvent = () => {
  const { i18n } = useTranslation();
  const { mutate, loading } = useMutate();

  const createEvent = useCallback(
    (newEvent: NewEvent) =>
      mutate(
        (async () => {
          const res = await httpsCallable<
            NewEvent & { language: string | undefined },
            string
          >(
            functions,
            'createevent',
          )({ ...newEvent, language: i18n.resolvedLanguage });

          return res.data;
        })(),
      ),
    [i18n.resolvedLanguage, mutate],
  );

  return { createEvent, loading };
};

export const useUpdateEvent = () => {
  const { update, loading } = useUpdate();

  const updateEvent = useCallback(
    (eventId: string, fields: Partial<Event>) =>
      update(eventsCollection, eventId, fields),
    [update],
  );

  return { updateEvent, loading };
};

export const useDeleteEvent = () => {
  const { loading } = useMutate();
  const { deleteEntity } = useDelete();

  const deleteEvent = useCallback(
    (event: Event) => deleteEntity(eventsCollection, event.id),
    [deleteEntity],
  );

  return { deleteEvent, loading };
};

export const useEventLocationPhotoRefresh = (event?: Event) => {
  const { getLocationPhotoUrl } = useGetLocationPhotoUrl();
  const { updateEvent } = useUpdateEvent();
  const [photoURL, setPhotoURL] = useState<string | null>(null);

  useEffect(() => {
    if (
      !event?.location?.placeId ||
      !event.locationPhotoURL ||
      !event.locationPhotoURLCreatedAt ||
      !photoURLIsExpired(event.locationPhotoURLCreatedAt)
    ) {
      setPhotoURL(event?.locationPhotoURL || null);
      return;
    }

    getLocationPhotoUrl(event.location.placeId)
      .then((url) => {
        if (!event.location) return;
        setPhotoURL(url);
        return updateEvent(event.id, {
          locationPhotoURL: url,
          locationPhotoURLCreatedAt: new Date(),
        });
      })
      .catch(() => {});
  }, [
    event?.id,
    event?.location,
    event?.locationPhotoURL,
    event?.locationPhotoURLCreatedAt,
    getLocationPhotoUrl,
    updateEvent,
  ]);

  return { photoURL };
};

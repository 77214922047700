import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventPost } from '../../components/fields/EventPost';
import { isEventPostValid } from '../../components/fields/utils';
import { useGetEvent } from '../../services/useEvent';
import { Post, useUpdatePost } from '../../services/usePost';
import { useNotification } from '../../utils/useNotification';

interface EditPostDialogProps {
  open: boolean;
  onClose: () => void;
  post: Post;
}

export const EditPostDialog: FC<EditPostDialogProps> = ({
  open,
  onClose,
  post,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { pushError } = useNotification();
  const { updatePost, loading } = useUpdatePost();
  const [internalPost, setInternalPost] = useState<Post>(post);

  if (!event) return null;

  const onEdit = async () => {
    try {
      await updatePost(event.id, post.id, {
        message: internalPost.message,
        range: internalPost.range,
        updatedAt: new Date(),
      });
      onClose();
    } catch (e) {
      pushError(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t('EDIT_POST')}</DialogTitle>
      <DialogContent>
        <EventPost
          post={internalPost}
          onRangeChange={(range) =>
            setInternalPost((prev) => ({ ...prev, range }))
          }
          onMessageChange={(message) =>
            setInternalPost((prev) => ({ ...prev, message }))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={onEdit}
          disabled={!isEventPostValid(internalPost.message, true)}
        >
          {t('EDIT')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

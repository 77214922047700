import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import { Error, Home } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Dialog,
  Divider,
  Paper,
  Typography,
} from '@mui/material';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventBackgroundImage } from '../../components/BackgroundImage';
import { Head } from '../../components/Head';
import { TopBar } from '../../components/TopBar';
import { UserLoginDialog } from '../../components/user/UserLoginDialog';
import { paths } from '../../router';
import { useGetEvent } from '../../services/useEvent';
import {
  Guest,
  useGetGuest,
  useGuestCreationPath,
} from '../../services/useGuest';
import { useGetPosts } from '../../services/usePost';
import { theme } from '../../theme';
import { eventIsPast } from '../../utils/eventDate';
import { isApp } from '../../utils/platform';
import { useAppBarHeight } from '../../utils/useAppBarHeight';
import { useDialog } from '../../utils/useDialog';
import { useInView } from '../../utils/useInView';
import { Name } from '../../utils/userName';
import { AddPost } from './AddPost';
import { BackgroundImageButton } from './BackgroundImageButton';
import { Details } from './Details';
import { GuestAnswer } from './GuestAnswer';
import { GuestAnswerPopUp } from './GuestAnswerPopUp';
import { GuestList } from './GuestList';
import { GuestNameDialog } from './GuestNameDialog';
import { Header } from './Header';
import { Location } from './Location';
import { NextSteps } from './NextSteps';
import { PostDisplay } from './PostDisplay';
import { SimilarGuestsDialog } from './SimilarGuestsDialog';

const BACKGROUND_IMAGE_HEIGHT = '23vh';

export const Event: FC = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const appBarHeight = useAppBarHeight();
  const inView = useInView();
  const { guest } = useGetGuest();
  const { posts, loading: postsLoading } = useGetPosts();
  const { event, loading, error } = useGetEvent();
  const [similarGuests, setSimilarGuests] = useState<Guest[]>([]);
  const [guestNameResolve, setGuestNameResolve] = useState<
    ((val: Name) => void) | null
  >(null);
  const [guestIdResolve, setGuestIdResolve] = useState<
    ((val: string | null) => void) | null
  >(null);
  const [userLoginResolve, setUserLoginResolve] = useState<
    ((val: boolean) => void) | null
  >(null);

  const [openNameDialog, closeNameDialog] = useDialog((open) => (
    <GuestNameDialog
      open={open}
      onValidate={(name) => {
        guestNameResolve?.(name);
        closeNameDialog();
      }}
    />
  ));

  const [openSimilarGuestsDialog, closeSimilarGuestsDialog] = useDialog(
    (open) => (
      <SimilarGuestsDialog
        open={open}
        similarGuests={similarGuests}
        onValidate={(guestId) => {
          guestIdResolve?.(guestId);
          closeSimilarGuestsDialog();
        }}
      />
    ),
  );

  const [openLoginDialog, closeLoginDialog] = useDialog((open) => (
    <UserLoginDialog
      title={t('GUEST_HAS_ACCOUNT')}
      open={open}
      onCancel={() => {
        userLoginResolve?.(false);
        closeLoginDialog();
      }}
      onSuccess={() => {
        userLoginResolve?.(true);
        closeLoginDialog();
      }}
    />
  ));

  const askForName = useCallback(() => {
    openNameDialog();
    return new Promise<Name>((res) => setGuestNameResolve(() => res));
  }, [openNameDialog]);

  const chooseSimilarGuest = useCallback(
    (guests: Guest[]) => {
      openSimilarGuestsDialog();
      setSimilarGuests(guests);
      return new Promise<string | null>((res) => setGuestIdResolve(() => res));
    },
    [openSimilarGuestsDialog],
  );

  const openUserLoginDialog = useCallback(() => {
    openLoginDialog();
    return new Promise<boolean>((res) => setUserLoginResolve(() => res));
  }, [openLoginDialog]);

  useGuestCreationPath({
    askForName,
    chooseSimilarGuest,
    openUserLoginDialog,
    skip: !event,
  });

  const displayInformations =
    !!guest || eventIsPast(event) || event?.canceledAt;

  return (
    <IonPage>
      {isApp && <TopBar hideSignInButton title={event?.title} />}
      <IonContent>
        <Head event={event} />

        <EventBackgroundImage event={event} height={BACKGROUND_IMAGE_HEIGHT} />

        {!isApp && <TopBar hideSignInButton />}

        <Container
          maxWidth="md"
          sx={{
            mt: BACKGROUND_IMAGE_HEIGHT,
            pb: `max(${theme.spacing(2)}, env(safe-area-inset-bottom))`,
            position: 'relative',
          }}
          disableGutters
        >
          <BackgroundImageButton
            sx={{
              position: 'absolute',
              top: `calc((${BACKGROUND_IMAGE_HEIGHT} + ${appBarHeight}px) * -0.5)`,
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 'max-content',
            }}
          />
          <Paper>
            <Header loading={!displayInformations} />
            <Divider />
            <Box
              sx={{
                display: 'flex',
                flexDirection: ['column', 'column', 'row'],
                alignItems: ['stretch', 'stretch', 'flex-start'],
              }}
            >
              <Details
                loading={!displayInformations}
                sx={{ flexBasis: '50%', flexGrow: 1, flexShrink: 0 }}
              />
              <Location
                loading={!displayInformations}
                sx={{
                  flexBasis: '50%',
                  flexGrow: 1,
                  flexShrink: 0,
                  transform: [
                    undefined,
                    undefined,
                    `translate(${theme.spacing(1)}, ${theme.spacing(1)})`,
                  ],
                  mx: [2, 2, 0],
                  mb: [2, 2, 0],
                }}
              />
            </Box>
          </Paper>

          {displayInformations && <NextSteps sx={{ mt: 2 }} />}

          <GuestAnswer sx={{ mt: 2 }} />

          <GuestList loading={!displayInformations} sx={{ mt: 2 }} />

          <AddPost sx={{ mt: 2 }} />

          {postsLoading || !displayInformations ? (
            <PostDisplay loading sx={{ mt: 2 }} />
          ) : (
            posts?.map((post) => (
              <PostDisplay key={post.id} post={post} sx={{ mt: 2 }} />
            ))
          )}

          <GuestAnswerPopUp />
        </Container>

        {inView && !loading && (error || !event) && (
          <Dialog
            open
            PaperProps={{
              sx: { backgroundColor: 'transparent', boxShadow: 'none' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {error ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Error color="error" sx={{ mr: 2, fontSize: 30 }} />
                  <Typography variant="h4" color="error">
                    {error.message}
                  </Typography>
                </Box>
              ) : (
                <Typography textAlign="center" variant="h4" color="secondary">
                  {t('EVENT_NOT_FOUND')}
                </Typography>
              )}
              <Button
                color="secondary"
                sx={{ mt: 4 }}
                variant="contained"
                startIcon={<Home />}
                href={paths.HOME}
                onClick={(e) => {
                  e.preventDefault();
                  router.push(paths.HOME, 'root');
                }}
              >
                {t('GO_HOME')}
              </Button>
            </Box>
          </Dialog>
        )}
      </IonContent>
    </IonPage>
  );
};

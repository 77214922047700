import {
  CameraAlt,
  Check,
  Edit,
  Event,
  Mail,
  PhotoSizeSelectActual,
  Place,
} from '@mui/icons-material';
import {
  Avatar,
  CircularProgress,
  Icon,
  LinearProgress,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  SvgIconTypeMap,
  Typography,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FC, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserLoginDialog } from '../../components/user/UserLoginDialog';
import { User, useGetUser, useUpdateUser } from '../../services/useAuth';
import { useGetEvent, useUpdateEvent } from '../../services/useEvent';
import {
  useGetGuest,
  useGetGuests,
  useUpdateGuest,
} from '../../services/useGuest';
import { useBackgroundImage, useUserPhoto } from '../../services/useStorage';
import { theme } from '../../theme';
import { eventIsPast } from '../../utils/eventDate';
import { useFileUpload } from '../../utils/fileUpload';
import { Sx } from '../../utils/sx';
import { useAddToCalendar } from '../../utils/useAddToCalendar';
import { useDialog } from '../../utils/useDialog';
import { useGetHost } from '../../utils/useGetHost';
import { useInvite } from '../../utils/useInvite';
import { useNotification } from '../../utils/useNotification';
import { DescriptionEditDialog } from './DescriptionEditDialog';
import { LocationEditDialog } from './LocationEditDialog';

enum StepId {
  DESCRIPTION = 'DESCRIPTION',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE',
  LOCATION = 'LOCATION',
  USER_PHOTO = 'USER_PHOTO',
  INVITE = 'INVITE',
  ANSWER = 'ANSWER',
  LOGIN = 'LOGIN',
  ADD_TO_CALENDAR = 'ADD_TO_CALENDAR',
  USE_APP = 'USE_APP',
}

interface Step {
  id: StepId;
  label: string;
  secondaryLabel?: string;
  Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  completed?: boolean;
  href?: string;
}

interface NextStepsProps {
  sx?: Sx;
}

export const NextSteps: FC<NextStepsProps> = ({ sx }) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { user } = useGetUser();
  const { guests } = useGetGuests();
  const { guest } = useGetGuest();
  const { openFileSelector } = useFileUpload();
  const [loadingStep, setLoadingStep] = useState<StepId | undefined>();
  const { pushError } = useNotification();
  const { uploadUserPhoto } = useUserPhoto();
  const { updateUser } = useUpdateUser();
  const { uploadBackgroundImage } = useBackgroundImage();
  const { updateEvent } = useUpdateEvent();
  const { updateGuest } = useUpdateGuest();
  const addToCalendar = useAddToCalendar();
  const { eventHost } = useGetHost();
  const { openInviteModal } = useInvite();
  const { isHost } = useGetHost();

  const [openDescriptionEditDialog, closeDescriptionEditDialog] = useDialog(
    (open) => (
      <DescriptionEditDialog open={open} onClose={closeDescriptionEditDialog} />
    ),
  );

  const [openLocationEditDialog, closeLocationEditDialog] = useDialog(
    (open) => (
      <LocationEditDialog open={open} onClose={closeLocationEditDialog} />
    ),
  );

  const [openLoginDialog, closeLoginDialog] = useDialog<File[] | null>(
    (open, files) =>
      event ? (
        <UserLoginDialog
          open={open}
          onClose={closeLoginDialog}
          title={t('TODO_LOGIN_DIALOG')}
          onSuccess={(loggedUser) => onUserPhotoUpload(files, loggedUser)}
        />
      ) : null,
  );

  if (
    !event ||
    eventIsPast(event) ||
    event.canceledAt ||
    !guest ||
    !eventHost
  ) {
    return null;
  }

  const onBackgroundImageUpload = async (files: File[] | null) => {
    if (!files || !event) return;

    setLoadingStep(StepId.BACKGROUND_IMAGE);
    try {
      const { small, large } = await uploadBackgroundImage(event.id, files[0]);
      await updateEvent(event.id, {
        thumbnailURL: small,
        backgroundImageURL: large,
      });
    } catch (e) {
      pushError(e);
    }
    setLoadingStep(undefined);
  };

  const onUserPhotoUpload = async (
    files: File[] | null | undefined,
    loggedUser: User,
  ) => {
    setLoadingStep(StepId.USER_PHOTO);
    try {
      if (!files?.[0]) throw new Error(t('ERROR_IN_PHOTO_SELECTION'));

      const { small, large } = await uploadUserPhoto(loggedUser.id, files[0]);
      await updateUser(loggedUser.id, { thumbnailURL: small, photoURL: large });
    } catch (e) {
      pushError(e);
    }
    setLoadingStep(undefined);
  };

  const onAddToCalendar = async () => {
    try {
      await addToCalendar(event);
      await updateGuest(event.id, guest.id, { addedToCalendar: true });
    } catch (e) {
      pushError(e);
    }
  };

  const userPhotoStep: Step = {
    id: StepId.USER_PHOTO,
    label: t('TODO_USER_PHOTO_LABEL'),
    secondaryLabel: t('TODO_USER_PHOTO_SECONDARY_LABEL'),
    Icon: CameraAlt,
    onClick: (e) =>
      openFileSelector(e, (files: File[] | null) => {
        if (!user) {
          openLoginDialog(files);
          return;
        }

        onUserPhotoUpload(files, user);
      }),
    completed: !!user?.thumbnailURL && !!user?.photoURL,
  };

  const addToCalendarStep: Step = {
    id: StepId.ADD_TO_CALENDAR,
    label: t('TODO_ADD_TO_CALENDAR_LABEL'),
    secondaryLabel: t('TODO_ADD_TO_CALENDAR_SECONDARY_LABEL'),
    Icon: Event,
    onClick: onAddToCalendar,
    completed: !!guest?.addedToCalendar,
  };

  const steps: Step[] = [
    {
      id: StepId.DESCRIPTION,
      label: t('TODO_DESCRIPTION_LABEL'),
      secondaryLabel: t('TODO_DESCRIPTION_SECONDARY_LABEL'),
      Icon: Edit,
      onClick: () => openDescriptionEditDialog(),
      completed: !!event.description,
    },
    {
      id: StepId.BACKGROUND_IMAGE,
      label: t('TODO_IMAGE_LABEL'),
      secondaryLabel: t('TODO_IMAGE_SECONDARY_LABEL'),
      Icon: PhotoSizeSelectActual,
      onClick: (e) => openFileSelector(e, onBackgroundImageUpload),
      completed: !!event.backgroundImageURL,
    },
    {
      id: StepId.LOCATION,
      label: t('TODO_LOCATION_LABEL'),
      secondaryLabel: t('TODO_LOCATION_SECONDARY_LABEL'),
      Icon: Place,
      onClick: () => openLocationEditDialog(),
      completed: !!event.location,
    },
    userPhotoStep,
    addToCalendarStep,
    {
      id: StepId.INVITE,
      label: t('TODO_INVITE_LABEL'),
      secondaryLabel: t('TODO_INVITE_SECONDARY_LABEL'),
      Icon: Mail,
      onClick: () => openInviteModal(),
      completed: !!guests && guests.length > 1,
    },
  ];

  const completedStepsLength = steps.filter(
    ({ completed }) => completed,
  ).length;

  if (completedStepsLength === steps.length || !isHost) {
    return null;
  }

  return (
    <Paper
      sx={{ px: 3, py: 2, display: 'flex', flexDirection: 'column', ...sx }}
    >
      <Typography variant="h6">{t('NEXT_STEPS')}</Typography>
      <LinearProgress
        color="success"
        variant="determinate"
        value={Math.min(
          100,
          Math.max(1, Math.round((completedStepsLength / steps.length) * 100)),
        )}
        sx={{ mt: 1, height: 6 }}
      />
      <Typography sx={{ color: theme.palette.success.main }}>
        {t('STEPS_COMPLETED', {
          count: completedStepsLength,
          total: steps.length,
        })}
      </Typography>
      <List dense>
        {[...steps]
          .sort((stepA, stepB) => {
            if (stepA.completed && !stepB.completed) return 1;
            if (!stepA.completed && stepB.completed) return -1;
            return 0;
          })
          .map((step) => (
            <ListItemButton
              key={step.id}
              onClick={step.onClick}
              disabled={step.completed}
              {...(step.href ? { href: step.href } : {})}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    bgcolor: step.completed
                      ? theme.palette.success.main
                      : theme.palette.primary.main,
                  }}
                >
                  {loadingStep === step.id ? (
                    <CircularProgress size={20} color="secondary" />
                  ) : step.completed ? (
                    <Check sx={{ color: theme.palette.common.white }} />
                  ) : (
                    <Icon sx={{ color: theme.palette.common.white }}>
                      <step.Icon />
                    </Icon>
                  )}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={step.label}
                secondary={!step.completed && step.secondaryLabel}
                primaryTypographyProps={{ sx: { fontSize: 16 } }}
              />
            </ListItemButton>
          ))}
      </List>
    </Paper>
  );
};

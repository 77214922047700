import { Edit, Place } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import googleLogo from '../../images/google_logo.png';
import { Event } from '../../services/useEvent';
import { usePlacesAutocomplete } from '../../services/useGooglePlaces';
import { isApp } from '../../utils/platform';
import { Sx } from '../../utils/sx';
import { Updater, getValue } from '../../utils/valOrUpdater';
import { LOCATION_MAX_CHARACTERS, isEventLocationValid } from './utils';

export type LocationPickerValue = Pick<
  Event,
  'location' | 'locationPhotoURL' | 'locationPhotoURLCreatedAt'
>;

interface EventLocationProps {
  value: LocationPickerValue;
  onChange?: Updater<LocationPickerValue>;
  sx?: Sx;
  defaultEndDate?: Date | null;
  disabled?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}

export const EventLocation: FC<EventLocationProps> = ({
  value: valueProp,
  onChange,
  sx,
  disabled,
  setOpen: setOpenProp,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);
  const { predictions, loading, onSelect } = usePlacesAutocomplete(
    search,
    !open,
  );
  const isValid = isEventLocationValid(valueProp.location);

  const value = valueProp.location;

  const options = search && predictions ? predictions : [];
  const optionsWithValue =
    !value ||
    options.find(({ description }) => value?.description === description)
      ? options
      : [...options, { description: value.description }];

  return (
    <Box sx={sx}>
      <Autocomplete
        disabled={disabled}
        onOpen={() => {
          setOpen(true);
          setOpenProp?.(true);
        }}
        onClose={() => {
          setOpen(false);
          setOpenProp?.(false);
          if (!value && search) {
            onSelect({ description: search }, (updater) =>
              onChange?.((prev) =>
                prev.location
                  ? prev
                  : {
                      ...prev,
                      ...getValue(updater, prev),
                    },
              ),
            );
          }
        }}
        getOptionLabel={(option) => option.description || ''}
        isOptionEqualToValue={(option, val) =>
          option.description === val.description
        }
        filterOptions={(x) => x}
        options={optionsWithValue}
        autoComplete
        filterSelectedOptions
        value={value}
        onChange={(e, val) => {
          onSelect(val, (updater) =>
            onChange?.((prev) => ({
              ...prev,
              ...getValue(updater, prev),
            })),
          );
        }}
        loading={loading}
        inputValue={search}
        onInputChange={(e, newValue) => {
          setSearch(newValue);
          if (!isApp) return;
          setTimeout(() => {
            (e?.target as HTMLInputElement)?.scrollIntoView({
              inline: 'start',
              behavior: 'smooth',
            });
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('LOCATION')}
            placeholder={t('ADD_LOCATION_OR_ADRESS')}
            fullWidth
            error={!isValid}
            helperText={
              !isValid &&
              t('MAX_CHARACTERS', {
                maxCharacters: LOCATION_MAX_CHARACTERS,
              })
            }
          />
        )}
        noOptionsText={t('TYPE_SOMETHING')}
        renderOption={(props, { structured_formatting: sf, customText }) => (
          <ListItem {...props} dense>
            <ListItemIcon>{sf?.main_text ? <Place /> : <Edit />}</ListItemIcon>
            <ListItemText secondary={sf?.secondary_text}>
              {sf?.main_text ? (
                sf.main_text.split('').map((letter, i) => (
                  <Typography
                    key={`${letter}-${i}`}
                    component="span"
                    sx={{
                      fontWeight: sf.main_text_matched_substrings?.some(
                        ({ offset, length }) =>
                          i >= offset && i < offset + length,
                      )
                        ? 'bold'
                        : undefined,
                    }}
                  >
                    {letter}
                  </Typography>
                ))
              ) : (
                <Typography>{customText}</Typography>
              )}
            </ListItemText>
          </ListItem>
        )}
      />
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Box component="img" sx={{ height: 15 }} src={googleLogo} />
      </Box>
    </Box>
  );
};

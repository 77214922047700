import { Close } from '@mui/icons-material';
import { alpha, Box, Dialog, Fab } from '@mui/material';
import { FC } from 'react';
import { theme } from '../theme';

interface ImageDialogProps {
  open: boolean;
  onClose: () => void;
  src: string;
}

export const ImageDialog: FC<ImageDialogProps> = ({ open, onClose, src }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      PaperProps={{
        sx: {
          backgroundColor: alpha(theme.palette.common.black, 1),
        },
      }}
    >
      <Box
        component="img"
        src={src}
        sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
      />
      <Fab
        size="small"
        sx={{
          position: 'absolute',
          top: `calc(${theme.spacing(2)} + env(safe-area-inset-top))`,
          right: `calc(${theme.spacing(2)} + env(safe-area-inset-right))`,
        }}
        onClick={onClose}
      >
        <Close />
      </Fab>
    </Dialog>
  );
};

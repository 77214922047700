import { capitalize } from '@mui/material';
import {
  addHours,
  differenceInCalendarDays,
  format,
  isPast,
  isSameDay,
  isToday,
} from 'date-fns';
import { TFunction } from 'i18next';
import { Event } from '../services/useEvent';

const formatDate = (date: Date, t: TFunction<'translation', undefined>) => {
  if (isToday(date)) {
    return t('TODAY');
  }

  const dateDifferenceInDays = differenceInCalendarDays(date, new Date());
  const dateIsNear = dateDifferenceInDays > -2 && dateDifferenceInDays < 2;

  if (dateIsNear) {
    if (isPast(date)) {
      return t('YESTERDAY');
    }
    return t('TOMORROW');
  }

  return format(date, 'PPPP');
};

const formatStartEndDatesAsArray = (
  event: Pick<Event, 'startDate' | 'endDate'>,
  t: TFunction<'translation', undefined>,
) => {
  const startDate = formatDate(event.startDate, t);
  const startHour = format(event.startDate, 'p');
  const at = t('AT');

  if (!event.endDate) {
    return [[startDate, at, startHour]];
  }

  const endHour = format(event.endDate, 'p');

  if (isSameDay(event.startDate, event.endDate)) {
    const from = t('FROM');
    const to = t('TO');
    return [[startDate, from, startHour, to, endHour]];
  }

  const endDate = formatDate(event.endDate, t);
  const ends = t('ENDS');
  return [
    [startDate, at, startHour],
    [ends, endDate, at, endHour],
  ];
};

export const formatStartEndDates = (
  event: Pick<Event, 'startDate' | 'endDate'> | undefined,
  t: TFunction<'translation', undefined>,
) => {
  if (!event) return [];
  return formatStartEndDatesAsArray(event, t).map((array) =>
    capitalize(array.join(' ')),
  );
};

export const eventIsPast = (
  event: Pick<Event, 'startDate' | 'endDate'> | undefined,
) => {
  if (!event) return false;
  if (event.endDate) {
    return isPast(event.endDate);
  }
  return isPast(addHours(event.startDate, 24));
};

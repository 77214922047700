import { AccessTime, OpenInNew, Place } from '@mui/icons-material';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { StarsRating } from '../../components/StarsRating';
import {
  useEventLocationPhotoRefresh,
  useGetEvent,
} from '../../services/useEvent';
import { theme } from '../../theme';
import { getLocationName, getOpeningHours } from '../../utils/eventLocation';
import { Sx } from '../../utils/sx';

interface LocationProps {
  loading?: boolean;
  sx?: Sx;
}

export const Location: FC<LocationProps> = ({ loading, sx }) => {
  const { t } = useTranslation();
  const { event: globalEvent } = useGetEvent();
  const event = loading ? undefined : globalEvent;
  const { photoURL } = useEventLocationPhotoRefresh(event);

  if (!event?.location?.details) {
    return null;
  }

  const { details } = event.location;
  const openingHours = getOpeningHours(event, t);

  return (
    <Card elevation={3} sx={{ ...sx }}>
      <CardHeader
        avatar={<Place sx={{ color: theme.palette.grey[500], fontSize: 35 }} />}
        action={
          details.url && (
            <Tooltip title={t('OPEN_ON_GOOGLE_MAP')}>
              <IconButton href={details.url} target="_blank" rel="noreferrer">
                <OpenInNew />
              </IconButton>
            </Tooltip>
          )
        }
        title={getLocationName(event.location)}
        titleTypographyProps={{ variant: 'h6' }}
        subheader={details.formatted_address}
      />
      {photoURL && (
        <CardActionArea>
          <Box
            component={details.url ? 'a' : 'div'}
            href={details.url}
            target="_blank"
            rel="noreferrer"
          >
            <CardMedia component="img" src={photoURL} height={150} />
          </Box>
        </CardActionArea>
      )}
      {!!(
        details.rating ||
        openingHours ||
        details.html_attributions?.length
      ) && (
        <CardContent sx={{ pt: 0 }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            {details.rating && (
              <StarsRating
                rating={details.rating}
                ratingCount={details.user_ratings_total}
                sx={{ mr: 2, mt: 2 }}
              />
            )}
            {openingHours && (
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <AccessTime
                  sx={{
                    color: openingHours.open ? 'GrayText' : red[500],
                    mr: 1,
                  }}
                />
                <Typography
                  sx={{ color: openingHours.open ? 'GrayText' : red[500] }}
                >
                  {openingHours.text}
                </Typography>
              </Box>
            )}
          </Box>
          {!!details.html_attributions?.length && (
            <Typography variant="caption" sx={{ mt: 2 }}>
              {details.html_attributions.join(', ')}
            </Typography>
          )}
        </CardContent>
      )}
    </Card>
  );
};

import { isAfter, isFuture } from 'date-fns';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Location } from '../../services/useGooglePlaces';

export const NAME_MIN_CHARACTERS = 2;
export const NAME_MAX_CHARACTERS = 30;
export const isNameValid = (string: string) => {
  const trimmed = string.trim();

  if (
    trimmed.length < NAME_MIN_CHARACTERS ||
    trimmed.length > NAME_MAX_CHARACTERS
  ) {
    return false;
  }

  return trimmed.match(
    /^[A-Za-zÀ-ÖØ-öø-ÿ]+(([',. -][A-Za-zÀ-ÖØ-öø-ÿ ])?[A-Za-zÀ-ÖØ-öø-ÿ]*)*$/,
  );
};

export const isFirstAndLastNameValid = (firstName: string, lastName: string) =>
  isNameValid(firstName) && isNameValid(lastName);

export const isPhoneValid = (value: string) => isPossiblePhoneNumber(value);

export const CONFIRMATION_CODE_LENGTH = 6;
export const isConfirmationCodeValid = (value: string) =>
  value.length === CONFIRMATION_CODE_LENGTH;

export const TITLE_MIN_CHARACTERS = 2;
export const TITLE_MAX_CHARACTERS = 100;
export const isEventTitleValid = (value: string) =>
  value.trim().length >= TITLE_MIN_CHARACTERS &&
  value.trim().length <= TITLE_MAX_CHARACTERS;

export const isEventDateTimeValid = (startDate: Date, endDate: Date | null) =>
  isFuture(startDate) && (!endDate || isAfter(endDate, startDate));

export const DESCRIPTION_MAX_CHARACTERS = 2000;
export const isEventDescriptionValid = (value: string | null) =>
  !value || value.trim().length <= DESCRIPTION_MAX_CHARACTERS;

export const LOCATION_MAX_CHARACTERS = 300;
export const isEventLocationValid = (value: Location | null) =>
  !value?.description ||
  value.description.trim().length <= LOCATION_MAX_CHARACTERS;

export const POST_MAX_CHARACTERS = 1000;
export const isEventPostValid = (message: string | null, mandatory?: boolean) =>
  (!mandatory || message?.trim()) &&
  (!message?.trim() || message.trim().length <= POST_MAX_CHARACTERS);

export const getPostFontSize = (message: string | null) =>
  !message || message.length < 85 ? 24 : undefined;

export const COMMENT_MAX_CHARACTERS = 500;
export const isCommentValid = (message: string | null) =>
  message?.trim() && message.trim().length <= COMMENT_MAX_CHARACTERS;

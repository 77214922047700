import { FormControl, FormHelperText, InputBase } from '@mui/material';
import { FC, Ref } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetGuest } from '../../services/useGuest';
import { Post, Range } from '../../services/usePost';
import { Sx } from '../../utils/sx';
import { PostHeader } from '../PostHeader';
import {
  getPostFontSize,
  isEventPostValid,
  POST_MAX_CHARACTERS,
} from './utils';

interface EventPostProps {
  post: Post;
  onRangeChange?: (range: Range) => void;
  onMessageChange?: (message: string | null) => void;
  sx?: Sx;
  inputRef?: Ref<any>;
  placeholder?: string;
}

export const EventPost: FC<EventPostProps> = ({
  post,
  onRangeChange,
  onMessageChange,
  sx,
  inputRef,
  placeholder,
}) => {
  const { t } = useTranslation();
  const isValid = isEventPostValid(post.message);
  const { guest } = useGetGuest();

  return (
    <FormControl fullWidth sx={sx}>
      <PostHeader
        sx={{ mb: 2 }}
        guest={guest}
        post={post}
        onRangeChange={onRangeChange}
      />
      <InputBase
        inputRef={inputRef}
        minRows={3}
        placeholder={placeholder || t('SAY_SOMETHING')}
        multiline
        value={post.message || ''}
        onChange={({ target }) => onMessageChange?.(target.value || null)}
        error={!isValid}
        sx={{ fontSize: getPostFontSize(post.message) }}
      />
      {!isValid && (
        <FormHelperText error>
          {t('MAX_CHARACTERS', {
            maxCharacters: POST_MAX_CHARACTERS,
          })}
        </FormHelperText>
      )}
    </FormControl>
  );
};

import { useIonRouter } from '@ionic/react';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { paths } from '../../router';
import { deleteUser } from '../../services/useAuth';
import { useNotification } from '../../utils/useNotification';

interface UserDeleteDialogProps {
  open: boolean;
  onClose?: () => void;
}

export const UserDeleteDialog: FC<UserDeleteDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const { pushError } = useNotification();
  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    setLoading(true);
    try {
      await deleteUser();
      onClose?.();
      router.push(paths.HOME, 'root');
    } catch (e) {
      pushError(e);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('DELETE_PROFILE')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 4 }}>
          {t('DELETE_PROFILE_TEXT')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={onDelete}
          color="error"
        >
          {t('DELETE_DEFINITELY')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { useIonRouter } from '@ionic/react';
import {
  AccountCircle,
  Check,
  Edit,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  MobileFriendly,
  Place,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  MobileStepper,
  Paper,
  SvgIconTypeMap,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { red } from '@mui/material/colors';
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { paths } from '../router';
import {
  User,
  useGetUser,
  useSignIn,
  useUpdateUser,
} from '../services/useAuth';
import { getDefaultEvent, useCreateEvent } from '../services/useEvent';
import { theme } from '../theme';
import { formatStartEndDates } from '../utils/eventDate';
import { getLocationName } from '../utils/eventLocation';
import { Sx } from '../utils/sx';
import { useNotification } from '../utils/useNotification';
import { getNameFromLocalStorage } from '../utils/userName';
import { Updater, getValue } from '../utils/valOrUpdater';
import { VisualCalendar } from './VisualCalendar';
import { ConfirmationCode } from './fields/ConfirmationCode';
import { EventDateTime } from './fields/EventDateTime';
import { EventDescription } from './fields/EventDescription';
import { EventLocation } from './fields/EventLocation';
import { EventTitle } from './fields/EventTitle';
import { FirstAndLastName } from './fields/FirstAndLastName';
import { Phone } from './fields/Phone';
import {
  isConfirmationCodeValid,
  isEventDateTimeValid,
  isEventDescriptionValid,
  isEventLocationValid,
  isEventTitleValid,
  isFirstAndLastNameValid,
  isPhoneValid,
} from './fields/utils';

interface EventCreationProps {
  activeStep: number;
  setActiveStep: Updater<number>;
  sx?: Sx;
  setLocationOpen?: Dispatch<SetStateAction<boolean>>;
}

export const EventCreation: FC<EventCreationProps> = ({
  activeStep,
  setActiveStep,
  sx,
  setLocationOpen,
}) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const { user } = useGetUser();
  const { updateUser } = useUpdateUser();
  const submitRef = useRef<HTMLButtonElement>(null);
  const { signIn, confirmCode } = useSignIn(submitRef.current);
  const [newEvent, setNewEvent] = useState(getDefaultEvent());
  const { createEvent } = useCreateEvent();
  const { pushError } = useNotification();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [LSContainsName, setLSContainsName] = useState(false);
  const [firstDateLine, secondDateLine] = formatStartEndDates(newEvent, t);

  const userHasName = !!user?.firstName && !!user.lastName;

  useEffect(() => {
    getNameFromLocalStorage().then((name) => setLSContainsName(!!name));
  }, []);

  const onCreateEvent = async () => {
    const eventId = await createEvent(newEvent);
    router.push(generatePath(paths.EVENT, { eventId }));
    setActiveStep(0);
    setNewEvent(getDefaultEvent());
  };

  const onCodeConfirmation = async () => {
    setLoading(true);
    let loggedUser: User | null = null;
    try {
      loggedUser = await confirmCode(
        confirmationCode,
        firstName && lastName ? { firstName, lastName } : undefined,
      );
    } catch (e) {
      pushError(e);
    }
    try {
      if (loggedUser) {
        await onCreateEvent();
      }
    } catch (e) {
      pushError(e);
      setActiveStep(2);
    }
    setLoading(false);
  };

  const steps: {
    component: ReactNode;
    nextDisabled?: boolean;
    height?: number;
    Icon?: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
    title?: string;
    text?: string;
    onNext?: () => void;
  }[] = [
    {
      component: (
        <>
          <EventTitle
            value={newEvent.title}
            onChange={(title) => setNewEvent((prev) => ({ ...prev, title }))}
            placeholder={t('EVENT_NAME_PLACEHOLDER')}
          />
          <EventDateTime
            sx={{ mt: 4 }}
            startDate={newEvent.startDate}
            endDate={newEvent.endDate}
            onStartDateChange={(startDate) =>
              setNewEvent((prev) => ({
                ...prev,
                startDate,
              }))
            }
            onEndDateChange={(endDate) =>
              setNewEvent((prev) => ({
                ...prev,
                endDate,
              }))
            }
          />
        </>
      ),
      nextDisabled:
        !isEventTitleValid(newEvent.title) ||
        !isEventDateTimeValid(newEvent.startDate, newEvent.endDate),
    },
    {
      component: (
        <EventLocation
          value={newEvent}
          onChange={(updater) =>
            setNewEvent((prev) => ({ ...prev, ...getValue(updater, prev) }))
          }
          setOpen={setLocationOpen}
        />
      ),
      Icon: Place,
      title: t('LOCATION'),
      text: t('ADD_LOCATION_TO_EVENT'),
      nextDisabled: !isEventLocationValid(newEvent.location),
    },
    {
      component: (
        <EventDescription
          value={newEvent.description}
          onChange={(description) =>
            setNewEvent((prev) => ({ ...prev, description }))
          }
        />
      ),
      Icon: Edit,
      title: t('DESCRIPTION'),
      text: t('ADD_DESCRIPTION_TO_EVENT'),
      nextDisabled: !isEventDescriptionValid(newEvent.description),
      onNext: async () => {
        if (userHasName) {
          setLoading(true);
          try {
            await onCreateEvent();
          } catch (e) {
            pushError(e);
          }
          setLoading(false);
          return;
        }

        setActiveStep((prev) => prev + 1);
      },
    },
    {
      component: (
        <>
          {!LSContainsName && (
            <FirstAndLastName
              firstName={firstName}
              lastName={lastName}
              onFirstNameChange={(val) => setFirstName(val)}
              onLastNameChange={(val) => setLastName(val)}
              disabled={loading}
            />
          )}
          {!user && (
            <Phone
              sx={{ mt: 3 }}
              value={phoneNumber}
              onChange={setPhoneNumber}
              disabled={loading}
            />
          )}
        </>
      ),
      Icon: AccountCircle,
      title: t('ABOUT_YOU'),
      text: t('ABOUT_YOU_TEXT'),
      nextDisabled:
        (!LSContainsName && !isFirstAndLastNameValid(firstName, lastName)) ||
        (!user && !isPhoneValid(phoneNumber)),
      onNext: async () => {
        setLoading(true);
        try {
          if (user) {
            if (!userHasName) {
              await updateUser(user.id, { firstName, lastName });
            }
            await onCreateEvent();
          } else {
            await signIn(phoneNumber);
            setActiveStep((prev) => prev + 1);
          }
        } catch (e) {
          pushError(e);
        }
        setLoading(false);
      },
    },
    {
      component: (
        <ConfirmationCode
          value={confirmationCode}
          onChange={setConfirmationCode}
          onValidate={onCodeConfirmation}
          disabled={loading}
        />
      ),
      Icon: MobileFriendly,
      title: t('CONFIRMATION_CODE'),
      nextDisabled: !isConfirmationCodeValid(confirmationCode),
      onNext: onCodeConfirmation,
    },
  ];

  const currentStep = steps[activeStep];

  if (!currentStep) return null;

  const stepsLength = steps.length - (user ? (userHasName ? 2 : 1) : 0);
  const lastStep = activeStep >= stepsLength - 1;

  return (
    <Paper sx={{ position: 'relative', ...sx }} elevation={10}>
      <VisualCalendar
        date={newEvent.startDate}
        color={red[500]}
        sx={{ position: 'absolute', top: -60, left: theme.spacing(3) }}
      />
      <Box sx={{ pl: [3, 21], pt: [10, 3], pb: 3, pr: 3 }}>
        {activeStep === 0 ? (
          currentStep.component
        ) : (
          <>
            <Typography variant="h4" sx={{ overflow: 'hidden' }}>
              {newEvent.title}
            </Typography>
            <Typography variant="h6" sx={{ color: red[500] }}>
              {firstDateLine}
            </Typography>
            {secondDateLine && (
              <Typography variant="h6" sx={{ color: red[500], opacity: 0.6 }}>
                {secondDateLine}
              </Typography>
            )}
            {newEvent.location && (
              <Typography
                variant="h6"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.grey[600],
                }}
              >
                <Place
                  sx={{
                    mr: 0.5,
                    color: theme.palette.grey[500],
                    fontSize: 22,
                  }}
                />
                {getLocationName(newEvent.location)}
              </Typography>
            )}
          </>
        )}
      </Box>
      {activeStep !== 0 && (
        <>
          <Divider />
          <Box sx={{ p: 3 }}>
            {(currentStep.Icon || currentStep.title) && (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                {currentStep.Icon && (
                  <currentStep.Icon
                    sx={{
                      fontSize: 40,
                      mr: 2,
                      color: theme.palette.grey[500],
                    }}
                  />
                )}
                {currentStep.title && (
                  <Typography variant="h6">{currentStep.title}</Typography>
                )}
              </Box>
            )}
            {currentStep.text && (
              <Typography sx={{ mb: 2 }} color="GrayText">
                {currentStep.text}
              </Typography>
            )}
            {currentStep.component}
          </Box>
        </>
      )}
      <MobileStepper
        variant="dots"
        steps={stepsLength}
        position="static"
        activeStep={activeStep}
        sx={{
          backgroundColor: theme.palette.background.paper,
          width: '100%',
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderBottomRightRadius: theme.shape.borderRadius,
        }}
        backButton={
          <Button
            startIcon={!smallScreen && <KeyboardArrowLeft />}
            onClick={() => setActiveStep((prev) => prev - 1)}
            disabled={!activeStep || loading}
            sx={{
              visibility: !activeStep ? 'hidden' : undefined,
              width: smallScreen ? undefined : 137,
            }}
          >
            {smallScreen ? <KeyboardArrowLeft /> : t('BACK')}
          </Button>
        }
        nextButton={
          <LoadingButton
            id="EventCreationStepper-submit-button"
            ref={submitRef}
            loading={loading}
            endIcon={
              !smallScreen && (lastStep ? <Check /> : <KeyboardArrowRight />)
            }
            variant="contained"
            disabled={currentStep.nextDisabled}
            onClick={
              currentStep.onNext || (() => setActiveStep((prev) => prev + 1))
            }
            sx={{ width: smallScreen ? undefined : 137 }}
          >
            {smallScreen ? (
              lastStep ? (
                <Check />
              ) : (
                <KeyboardArrowRight />
              )
            ) : (
              t(lastStep ? 'FINISH' : 'CONTINUE')
            )}
          </LoadingButton>
        }
      />
    </Paper>
  );
};

import { Card, CardContent, Skeleton, alpha } from '@mui/material';
import { FC } from 'react';
import { theme } from '../theme';
import { Sx } from '../utils/sx';

interface SkeletonCardProps {
  sx?: Sx;
}

export const SkeletonCard: FC<SkeletonCardProps> = ({ sx }) => {
  return (
    <Card sx={{ bgcolor: alpha(theme.palette.common.white, 0.2), ...sx }}>
      <Skeleton variant="rectangular" width="100%" height={150} />
      <CardContent>
        <Skeleton width={200} />
        <Skeleton height={40} width={220} />
        <Skeleton width={190} height={50} />
      </CardContent>
    </Card>
  );
};

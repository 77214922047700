import { useIonRouter } from '@ionic/react';
import { Check, Close, QuestionMark } from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { green, orange, red } from '@mui/material/colors';
import { isBefore } from 'date-fns';
import {
  DocumentData,
  QueryConstraint,
  collection,
  collectionGroup,
  doc,
  query as fsQuery,
  getDoc,
  where,
} from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilState } from 'recoil';
import { db, timestampConverter } from '../firebase';
import { paths } from '../router';
import { eventIsPast } from '../utils/eventDate';
import { getRandomGuestColor } from '../utils/guestColor';
import { isDefined } from '../utils/isDefined';
import { ls } from '../utils/localStorage';
import { isApp } from '../utils/platform';
import { promisePool } from '../utils/promisePool';
import { stringsAreSimilar } from '../utils/stringsAreSimilar';
import { useNotification } from '../utils/useNotification';
import { usePrevious } from '../utils/usePrevious';
import { Name, getNameFromLocalStorage } from '../utils/userName';
import { User, useGetUser, useUpdateUser } from './useAuth';
import {
  useCreate,
  useDelete,
  useGetList,
  useUpdate,
  useUpdateMultiple,
} from './useEntity';
import { EventForListing, eventsCollection, useGetEvent } from './useEvent';

const MAX_GUESTS_PER_EVENT = 100;

export enum Answer {
  PARTICIPATE = 'PARTICIPATE',
  DO_NOT_PARTICIPATE = 'DO_NOT_PARTICIPATE',
  MAYBE = 'MAYBE',
}

export const answers: Record<
  Answer,
  {
    label: string;
    labelPlural: string;
    labelMe: string;
    Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
    color: string;
  }
> = {
  [Answer.PARTICIPATE]: {
    label: 'PARTICIPATE',
    labelPlural: 'PARTICIPATE_PLURAL',
    labelMe: 'PARTICIPATE_ME',
    Icon: Check,
    color: green[500],
  },
  [Answer.DO_NOT_PARTICIPATE]: {
    label: 'DO_NOT_PARTICIPATE',
    labelPlural: 'DO_NOT_PARTICIPATE_PLURAL',
    labelMe: 'DO_NOT_PARTICIPATE_ME',
    Icon: Close,
    color: red[500],
  },
  [Answer.MAYBE]: {
    label: 'MAYBE',
    labelPlural: 'MAYBE_PLURAL',
    labelMe: 'MAYBE_ME',
    Icon: QuestionMark,
    color: orange[500],
  },
};

export interface Guest {
  id: string;
  createdAt: Date;
  userId: string | null;
  firstName: string;
  lastName: string;
  thumbnailURL: string | null;
  photoURL: string | null;
  answer: Answer | null;
  answeredAt: Date | null;
  hasBeenRenamed: boolean;
  hasPostedDecline: boolean;
  color: number;
  addedToCalendar: boolean;
  language: string;
  lastUpdatedBy: string | null;
  event?: EventForListing;
}

const GUESTS_COLLECTION = 'guests';
const guestsCollectionGroup = collectionGroup(db, GUESTS_COLLECTION);
export const getGuestsCollection = (eventId: string) =>
  collection(eventsCollection, eventId, GUESTS_COLLECTION);

export const getUserGuestConstraint = (userId: string) =>
  where('userId', '==', userId);

const LS_START_KEY = 'GUEST_ID_FOR_EVENT_';

export const getLSGuestKey = (eventId: string) => `${LS_START_KEY}${eventId}`;

const getGuestsWithSimilarNames = (name: Name, guests: Guest[]) =>
  guests.filter(
    (guest) =>
      (stringsAreSimilar(guest.firstName, name.firstName) &&
        stringsAreSimilar(guest.lastName, name.lastName)) ||
      (stringsAreSimilar(guest.firstName, name.lastName) &&
        stringsAreSimilar(guest.lastName, name.firstName)),
  );

const guestAtom = atom<Guest | null>({
  key: 'guest',
  default: null,
});

const guestsInLSAtom = atom<{ eventId: string; guestId: string | null }[]>({
  key: 'guestsInLS',
  default: [],
});

const guestsFromLSFetchedAtom = atom<Guest[]>({
  key: 'guestsFromLSFetched',
  default: [],
});

export const useGetGuest = () => {
  const [guest] = useRecoilState(guestAtom);

  return { guest };
};

export const useGetGuests = (skip?: boolean) => {
  const { user } = useGetUser();
  const { pushNotification, pushAnswer } = useNotification();
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const query = useMemo(
    () => event && fsQuery(getGuestsCollection(event.id)),
    [event],
  );
  const { data, loading, error, snapshots } = useGetList<Guest>(query, skip);

  const prevSnapshots = usePrevious(snapshots);

  useEffect(() => {
    if (
      isApp ||
      !prevSnapshots ||
      !snapshots ||
      user?.id !== event?.userId ||
      prevSnapshots[0]?.ref.parent.parent?.id !==
        snapshots[0]?.ref.parent.parent?.id
    ) {
      return;
    }

    // const newGuest = snapshots.find(
    //   (snap) => !prevSnapshots.map((prevSnap) => prevSnap.id).includes(snap.id),
    // );
    // if (newGuest) {
    //   console.log(loading, prevSnapshots, snapshots);
    //   pushNotification(
    //     t('NEW_GUEST_NOTIFICATION', { name: getFullName(newGuest.data()) }),
    //     { preventDuplicate: true, variant: 'info', autoHideDuration: 3000 },
    //   );
    //   return;
    // }

    const changedGuest = snapshots.find((snap) =>
      prevSnapshots.find(
        (prevSnap) =>
          prevSnap.id === snap.id &&
          prevSnap.data().answer !== snap.data().answer,
      ),
    );
    if (changedGuest) {
      pushAnswer(changedGuest.data());
    }
  }, [
    event?.userId,
    prevSnapshots,
    pushAnswer,
    pushNotification,
    snapshots,
    t,
    user?.id,
  ]);

  return { guests: data, loading, error };
};

export const useGuestCreationPath = ({
  askForName,
  chooseSimilarGuest,
  openUserLoginDialog,
  skip,
}: {
  askForName: () => Promise<Name>;
  chooseSimilarGuest: (
    guestsWithSimilarNames: Guest[],
  ) => Promise<string | null>;
  openUserLoginDialog: () => Promise<boolean>;
  skip?: boolean;
}) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const { event } = useGetEvent();
  const [guestId, setGuestId] = useState<string | null>(null);
  const [LSReady, setLSReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setGuest] = useRecoilState(guestAtom);
  const [, setGuestsInLS] = useRecoilState(guestsInLSAtom);
  const { user, loading: userLoading } = useGetUser();
  const { guests } = useGetGuests();
  const { createGuestWithName, createGuestWithUser } = useCreateGuest();
  const { updateGuest } = useUpdateGuest();
  const { updateUser } = useUpdateUser();
  const { pushError } = useNotification();

  const setGuestFromLS = useCallback(async () => {
    if (!guests || !event?.id) return;

    const guestFound = guests.find(({ id }) => id === guestId);
    if (guestFound) {
      if (guestFound.userId) {
        setLoading(true);
        const success = await openUserLoginDialog();
        !success && setGuestId(null);
        setLoading(false);
        return;
      }

      setGuest(guestFound);
      return;
    }

    if (eventIsPast(event) || event?.canceledAt) {
      return;
    }

    setLoading(true);

    setGuestId(null);

    let name = await getNameFromLocalStorage();
    if (!name) {
      name = await askForName();
    }

    const guestsWithSimilarNames = getGuestsWithSimilarNames(name, guests);
    if (guestsWithSimilarNames.length) {
      const chosenGuestId = await chooseSimilarGuest(guestsWithSimilarNames);
      if (chosenGuestId) {
        setGuestId(chosenGuestId);
        setLoading(false);
        return;
      }
    }

    if (guests.length < MAX_GUESTS_PER_EVENT) {
      const ref = await createGuestWithName(
        event.id,
        name.firstName,
        name.lastName,
      );
      setGuestId(ref.id);
    } else {
      pushError(t('MAX_GUESTS_FOR_THIS_EVENT', { max: MAX_GUESTS_PER_EVENT }));
      router.push(paths.HOME, 'root');
    }

    setLoading(false);
  }, [
    askForName,
    chooseSimilarGuest,
    createGuestWithName,
    event,
    guestId,
    guests,
    openUserLoginDialog,
    pushError,
    router,
    setGuest,
    t,
  ]);

  const setGuestFromUser = useCallback(async () => {
    if (!guests || !event?.id || !user) return;

    const guestFound = guests.find(({ userId }) => userId === user.id);
    if (guestFound) {
      if (!user.firstName || !user.lastName) {
        setLoading(true);
        await updateUser(user.id, {
          firstName: guestFound.firstName,
          lastName: guestFound.lastName,
        });
        setLoading(false);
      }
      setGuest(guestFound);
      return;
    }

    if (eventIsPast(event) || event?.canceledAt) {
      return;
    }

    setLoading(true);

    let name: Name;
    if (user.firstName && user.lastName) {
      name = { firstName: user.firstName, lastName: user.lastName };
    } else {
      name = await askForName();
      await updateUser(user.id, {
        firstName: name.firstName,
        lastName: name.lastName,
      });
    }

    const guestFoundInLS = guests.find(({ id }) => id === guestId);
    if (guestFoundInLS && !guestFoundInLS.userId) {
      await updateGuest(event.id, guestFoundInLS.id, {
        userId: user.id,
        thumbnailURL: user.thumbnailURL,
        photoURL: user.photoURL,
        ...name,
      });
      setLoading(false);
      return;
    }

    const guestsWithSimilarNames = getGuestsWithSimilarNames(
      name,
      guests,
    ).filter(({ userId }) => !userId);
    if (guestsWithSimilarNames.length) {
      const chosenGuestId = await chooseSimilarGuest(guestsWithSimilarNames);
      if (chosenGuestId) {
        await updateGuest(event.id, chosenGuestId, {
          userId: user.id,
          thumbnailURL: user.thumbnailURL,
          photoURL: user.photoURL,
          ...name,
        });
        setLoading(false);
        return;
      }
    }

    await createGuestWithUser(event.id, {
      ...user,
      ...name,
    });
    setLoading(false);
  }, [
    askForName,
    chooseSimilarGuest,
    createGuestWithUser,
    event,
    guestId,
    guests,
    setGuest,
    updateGuest,
    updateUser,
    user,
  ]);

  useEffect(() => {
    if (event && !userLoading && LSReady && !loading && !skip) {
      if (user) {
        setGuestFromUser().catch((e) => pushError(e));
      } else {
        setGuestFromLS().catch((e) => pushError(e));
      }
    }

    return () => {
      setGuest(null);
    };
  }, [
    LSReady,
    event,
    loading,
    pushError,
    setGuest,
    setGuestFromLS,
    setGuestFromUser,
    skip,
    user,
    userLoading,
  ]);

  useEffect(() => {
    if (!event?.id) return;
    ls.get<string>(getLSGuestKey(event.id)).then((id) => {
      setGuestId(id);
      setLSReady(true);
    });
  }, [event?.id]);

  useEffect(() => {
    if (!event?.id || !LSReady) return;
    if (!user && guestId) {
      ls.set(getLSGuestKey(event.id), guestId);
      setGuestsInLS((prev) =>
        prev.map(({ eventId }) => eventId).includes(event.id)
          ? prev
          : [...prev, { eventId: event.id, guestId }],
      );
    } else {
      ls.remove(getLSGuestKey(event.id));
      setGuestsInLS((prev) =>
        prev.filter(({ eventId }) => eventId !== event.id),
      );
    }
  }, [LSReady, event?.id, guestId, setGuestsInLS, user]);
};

export const useGetMyGuestsEvents = (
  orderBy: 'asc' | 'desc',
  skip?: boolean,
) => {
  const { user, loading: userLoading, error: userError } = useGetUser();
  const query = useMemo(
    () =>
      user
        ? fsQuery(guestsCollectionGroup, getUserGuestConstraint(user.id))
        : undefined,
    [user],
  );

  const {
    data,
    loading: dataLoading,
    error: dataError,
  } = useGetList<Guest>(query, skip);

  const [dataFromLS, setDataFromLS] = useRecoilState(guestsFromLSFetchedAtom);
  const [guestsInLS, setGuestsInLS] = useRecoilState(guestsInLSAtom);

  const getGuestsFromLS = useCallback(async () => {
    const guestsSnapshots = await promisePool(
      guestsInLS
        .filter(
          ({ eventId }) =>
            ![...(data || []), ...dataFromLS]
              ?.map((d) => d.event?.id)
              .includes(eventId),
        )
        .map(({ guestId, eventId }) =>
          guestId
            ? getDoc(
                doc(getGuestsCollection(eventId), guestId).withConverter<
                  Guest,
                  DocumentData
                >(timestampConverter),
              ).then((snapshot) => {
                if (!snapshot.exists()) {
                  ls.remove(getLSGuestKey(eventId));
                  setGuestsInLS((prev) =>
                    prev.filter((guestInLS) => eventId !== guestInLS.eventId),
                  );
                }
                return snapshot;
              })
            : null,
        ),
    );

    setDataFromLS((prev) => [
      ...prev.filter(
        ({ event, id }) =>
          event &&
          guestsInLS.map(({ eventId }) => eventId).includes(event.id) &&
          !guestsSnapshots.map((snap) => snap?.id).includes(id),
      ),
      ...guestsSnapshots.map((snap) => snap?.data()).filter(isDefined),
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, guestsInLS, setDataFromLS, setGuestsInLS]);

  useEffect(() => {
    getGuestsFromLS();
  }, [getGuestsFromLS]);

  const sortedData = useMemo(
    () =>
      [...(data || []), ...dataFromLS].sort(
        ({ event: eventA }, { event: eventB }) => {
          if (!eventA || !eventB) {
            return 0;
          }
          if (isBefore(eventA.startDate, eventB.startDate)) {
            return orderBy === 'desc' ? 1 : -1;
          }
          return orderBy === 'desc' ? -1 : 1;
        },
      ),
    [data, dataFromLS, orderBy],
  );

  return {
    data: sortedData,
    loading: userLoading || dataLoading,
    error: userError || dataError,
  };
};

export const useGuestsInLS = () => {
  const [, setGuestsInLS] = useRecoilState(guestsInLSAtom);

  const initiateGuestsLS = useCallback(async () => {
    const keysFromLS = await ls.keys();
    const eventKeys = (keysFromLS || []).filter((key) =>
      key.startsWith(LS_START_KEY),
    );
    const eventIds = eventKeys.map((key) => key.replace(LS_START_KEY, ''));
    const guestsIds = await promisePool(
      eventKeys.map((key) => ls.get<string>(key)),
    );
    setGuestsInLS(
      eventIds.reduce<{ eventId: string; guestId: string | null }[]>(
        (previous, id, i) => [
          ...previous,
          { eventId: id, guestId: guestsIds[i] },
        ],
        [],
      ),
    );
  }, [setGuestsInLS]);

  useEffect(() => {
    initiateGuestsLS();
  }, [initiateGuestsLS]);
};

const useCreateGuest = () => {
  const { create, loading } = useCreate();
  const { i18n } = useTranslation();

  const createGuestWithName = useCallback(
    async (eventId: string, firstName: string, lastName: string) =>
      create<Guest>(getGuestsCollection(eventId), {
        createdAt: new Date(),
        userId: null,
        firstName,
        lastName,
        thumbnailURL: null,
        photoURL: null,
        answer: null,
        answeredAt: null,
        hasBeenRenamed: false,
        hasPostedDecline: false,
        color: getRandomGuestColor(),
        addedToCalendar: false,
        language: i18n.resolvedLanguage || 'en',
        lastUpdatedBy: null,
      }),
    [create, i18n.resolvedLanguage],
  );

  const createGuestWithUser = useCallback(
    async (eventId: string, user: User, answer?: Answer) => {
      if (!user.firstName || !user.lastName) {
        throw new Error('Name needed to create guest');
      }

      return create<Guest>(getGuestsCollection(eventId), {
        createdAt: new Date(),
        userId: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        thumbnailURL: user.thumbnailURL,
        photoURL: user.photoURL,
        answer: answer || null,
        answeredAt: answer ? new Date() : null,
        hasBeenRenamed: false,
        hasPostedDecline: false,
        color: getRandomGuestColor(),
        addedToCalendar: false,
        language: i18n.resolvedLanguage || 'en',
        lastUpdatedBy: user.id,
      });
    },
    [create, i18n.resolvedLanguage],
  );

  return { createGuestWithName, createGuestWithUser, loading };
};

export const useUpdateGuest = () => {
  const { update, loading } = useUpdate();
  const { user } = useGetUser();

  const updateGuest = useCallback(
    (
      eventId: string,
      guestId: string,
      fields: Partial<Omit<Guest, 'lastUpdatedBy'>>,
    ) =>
      update(getGuestsCollection(eventId), guestId, {
        ...fields,
        lastUpdatedBy: user?.id || null,
      }),
    [update, user?.id],
  );

  return { updateGuest, loading };
};

export const useDeleteGuest = () => {
  const { deleteEntity, loading } = useDelete();

  const deleteGuest = useCallback(
    (eventId: string, guestId: string) =>
      deleteEntity(getGuestsCollection(eventId), guestId),
    [deleteEntity],
  );

  return { deleteGuest, loading };
};

export const useUpdateMultipleGuests = () => {
  const { updateMultiple, loading } = useUpdateMultiple();

  const updateMultipleGuests = useCallback(
    async (constraints: QueryConstraint[], fields: Partial<Guest>) =>
      updateMultiple(fsQuery(guestsCollectionGroup, ...constraints), fields),
    [updateMultiple],
  );

  return { updateMultipleGuests, loading };
};

const GOOGLE_MAP_API_KEY = 'AIzaSyD9lLqdde1_gGL0Y8SzktOMyRfaHT-tnRs';

interface Services {
  autocompleteService: google.maps.places.AutocompleteService;
  placeService: google.maps.places.PlacesService;
}

let googleMapsPromise: Promise<Services>;

// eslint-disable-next-line immutable/no-mutation
(window as any).initMap = () => {};

export const loadGoogleMaps = () => {
  if (!googleMapsPromise) {
    googleMapsPromise = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      const div = document.createElement('div');
      // eslint-disable-next-line immutable/no-mutation
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places&callback=initMap`;
      // eslint-disable-next-line immutable/no-mutation
      script.async = true;
      // eslint-disable-next-line immutable/no-mutation
      script.defer = true;
      // eslint-disable-next-line immutable/no-mutation
      script.onload = () => {
        resolve({
          autocompleteService: new google.maps.places.AutocompleteService(),
          placeService: new google.maps.places.PlacesService(div),
        });
      };
      // eslint-disable-next-line immutable/no-mutation
      script.onerror = () => {
        reject(new Error('Could not load Google Maps script'));
      };
      document.body.appendChild(script);
    });
  }

  return googleMapsPromise;
};

import { PersonOff, PowerSettingsNew } from '@mui/icons-material';
import { Box, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSignOut } from '../../services/useAuth';
import { useNotification } from '../../utils/useNotification';

interface UserActionMenuProps {
  onClose: () => void;
  openUserDeleteDialog: () => void;
  onSignOut: () => void;
}

export const UserActionMenu: FC<UserActionMenuProps> = ({
  onClose,
  openUserDeleteDialog,
  onSignOut,
}) => {
  const { t } = useTranslation();
  const { pushError } = useNotification();
  const signOut = useSignOut();

  const triggerSignOut = async () => {
    try {
      await signOut();
      onSignOut();
    } catch (e) {
      pushError(e);
    }
  };

  return (
    <Box>
      <MenuItem
        onClick={() => {
          triggerSignOut();
          onClose();
        }}
      >
        <ListItemIcon>
          <PowerSettingsNew fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('SIGN_OUT')}</ListItemText>
      </MenuItem>
      <MenuItem
        onClick={() => {
          openUserDeleteDialog();
          onClose();
        }}
      >
        <ListItemIcon>
          <PersonOff fontSize="small" />
        </ListItemIcon>
        <ListItemText>{t('DELETE_PROFILE')}</ListItemText>
      </MenuItem>
    </Box>
  );
};

import { ContentCopy } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useGetEvent } from '../../services/useEvent';
import { theme } from '../../theme';
import { copyTextToClipboard } from '../../utils/copyToClipboard';
import { getEventUrl } from '../../utils/eventUrl';
import { useNotification } from '../../utils/useNotification';

interface InviteDialogProps {
  open: boolean;
  onClose: () => void;
}

export const InviteDialog: FC<InviteDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { pushNotification, pushError } = useNotification();
  const [selectedTab, setSelectedTab] = useState(0);

  if (!event) return null;

  const link = getEventUrl(event.id);

  const onCopy = async () => {
    try {
      await copyTextToClipboard(link);
      pushNotification(t('COPIED_TO_CLIPBOARD'), { autoHideDuration: 2000 });
    } catch (e) {
      pushError(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Tabs
        value={selectedTab}
        onChange={(_, val) => setSelectedTab(val)}
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label={t('LINK')} sx={{ flex: 1 }} />
        <Tab label={t('QR_CODE')} sx={{ flex: 1 }} />
      </Tabs>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {selectedTab === 0 && (
          <>
            <DialogContentText>{t('INVITE_DIALOG_TEXT')}</DialogContentText>
            <TextField
              value={link}
              sx={{ overflowX: 'auto', mt: 2, width: '100%' }}
            />
            <Button
              startIcon={<ContentCopy />}
              onClick={onCopy}
              variant="outlined"
              sx={{ alignSelf: 'center', mt: 2 }}
            >
              {t('COPY_LINK')}
            </Button>
          </>
        )}
        {selectedTab === 1 && (
          <>
            <DialogContentText sx={{ mb: 3 }}>
              {t('INVITE_DIALOG_QR_TEXT')}
            </DialogContentText>
            <Box sx={{ alignSelf: 'center' }}>
              <QRCode
                value={link}
                size={140}
                fgColor={theme.palette.primary.dark}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CLOSE')}</Button>
      </DialogActions>
    </Dialog>
  );
};

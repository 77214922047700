import { Home, Refresh } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Component, ReactNode } from 'react';
import { paths } from '../router';
import { theme } from '../theme';
import { isApp } from '../utils/platform';
import { CenterBox } from './CenterBox';

export class ErrorBoundary extends Component<
  { children: ReactNode },
  { error: Error | null }
> {
  constructor(props: any) {
    super(props);
    // eslint-disable-next-line immutable/no-mutation
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    if (this.state.error) {
      return (
        <CenterBox
          sx={{
            p: 4,
            textAlign: 'center',
            bgcolor: theme.palette.background.default,
          }}
        >
          <Typography variant="h4" color="secondary">
            {'Something went wrong :('}
          </Typography>
          <Typography sx={{ mt: 2 }} color="secondary">
            {this.state.error?.message}
          </Typography>
          {!isApp && (
            <Button
              color="secondary"
              sx={{ mt: 4 }}
              variant="contained"
              startIcon={<Refresh />}
              onClick={() => location.reload()}
            >
              Try refreshing the page
            </Button>
          )}
          <Button
            color="secondary"
            sx={{ mt: 2 }}
            variant="outlined"
            startIcon={<Home />}
            href={paths.HOME}
          >
            Go home
          </Button>
        </CenterBox>
      );
    }

    return this.props.children;
  }
}

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventDescription } from '../../components/fields/EventDescription';
import { isEventDescriptionValid } from '../../components/fields/utils';
import { useGetEvent, useUpdateEvent } from '../../services/useEvent';
import { useNotification } from '../../utils/useNotification';

interface DescriptionEditDialogProps {
  open: boolean;
  onClose: () => void;
}

export const DescriptionEditDialog: FC<DescriptionEditDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const [description, setDescription] = useState<string | null>(
    event?.description || null,
  );
  const { updateEvent, loading } = useUpdateEvent();
  const { pushError } = useNotification();

  if (!event) return null;

  const onValidation = async () => {
    if (description == event.description) {
      onClose();
      return;
    }

    try {
      await updateEvent(event.id, { description });
      onClose();
    } catch (e) {
      pushError(e);
    }
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{t('TODO_DESCRIPTION_LABEL')}</DialogTitle>
      <DialogContent>
        <EventDescription
          sx={{ mt: 1 }}
          value={description}
          onChange={(value) => setDescription(value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <LoadingButton
          loading={loading}
          onClick={onValidation}
          variant="contained"
          disabled={!isEventDescriptionValid(description)}
        >
          {t('APPLY')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

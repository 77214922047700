import { Box, Typography } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import VerificationInput from 'react-verification-input';
import { Sx } from '../../utils/sx';
import { usePrevious } from '../../utils/usePrevious';
import './ConfirmationCode.css';
import { CONFIRMATION_CODE_LENGTH, isConfirmationCodeValid } from './utils';

interface ConfirmationCodeProps {
  value: string;
  onChange?: (value: string) => void;
  onValidate?: (value: string) => void;
  sx?: Sx;
  disabled?: boolean;
}

export const ConfirmationCode: FC<ConfirmationCodeProps> = ({
  value,
  onChange,
  onValidate,
  sx,
  disabled,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const previousValue = usePrevious(value);

  useEffect(() => {
    if (
      previousValue !== undefined &&
      isConfirmationCodeValid(value) &&
      !isConfirmationCodeValid(previousValue)
    ) {
      inputRef.current?.blur();
      onValidate?.(value);
    }
  }, [onValidate, previousValue, value]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...sx,
      }}
    >
      <Typography color="GrayText" sx={{ mb: 2 }}>
        {t('ENTER_CODE_YOU_RECEIVED')}
      </Typography>
      <VerificationInput
        value={value}
        onChange={onChange}
        ref={inputRef}
        inputProps={{
          autoComplete: 'one-time-code',
          type: 'tel',
          disabled,
        }}
        autoFocus
        placeholder=""
        validChars="0-9"
        length={CONFIRMATION_CODE_LENGTH}
        classNames={{
          character: 'character',
          characterSelected: disabled
            ? 'character--selected--disabled'
            : 'character--selected',
        }}
      />
    </Box>
  );
};

import { Box, Skeleton, Typography } from '@mui/material';
import { FC } from 'react';
import { Guest } from '../../services/useGuest';
import { theme } from '../../theme';
import { Sx } from '../../utils/sx';
import { getFullName } from '../../utils/userName';
import { GuestAvatar } from '../GuestAvatar';

interface UserIconAndNameProps {
  sx?: Sx;
  darkMode?: boolean;
  guest: Guest | null;
  disableClick?: boolean;
}

export const GuestIconAndName: FC<UserIconAndNameProps> = ({
  sx,
  darkMode,
  guest,
  disableClick,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        pb: '2px',
        ...sx,
      }}
    >
      {guest ? (
        <GuestAvatar guest={guest} disableClick={disableClick} />
      ) : (
        <Skeleton width={40} height={40} variant="circular" />
      )}
      <Typography
        sx={{
          color: darkMode
            ? theme.palette.common.white
            : theme.palette.text.primary,
          ml: 1,
          fontWeight: 800,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          textAlign: 'left',
        }}
      >
        {guest ? getFullName(guest) : <Skeleton width={100} />}
      </Typography>
    </Box>
  );
};

import { IonContent, IonPage, useIonRouter } from '@ionic/react';
import {
  Box,
  Button,
  Container,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { red } from '@mui/material/colors';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventCard } from '../../components/EventCard';
import { EventCreation } from '../../components/EventCreation';
import { SkeletonCard } from '../../components/SkeletonCard';
import { StoreBadges } from '../../components/StoreBadges';
import { TopBar } from '../../components/TopBar';
import IpadWithApp from '../../images/ipad_with_app.png';
import PhoneWithApp from '../../images/phone_with_app.png';
import { paths } from '../../router';
import { Answer, useGetMyGuestsEvents } from '../../services/useGuest';
import { theme } from '../../theme';
import { eventIsPast } from '../../utils/eventDate';

export const BrowserHome: FC = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const [activeStep, setActiveStep] = useState(0);
  const [locationOpen, setLocationOpen] = useState(false);
  const { data, loading } = useGetMyGuestsEvents('asc');
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const nextEvents = data.filter(({ event, answer }) => {
    if (!event) return false;
    return (
      !eventIsPast(event) &&
      answer !== Answer.DO_NOT_PARTICIPATE &&
      !event.canceledAt
    );
  });

  return (
    <IonPage>
      <IonContent>
        <TopBar />
        <Container
          sx={{
            display: 'flex',
            position: 'relative',
          }}
        >
          <Box sx={{ flexShrink: 1, zIndex: 1, flexGrow: 1 }}>
            <Typography
              sx={{
                fontSize: [30, 45, 45, 55],
                color: theme.palette.common.white,
                textAlign: ['center', 'left', 'left'],
                pr: 3,
                pl: [3, 3, 0],
                pb: 2,
                pt: [4, 8],
                lineHeight: ['35px', '45px', '45px', '70px'],
                fontWeight: 500,
              }}
            >
              {t('HOME_SENTENCE')}
            </Typography>

            {!smallScreen && (
              <Typography
                sx={{
                  fontSize: [16, 18, 18, 20],
                  color: theme.palette.common.white,
                  pr: 3,
                  pl: [3, 3, 0],
                  pb: [0, 4, 8],
                  lineHeight: '30px',
                  fontWeight: 500,
                  whiteSpace: 'pre-line',
                  textAlign: ['center', 'left', 'left'],
                }}
              >
                {t('HOME_SENTENCE_SECONDARY')}
                <Typography
                  component="span"
                  sx={{
                    color: red[200],
                    fontSize: [16, 18, 20],
                    fontWeight: 500,
                  }}
                >
                  {t('HOME_SENTENCE_SECONDARY_ACCENT')}
                </Typography>
              </Typography>
            )}

            {smallScreen && (
              <StoreBadges sx={{ justifyContent: 'center', mt: 1, pb: 3 }} />
            )}

            <EventCreation
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              sx={{
                mt: '60px',
                mb: locationOpen ? 30 : undefined,
                maxWidth: [undefined, undefined, 650, 740],
              }}
              setLocationOpen={setLocationOpen}
            />
          </Box>
          {!smallScreen && (
            <Box
              sx={{
                ml: [0, 2, 0, 8],
                mt: 12,
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <StoreBadges
                sx={{
                  alignSelf: 'flex-start',
                  flexDirection: ['column', 'column', 'row', 'row'],
                }}
              />
              <Box
                component="img"
                src={IpadWithApp}
                sx={{
                  width: [0, 0, '480px', '600px'],
                  position: 'absolute',
                  top: [0, 0, 240, 200],
                  right: 60,
                  display: ['none', 'none', 'block'],
                }}
              />
              <Box
                component="img"
                src={PhoneWithApp}
                sx={{
                  width: [0, '180px', '120px', '150px'],
                  position: ['static', 'static', 'absolute'],
                  top: [0, 0, 400, 400],
                  right: [0, 0, 28],
                  mt: [0, 2, 0],
                }}
              />
            </Box>
          )}
        </Container>

        <Container
          maxWidth="md"
          sx={{
            pb: `max(${theme.spacing(2)}, env(safe-area-inset-bottom))`,
            display: 'flex',
            flexDirection: 'column',
          }}
          disableGutters
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: [4, 8],
              px: [2, 2, 0],
            }}
          >
            {(loading || !!nextEvents.length) && (
              <Typography
                variant="h6"
                color="secondary"
                sx={{
                  mb: 2,
                }}
              >
                {t('YOUR_NEXT_EVENTS')}
              </Typography>
            )}

            <Grid container spacing={2}>
              {loading
                ? Array.from(Array(5)).map((_, i) => (
                    <Grid key={i} xs={12} sm={6} md={4}>
                      <SkeletonCard />
                    </Grid>
                  ))
                : nextEvents.map((guest) => (
                    <Grid key={guest.id} xs={12} sm={6} md={4}>
                      <EventCard guest={guest} />
                    </Grid>
                  ))}
            </Grid>

            {!!data.length && (
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                sx={{ alignSelf: 'center', mt: 4, mb: 'auto' }}
                href={paths.MY_EVENTS}
                onClick={(e) => {
                  e.preventDefault();
                  router.push(paths.MY_EVENTS);
                }}
              >
                {t('SEE_ALL_EVENTS')}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 'auto',
              pt: 4,
            }}
          >
            <Link
              color="secondary"
              sx={{ mx: 1, textDecoration: 'none' }}
              href={paths.CONTACT}
              onClick={(e) => {
                e.preventDefault();
                router.push(paths.CONTACT);
              }}
            >
              {t('CONTACT')}
            </Link>
            <Link
              color="secondary"
              sx={{ mx: 1, textDecoration: 'none' }}
              href={paths.PRIVACY_POLICY}
              onClick={(e) => {
                e.preventDefault();
                router.push(paths.PRIVACY_POLICY);
              }}
            >
              {t('PRIVACY_POLICY')}
            </Link>
          </Box>
        </Container>
      </IonContent>
    </IonPage>
  );
};

import { FC } from 'react';
import { isApp } from '../../utils/platform';
import { AppHome } from './AppHome';
import { BrowserHome } from './BrowserHome';

export const Home: FC = () => {
  if (isApp) {
    return <AppHome />;
  }

  return <BrowserHome />;
};

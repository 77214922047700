import { useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import { useState } from 'react';

export const useInView = () => {
  const [inView, setInView] = useState(false);

  useIonViewDidEnter(() => {
    setInView(true);
  });

  useIonViewWillLeave(() => {
    setInView(false);
  });

  return inView;
};

import { AddPhotoAlternate } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetEvent, useUpdateEvent } from '../../services/useEvent';
import { useBackgroundImage } from '../../services/useStorage';
import { useFileUpload } from '../../utils/fileUpload';
import { Sx } from '../../utils/sx';
import { useGetHost } from '../../utils/useGetHost';
import { useNotification } from '../../utils/useNotification';

interface BackgroundImageButtonProps {
  sx?: Sx;
}

export const BackgroundImageButton: FC<BackgroundImageButtonProps> = ({
  sx,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { isHost } = useGetHost();
  const { uploadBackgroundImage } = useBackgroundImage();
  const [loading, setLoading] = useState(false);
  const { pushError } = useNotification();
  const { updateEvent } = useUpdateEvent();
  const { openFileSelector } = useFileUpload();

  const onFileUpload = async (files: File[] | null) => {
    if (!files || !event) return;

    setLoading(true);
    try {
      const { small, large } = await uploadBackgroundImage(event.id, files[0]);
      await updateEvent(event.id, {
        thumbnailURL: small,
        backgroundImageURL: large,
      });
    } catch (e) {
      pushError(e);
    }
    setLoading(false);
  };

  if (!isHost || event?.backgroundImageURL || event?.canceledAt) return null;

  return (
    <LoadingButton
      loading={loading}
      loadingIndicator={<CircularProgress color="secondary" size={16} />}
      onClick={(e) => openFileSelector(e, onFileUpload)}
      variant="outlined"
      color="secondary"
      startIcon={<AddPhotoAlternate />}
      sx={sx}
    >
      {t('ADD_IMAGE')}
    </LoadingButton>
  );
};

import { TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sx } from '../../utils/sx';
import {
  TITLE_MAX_CHARACTERS,
  TITLE_MIN_CHARACTERS,
  isEventTitleValid,
} from './utils';

interface EventTitleProps {
  sx?: Sx;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  errorIfEmpty?: boolean;
  placeholder?: string;
}

export const EventTitle: FC<EventTitleProps> = ({
  sx,
  value,
  onChange,
  disabled,
  errorIfEmpty,
  placeholder,
}) => {
  const { t } = useTranslation();
  const [isFocus, setIsFocus] = useState(false);
  const valueNotLongEnough = value.trim().length < TITLE_MIN_CHARACTERS;
  const error = value
    ? valueNotLongEnough
      ? errorIfEmpty || !isFocus
      : !isEventTitleValid(value)
    : errorIfEmpty;

  return (
    <TextField
      placeholder={placeholder}
      disabled={disabled}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
      sx={sx}
      label={t('EVENT_TITLE')}
      value={value}
      onChange={({ target }) => onChange?.(target.value)}
      fullWidth
      error={error}
      helperText={
        error &&
        t(valueNotLongEnough ? 'MIN_CHARACTERS' : 'MAX_CHARACTERS', {
          minCharacters: TITLE_MIN_CHARACTERS,
          maxCharacters: TITLE_MAX_CHARACTERS,
        })
      }
    />
  );
};

import { App } from '@capacitor/app';
import { useEffect, useState } from 'react';

export const useAppIsActive = () => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    App.addListener('appStateChange', ({ isActive }) => {
      setActive(isActive);
    });
  }, []);

  return active;
};

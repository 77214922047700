import {
  Avatar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Guest } from '../../services/useGuest';
import { theme } from '../../theme';
import { getFullName, getInitials } from '../../utils/userName';

interface SimilarGuestsDialogProps {
  open: boolean;
  similarGuests: Guest[];
  onValidate?: (guestId: string | null) => void;
}

export const SimilarGuestsDialog: FC<SimilarGuestsDialogProps> = ({
  open,
  similarGuests,
  onValidate,
}) => {
  const { t } = useTranslation();
  const plural = similarGuests.length > 1;

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>
        {t(
          plural
            ? 'SIMILAR_GUESTS_DIALOG_TITLE_PLURAL'
            : 'SIMILAR_GUESTS_DIALOG_TITLE',
          { name: similarGuests[0] && getFullName(similarGuests[0]) },
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            plural
              ? 'SIMILAR_GUESTS_DIALOG_TEXT_PLURAL'
              : 'SIMILAR_GUESTS_DIALOG_TEXT',
          )}
        </DialogContentText>
        <List>
          {similarGuests.map((guest) => (
            <ListItem key={guest.id} disablePadding>
              <ListItemButton onClick={() => onValidate?.(guest.id)}>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.common.white,
                    }}
                    src={guest.thumbnailURL || undefined}
                  >
                    {getInitials(guest)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  <Typography component="span">{t('I_AM')}</Typography>{' '}
                  <Typography component="span" sx={{ fontWeight: 800 }}>
                    {getFullName(guest)}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem disablePadding>
            <ListItemButton onClick={() => onValidate?.(null)}>
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor: theme.palette.grey[500],
                    color: theme.palette.common.white,
                  }}
                />
              </ListItemAvatar>
              <ListItemText>{t('NO_ITS_NOT_ME')}</ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </DialogContent>
    </Dialog>
  );
};

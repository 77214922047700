import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
  eachMinuteOfInterval,
  format,
  getMinutes,
  isValid,
  set,
  setMinutes,
  startOfMinute,
} from 'date-fns';
import { FC, useMemo } from 'react';
import { Sx } from '../../utils/sx';

const STEP_IN_MINUTES = 15;

const roundToNearestMinutes = (
  date: Date,
  interval: number,
  method: 'floor' | 'ceil',
) => {
  const minutes = getMinutes(date) / interval;
  const roundedMinutes =
    method === 'floor' ? Math.floor(minutes) : Math.ceil(minutes);
  return setMinutes(startOfMinute(date), roundedMinutes * interval);
};

interface TimePickerProps {
  value: Date;
  onChange?: (value: Date) => void;
  label?: string;
  minTime?: Date;
  disabled?: boolean;
  sx?: Sx;
}

export const TimePicker: FC<TimePickerProps> = ({
  value,
  onChange,
  label,
  minTime,
  disabled,
  sx,
}) => {
  const safeValue = useMemo(
    () => (isValid(value) ? value : set(new Date(), { hours: 0, minutes: 0 })),
    [value],
  );

  const start = roundToNearestMinutes(
    minTime || set(safeValue, { hours: 0, minutes: 0 }),
    STEP_IN_MINUTES,
    'ceil',
  );

  const end = roundToNearestMinutes(
    set(safeValue, { hours: 23, minutes: 59 }),
    STEP_IN_MINUTES,
    'floor',
  );

  const times = eachMinuteOfInterval({ start, end }, { step: STEP_IN_MINUTES });

  const formatedValue = format(
    roundToNearestMinutes(safeValue, STEP_IN_MINUTES, 'ceil'),
    'H:m',
  );

  const timesWithValue = times
    .map((time) => format(time, 'H:m'))
    .includes(formatedValue)
    ? times
    : [safeValue, ...times];

  return (
    <Box sx={sx}>
      <FormControl fullWidth disabled={disabled}>
        <InputLabel id="time-picker-select-label">{label}</InputLabel>
        <Select
          labelId="time-picker-select-label"
          value={formatedValue}
          label={label}
          onChange={({ target }) => {
            const [hours, minutes] = target.value.split(':');
            onChange?.(
              set(safeValue, {
                hours: Number(hours),
                minutes: Number(minutes),
              }),
            );
          }}
        >
          {timesWithValue.map((time) => {
            const val = format(time, 'H:m');
            return (
              <MenuItem key={val} value={val}>
                {format(time, 'p')}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

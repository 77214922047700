import { ArrowDropDown, Event } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Paper,
  ThemeProvider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetEvent } from '../../services/useEvent';
import {
  Answer,
  answers,
  useGetGuest,
  useUpdateGuest,
} from '../../services/useGuest';
import { theme } from '../../theme';
import { eventIsPast } from '../../utils/eventDate';
import { Sx } from '../../utils/sx';
import { useAddToCalendar } from '../../utils/useAddToCalendar';
import { useDialog } from '../../utils/useDialog';
import { useGetHost } from '../../utils/useGetHost';
import { useMenu } from '../../utils/useMenu';
import { useNotification } from '../../utils/useNotification';
import { DeclinePostDialog } from './DeclinePostDialog';
import { GuestAnswerMenu } from './GuestAnswerMenu';

interface GuestAnswerProps {
  sx?: Sx;
}

export const GuestAnswer: FC<GuestAnswerProps> = ({ sx }) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { guest } = useGetGuest();
  const { isHost } = useGetHost();
  const addToCalendar = useAddToCalendar();
  const { updateGuest } = useUpdateGuest();
  const { pushError } = useNotification();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [openDeclinePostDialog, closeDeclinePostDialog] = useDialog((open) => (
    <DeclinePostDialog open={open} onClose={closeDeclinePostDialog} />
  ));

  const [openMenu, closeMenu] = useMenu(() => (
    <Box>
      <GuestAnswerMenu
        guest={guest}
        onClose={closeMenu}
        openDeclinePostDialog={openDeclinePostDialog}
      />
    </Box>
  ));

  if (
    !event ||
    !guest ||
    !guest.answer ||
    eventIsPast(event) ||
    event?.canceledAt ||
    isHost
  ) {
    return null;
  }

  const onAddToCalendar = async () => {
    try {
      await addToCalendar(event);
      await updateGuest(event.id, guest.id, { addedToCalendar: true });
    } catch (e) {
      pushError(e);
    }
  };

  const guestAnswerInfos = answers[guest.answer];
  const GuestAnswerIcon = guestAnswerInfos?.Icon;

  return (
    <Paper sx={sx}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 3,
          py: 2,
        }}
      >
        <Typography variant="h6">{t('YOUR_ANSWER')}</Typography>
        <ThemeProvider
          theme={{
            ...theme,
            palette: {
              ...theme.palette,
              primary: { main: guestAnswerInfos.color },
            },
          }}
        >
          <Button
            onClick={openMenu}
            variant="contained"
            size={isMobile ? 'small' : 'medium'}
            startIcon={GuestAnswerIcon && <GuestAnswerIcon />}
            endIcon={<ArrowDropDown />}
            sx={{ color: theme.palette.common.white }}
          >
            {t(guestAnswerInfos.labelMe)}
          </Button>
        </ThemeProvider>
      </Box>
      {guest.answer !== Answer.DO_NOT_PARTICIPATE && (
        <>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              startIcon={<Event />}
              variant="outlined"
              onClick={onAddToCalendar}
              sx={{ m: 2 }}
            >
              {t('ADD_TO_CALENDAR')}
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
};

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmationCode } from '../../components/fields/ConfirmationCode';
import { EventPost } from '../../components/fields/EventPost';
import { Phone } from '../../components/fields/Phone';
import {
  isConfirmationCodeValid,
  isEventPostValid,
  isPhoneValid,
} from '../../components/fields/utils';
import { useGetUser, useSignIn } from '../../services/useAuth';
import { useGetEvent } from '../../services/useEvent';
import { useGetGuest } from '../../services/useGuest';
import {
  NewPost,
  Range,
  convertNewPostToPost,
  useCreatePost,
} from '../../services/usePost';
import { useNotification } from '../../utils/useNotification';

interface AddPostDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AddPostDialog: FC<AddPostDialogProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { user } = useGetUser();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const { guest } = useGetGuest();
  const submitRef = useRef<HTMLButtonElement>(null);
  const { signIn, confirmCode } = useSignIn(submitRef.current);
  const { pushError } = useNotification();
  const { createPost } = useCreatePost();
  const [post, setPost] = useState<NewPost>({
    range: Range.PUBLIC,
    message: null,
  });
  const buttonDisabled = useMemo(() => {
    switch (step) {
      case 0:
        return !isEventPostValid(post.message, true);

      case 1:
        return !isPhoneValid(phoneNumber);

      case 2:
        return !isConfirmationCodeValid(confirmationCode);

      default:
        return true;
    }
  }, [confirmationCode, phoneNumber, post.message, step]);

  if (!guest || !event) return null;

  const onPublish = async () => {
    if (!user) {
      setStep((prev) => prev + 1);
      return;
    }

    setLoading(true);
    try {
      await createPost({
        ...post,
        eventId: event.id,
        userId: user.id,
      });
      onClose();
    } catch (e) {
      pushError(e);
    }
    setLoading(false);
  };

  const onSignIn = async () => {
    setLoading(true);
    try {
      await signIn(phoneNumber);
      setStep((prev) => prev + 1);
    } catch (e) {
      pushError(e);
    }
    setLoading(false);
  };

  const onConfirmCode = async () => {
    setLoading(true);
    try {
      const createdUser = await confirmCode(confirmationCode, guest);
      setStep(0);
      await createPost({
        ...post,
        eventId: event.id,
        userId: createdUser.id,
      });
      onClose();
    } catch (e) {
      pushError(e);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>{t('ADD_POST')}</DialogTitle>
      <DialogContent>
        {step === 0 && (
          <EventPost
            post={convertNewPostToPost(post)}
            onRangeChange={(range) => setPost((prev) => ({ ...prev, range }))}
            onMessageChange={(message) =>
              setPost((prev) => ({ ...prev, message }))
            }
          />
        )}
        {step === 1 && (
          <>
            <DialogContentText sx={{ mb: 4 }}>
              {t('ADD_POST_PHONE_TEXT')}
            </DialogContentText>
            <Phone
              sx={{ mt: 1 }}
              value={phoneNumber}
              onChange={setPhoneNumber}
            />
          </>
        )}
        {step === 2 && (
          <ConfirmationCode
            sx={{ mt: 1 }}
            value={confirmationCode}
            onChange={setConfirmationCode}
            onValidate={onConfirmCode}
          />
        )}
      </DialogContent>
      <DialogActions>
        {step > 0 ? (
          <Button
            startIcon={<KeyboardArrowLeft />}
            onClick={() => setStep((prev) => prev - 1)}
          >
            {t('BACK')}
          </Button>
        ) : (
          <Button onClick={onClose}>{t('CANCEL')}</Button>
        )}
        <LoadingButton
          loading={loading}
          id={'AddPostDialog-submit-button'}
          ref={submitRef}
          variant="contained"
          endIcon={step !== 2 && !user && <KeyboardArrowRight />}
          onClick={() => {
            switch (step) {
              case 0:
                onPublish();
                break;

              case 1:
                onSignIn();
                break;

              case 2:
                onConfirmCode();
                break;
            }
          }}
          disabled={buttonDisabled}
        >
          {t(step !== 2 && !user ? 'CONTINUE' : 'PUBLISH')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

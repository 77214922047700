import {
  AddAPhoto,
  CameraAlt,
  Check,
  Edit,
  MoreHoriz,
  Warning,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Tooltip,
  Typography,
  alpha,
} from '@mui/material';
import { addDays, format, isFuture } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetUser, useUpdateUser } from '../../services/useAuth';
import { useUserPhoto } from '../../services/useStorage';
import { theme } from '../../theme';
import { useFileUpload } from '../../utils/fileUpload';
import { useDialog } from '../../utils/useDialog';
import { useMenu } from '../../utils/useMenu';
import { useNotification } from '../../utils/useNotification';
import {
  RENAME_DELAY_IN_DAYS,
  getFullName,
  getInitials,
} from '../../utils/userName';
import { FirstAndLastName } from '../fields/FirstAndLastName';
import { isFirstAndLastNameValid } from '../fields/utils';
import { UserActionMenu } from './UserActionMenu';
import { UserDeleteDialog } from './UserDeleteDialog';

interface UserProfileDialogProps {
  open: boolean;
  onClose: () => void;
}

export const UserProfileDialog: FC<UserProfileDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { user } = useGetUser();
  const { updateUser } = useUpdateUser();
  const [editMode, setEditMode] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [nameLoading, setNameLoading] = useState(false);
  const [photoLoading, setPhotoLoading] = useState(false);
  const [photoHover, setPhotoHover] = useState(false);
  const { pushError } = useNotification();
  const { uploadUserPhoto } = useUserPhoto();
  const { openFileSelector } = useFileUpload();

  const [openUserDeleteDialog, closeUserDeleteDialog] = useDialog((isOpen) => (
    <UserDeleteDialog open={isOpen} onClose={closeUserDeleteDialog} />
  ));

  const [openMenu, closeMenu] = useMenu(() => (
    <UserActionMenu
      onClose={closeMenu}
      openUserDeleteDialog={openUserDeleteDialog}
      onSignOut={onClose}
    />
  ));

  useEffect(() => {
    if (!user?.firstName || !user.lastName) return;
    setFirstName(user.firstName);
    setLastName(user.lastName);
  }, [user]);

  const onUpdateName = async () => {
    if (!user || (firstName === user.firstName && lastName === user.lastName)) {
      setEditMode(false);
      return;
    }

    setNameLoading(true);
    try {
      await updateUser(user.id, {
        firstName,
        lastName,
        editedNameAt: new Date(),
      });
      setEditMode(false);
    } catch (e) {
      pushError(e);
    }
    setNameLoading(false);
  };

  const onFileUpload = async (files: File[] | null) => {
    if (!files || !user) return;

    setPhotoLoading(true);
    try {
      const { small, large } = await uploadUserPhoto(user.id, files[0]);
      await updateUser(user.id, { thumbnailURL: small, photoURL: large });
    } catch (e) {
      pushError(e);
    }
    setPhotoLoading(false);
  };

  if (!user) return null;

  const renameUnlockDate =
    user.editedNameAt && addDays(user.editedNameAt, RENAME_DELAY_IN_DAYS);
  const renameDisabled = !!renameUnlockDate && isFuture(renameUnlockDate);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <IconButton
        onClick={openMenu}
        sx={{ alignSelf: 'flex-end', mt: 1, mr: 1 }}
      >
        <MoreHoriz />
      </IconButton>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <Tooltip title={t(user.thumbnailURL ? 'REPLACE_PHOTO' : 'ADD_PHOTO')}>
          <ButtonBase
            onMouseEnter={() => setPhotoHover(true)}
            onMouseLeave={() => setPhotoHover(false)}
            sx={{ mb: 3, borderRadius: '50%', position: 'relative' }}
            onClick={(e) => openFileSelector(e, onFileUpload)}
          >
            <Avatar
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                height: 100,
                width: 100,
                fontSize: 50,
              }}
              src={photoLoading ? undefined : user.thumbnailURL || undefined}
            >
              {photoLoading ? (
                <CircularProgress color="secondary" size={40} />
              ) : (
                user.firstName &&
                user.lastName &&
                getInitials({
                  firstName: user.firstName,
                  lastName: user.lastName,
                })
              )}
            </Avatar>
            {photoHover && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: theme.palette.common.white,
                  height: 40,
                  width: 40,
                  borderRadius: '50%',
                  fontSize: 20,
                  bgcolor: alpha(theme.palette.common.black, 0.5),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {user.thumbnailURL ? <CameraAlt /> : <AddAPhoto />}
              </Box>
            )}
          </ButtonBase>
        </Tooltip>

        {!user.thumbnailURL && (
          <LoadingButton
            loading={photoLoading}
            variant="contained"
            startIcon={<AddAPhoto />}
            sx={{ mb: 3 }}
            onClick={(e) => openFileSelector(e, onFileUpload)}
          >
            {t('ADD_PHOTO')}
          </LoadingButton>
        )}

        {user.firstName && user.lastName && !editMode ? (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              <Typography variant="h6" sx={{ m: 1 }}>
                {getFullName({
                  firstName: user.firstName,
                  lastName: user.lastName,
                })}
              </Typography>
              <Tooltip title={t('EDIT')}>
                <IconButton sx={{ m: 1 }} onClick={() => setEditMode(true)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            </Box>
          </>
        ) : (
          <>
            <FirstAndLastName
              firstName={firstName}
              lastName={lastName}
              disabled={renameDisabled}
              onFirstNameChange={(val) => setFirstName(val)}
              onLastNameChange={(val) => setLastName(val)}
              sx={{ mb: 2 }}
            />
            <DialogContentText
              sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
            >
              {!renameDisabled && <Warning sx={{ mr: 1 }} />}
              {t(
                renameDisabled
                  ? 'USER_PROFILE_RENAME_DISABLED'
                  : 'USER_PROFILE_RENAME_WARNING',
                {
                  days: RENAME_DELAY_IN_DAYS,
                  date: renameUnlockDate && format(renameUnlockDate, 'PPPp'),
                },
              )}
            </DialogContentText>
            <LoadingButton
              loading={nameLoading}
              startIcon={<Check />}
              variant="outlined"
              onClick={onUpdateName}
              disabled={!isFirstAndLastNameValid(firstName, lastName)}
            >
              {t('APPLY')}
            </LoadingButton>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CLOSE')}</Button>
      </DialogActions>
    </Dialog>
  );
};

import { Box, ButtonBase } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import AppStoreBadgeEn from '../images/app-store-badge-en.svg';
import AppStoreBadgeFr from '../images/app-store-badge-fr.svg';
import PlayStoreBadgeEn from '../images/google-play-badge-en.png';
import PlayStoreBadgeFr from '../images/google-play-badge-fr.png';
import {
  ANDROID_STORE_LINK,
  IOS_STORE_LINK,
  isAndroid,
  isIos,
} from '../utils/platform';
import { Sx } from '../utils/sx';

export const StoreBadges: FC<{ sx?: Sx }> = ({ sx }) => {
  const { i18n } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
    >
      {!isAndroid && (
        <ButtonBase
          href={IOS_STORE_LINK}
          target="_blank"
          rel="noreferrer"
          sx={{ py: '7px', mx: 1 }}
        >
          <Box
            component="img"
            src={
              i18n.resolvedLanguage === 'fr' ? AppStoreBadgeFr : AppStoreBadgeEn
            }
            sx={{ height: '50px' }}
          />
        </ButtonBase>
      )}
      {!isIos && (
        <ButtonBase href={ANDROID_STORE_LINK} target="_blank" rel="noreferrer">
          <Box
            component="img"
            src={
              i18n.resolvedLanguage === 'fr'
                ? PlayStoreBadgeFr
                : PlayStoreBadgeEn
            }
            sx={{ height: '64px', mx: 1 }}
          />
        </ButtonBase>
      )}
    </Box>
  );
};

import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  useIonRouter,
} from '@ionic/react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  Toolbar,
  Typography,
  alpha,
} from '@mui/material';
import { mail, personCircleOutline } from 'ionicons/icons';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Icon } from '../images/HopHopIcon.svg';
import { GuestReNameDialog } from '../pages/Event/GuestReNameDialog';
import { FILTER_PARAM, FilterId } from '../pages/MyEvents';
import { paths } from '../router';
import { useGetUser } from '../services/useAuth';
import { useGetGuest } from '../services/useGuest';
import { theme } from '../theme';
import { isApp } from '../utils/platform';
import { Sx } from '../utils/sx';
import { useDialog } from '../utils/useDialog';
import { useMenu } from '../utils/useMenu';
import { getFullName, getInitials } from '../utils/userName';
import { UserGuestMenu } from './UserGuestMenu';
import { GuestIconAndName } from './user/UserIconAndName';
import { UserLoginDialog } from './user/UserLoginDialog';
import { UserProfileDialog } from './user/UserProfileDialog';

interface TopBarProps {
  hideSignInButton?: boolean;
  title?: string;
  sx?: Sx;
  additionnalContent?: ReactNode;
  invitations?: number;
}

export const TopBar: FC<TopBarProps> = ({
  hideSignInButton,
  title,
  sx,
  additionnalContent,
  invitations,
}) => {
  const { t } = useTranslation();
  const { user, loading } = useGetUser();
  const { guest } = useGetGuest();
  const router = useIonRouter();

  const [openLoginDialog, closeLoginDialog] = useDialog((open) => (
    <UserLoginDialog open={open} onClose={closeLoginDialog} />
  ));

  const [openUserDialog, closeUserDialog] = useDialog((open) => (
    <UserProfileDialog open={open} onClose={closeUserDialog} />
  ));

  const [openRenameDialog, closeRenameDialog] = useDialog((open) => (
    <GuestReNameDialog
      open={open}
      onClose={closeRenameDialog}
      onOpenLogin={() => {
        openUserDialog();
        openLoginDialog();
      }}
    />
  ));

  const [openUserGuestMenu, closeUserGuestMenu] = useMenu(() => (
    <Box>
      <UserGuestMenu
        onClose={closeUserGuestMenu}
        openUserDialog={openUserDialog}
        openLoginDialog={openLoginDialog}
        openRenameDialog={openRenameDialog}
      />
    </Box>
  ));

  if (isApp) {
    return (
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            {router.routeInfo.pathname === paths.HOME ? (
              !!invitations && (
                <IonButton
                  onClick={() =>
                    router.push(
                      `${paths.MY_EVENTS}?${FILTER_PARAM}=${FilterId.INVITATION}`,
                    )
                  }
                >
                  <Badge
                    badgeContent={invitations}
                    color="error"
                    componentsProps={{
                      badge: { style: { top: 6, right: 6 } },
                    }}
                  >
                    <IonIcon icon={mail} slot="start" />
                  </Badge>
                </IonButton>
              )
            ) : (
              <IonBackButton text={t('BACK')} defaultHref={paths.HOME} />
            )}
          </IonButtons>
          {title && <IonTitle>{title}</IonTitle>}
          {!loading && (
            <IonButtons slot="end">
              {user || guest ? (
                <IonButton onClick={openUserGuestMenu}>
                  <IonIcon icon={personCircleOutline} slot="start" />
                </IonButton>
              ) : (
                !hideSignInButton && (
                  <IonButton onClick={() => openLoginDialog()}>
                    {t('SIGN_IN')}
                  </IonButton>
                )
              )}
            </IonButtons>
          )}
        </IonToolbar>
        {additionnalContent}
      </IonHeader>
    );
  }

  return (
    <Box sx={sx}>
      <Toolbar
        sx={{
          px: 1,
          backgroundImage: `linear-gradient(${alpha(
            theme.palette.common.black,
            0.4,
          )}, ${alpha(theme.palette.common.black, 0)})`,
        }}
      >
        <ButtonBase
          href={paths.HOME}
          onClick={(e) => {
            e.preventDefault();
            router.push(paths.HOME, 'root');
          }}
        >
          <Box
            component={Icon}
            sx={{ height: 40, width: 40, mr: [0, 0.5, 1], pb: 0.5 }}
          />
          <Typography
            sx={{
              color: theme.palette.common.white,
              px: 1,
              fontFamily: 'LeckerliOne',
              whiteSpace: 'nowrap',
              fontSize: [20, 26, 30],
            }}
          >
            Hop Hop
          </Typography>
        </ButtonBase>

        {!loading && (
          <Box
            sx={{
              ml: 'auto',
              minWidth: 0,
              overflow: 'hidden',
            }}
          >
            {user || guest ? (
              <>
                <Button
                  color="inherit"
                  sx={{ textTransform: 'none' }}
                  onClick={openUserGuestMenu}
                >
                  {guest ? (
                    <GuestIconAndName guest={guest} disableClick darkMode />
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        overflow: 'hidden',
                      }}
                    >
                      <Avatar
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.common.white,
                        }}
                        src={user?.thumbnailURL || undefined}
                      >
                        {user?.firstName &&
                          user?.lastName &&
                          getInitials({
                            firstName: user.firstName,
                            lastName: user.lastName,
                          })}
                      </Avatar>
                      <Typography
                        sx={{
                          color: theme.palette.common.white,
                          ml: 1,
                          fontWeight: 800,
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          textAlign: 'left',
                        }}
                      >
                        {user?.firstName &&
                          user?.lastName &&
                          getFullName({
                            firstName: user.firstName,
                            lastName: user.lastName,
                          })}
                      </Typography>
                    </Box>
                  )}
                </Button>
              </>
            ) : (
              !hideSignInButton && (
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => openLoginDialog()}
                >
                  {t('SIGN_IN')}
                </Button>
              )
            )}
          </Box>
        )}
      </Toolbar>
      {additionnalContent}
    </Box>
  );
};

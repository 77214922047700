import { isNameValid } from '../components/fields/utils';
import { ls } from './localStorage';

export const RENAME_DELAY_IN_DAYS = 15;

export interface Name {
  firstName: string;
  lastName: string;
}

export const formatName = (name: string) =>
  name
    .trim()
    .split('')
    .map((char, i, array) => {
      const previousLetter = array[i - 1];
      if (!previousLetter || [' ', "'", '-'].includes(previousLetter)) {
        return char.toLocaleUpperCase();
      }
      return char.toLocaleLowerCase();
    })
    .join('');

export const getFullName = (name: Name) =>
  `${formatName(name.firstName)} ${formatName(name.lastName)}`;

export const getInitials = (name: Name) =>
  `${name.firstName[0].toUpperCase()}${name.lastName[0].toUpperCase()}`;

export const LS_USER_NAME_KEY = 'USER_NAME';

export const getNameFromLocalStorage = async () => {
  const name = await ls.get<Name>(LS_USER_NAME_KEY);

  if (
    typeof name?.firstName === 'string' &&
    isNameValid(name.firstName) &&
    typeof name?.lastName === 'string' &&
    isNameValid(name.lastName)
  ) {
    return name;
  }

  return null;
};

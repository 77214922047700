const fallbackCopyTextToClipboard = (text: string) => {
  const textArea = document.createElement('textarea');
  // eslint-disable-next-line immutable/no-mutation
  textArea.value = text;
  // eslint-disable-next-line immutable/no-mutation
  textArea.style.top = '0';
  // eslint-disable-next-line immutable/no-mutation
  textArea.style.left = '0';
  // eslint-disable-next-line immutable/no-mutation
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (e) {}

  document.body.removeChild(textArea);
};

export const copyTextToClipboard = async (text: string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  return navigator.clipboard.writeText(text);
};

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { GuestIconAndName } from '../../components/user/UserIconAndName';
import { useGetEvent } from '../../services/useEvent';
import { Guest, useDeleteGuest } from '../../services/useGuest';
import { useNotification } from '../../utils/useNotification';

interface AdminGuestDeleteDialogProps {
  open: boolean;
  onClose: () => void;
  guest: Guest | undefined;
}

export const AdminGuestDeleteDialog: FC<AdminGuestDeleteDialogProps> = ({
  open,
  onClose,
  guest,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { deleteGuest, loading } = useDeleteGuest();
  const { pushError } = useNotification();

  if (!guest || !event) return null;

  const onValidate = async () => {
    try {
      await deleteGuest(event.id, guest.id);
      onClose();
    } catch (e) {
      pushError(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t('DELETE_GUEST_DIALOG_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 2 }}>
          {t('DELETE_GUEST_DIALOG_TEXT')}
        </DialogContentText>
        <GuestIconAndName guest={guest} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={onValidate}
          color="error"
        >
          {t('DELETE_GUEST_DIALOG_ACTION')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

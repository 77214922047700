import {
  Box,
  Typography,
  TypographyProps as MuiTypographyProps,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sx } from '../utils/sx';
import { LinkifiedText } from './LinkifiedText';

const LONG_TEXT_LENGTH = 200;

interface ExpandableTextProps {
  text: string;
  linkified?: boolean;
  sx?: Sx;
  TypographyProps?: MuiTypographyProps;
}

export const ExpandableText: FC<ExpandableTextProps> = ({
  text,
  linkified,
  sx,
  TypographyProps = {},
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const longText = text.length > LONG_TEXT_LENGTH;
  const shortenedText = text?.substring(0, LONG_TEXT_LENGTH);

  return (
    <Box sx={sx}>
      {linkified ? (
        <LinkifiedText>
          <Typography component="span" {...TypographyProps}>
            {open ? text : `${shortenedText}${longText ? '...' : ''}`}
          </Typography>
        </LinkifiedText>
      ) : (
        <Typography component="span" {...TypographyProps}>
          {open ? text : `${shortenedText}${longText ? '...' : ''}`}
        </Typography>
      )}

      {longText && (
        <Typography
          sx={{ cursor: 'pointer', fontWeight: 800, whiteSpace: 'nowrap' }}
          component={open ? 'p' : 'span'}
          onClick={() => setOpen((prev) => !prev)}
        >
          {' '}
          {t(open ? 'SEE_LESS' : 'SEE_MORE')}
        </Typography>
      )}
    </Box>
  );
};

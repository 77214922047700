import { Share } from '@capacitor/share';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InviteDialog } from '../pages/Event/InviteDialog';
import { useGetEvent } from '../services/useEvent';
import { getEventUrl } from './eventUrl';
import { isApp } from './platform';
import { useDialog } from './useDialog';
import { useGetHost } from './useGetHost';
import { getFullName } from './userName';

export const useInvite = () => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { eventHost, isHost } = useGetHost();
  const [openInviteDialog, closeInviteDialog] = useDialog((open) => (
    <InviteDialog open={open} onClose={closeInviteDialog} />
  ));

  const openInviteModal = useCallback(async () => {
    if (!event) return;

    if (isApp) {
      try {
        await Share.share({
          title: t('SHARE_MODAL_TITLE', { eventTitle: event.title }),
          text: t(isHost ? 'SHARE_MODAL_TEXT_HOST' : 'SHARE_MODAL_TEXT_GUEST', {
            hostName: eventHost ? getFullName(eventHost) : '',
          }),
          url: getEventUrl(event.id),
          dialogTitle: t('INVITE_DIALOG_TEXT'),
        });
      } catch (e) {}
      return;
    }

    openInviteDialog();
  }, [event, eventHost, isHost, openInviteDialog, t]);

  return { openInviteModal };
};

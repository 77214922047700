import { differenceInHours } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadGoogleMaps } from '../googleMaps';
import { getTimezone } from '../utils/timezone';
import { useDebounce } from '../utils/useDebounce';
import { Updater } from '../utils/valOrUpdater';

const placeFields: (keyof google.maps.places.PlaceResult)[] = [
  'formatted_address',
  'name',
  'opening_hours',
  'photos',
  'rating',
  'url',
  'user_ratings_total',
];

type CustomPlaceResult = Pick<
  google.maps.places.PlaceResult,
  | 'formatted_address'
  | 'html_attributions'
  | 'name'
  | 'opening_hours'
  | 'photos'
  | 'rating'
  | 'url'
  | 'user_ratings_total'
>;

type CustomPhotosPlaceResult = Pick<google.maps.places.PlaceResult, 'photos'>;

interface CustomAutocompletePrediction
  extends Partial<google.maps.places.AutocompletePrediction> {
  customText?: string;
}

interface LocationDetails
  extends Omit<CustomPlaceResult, 'photos' | 'opening_hours'> {
  openingHours: google.maps.places.PlaceOpeningHoursPeriod[] | null;
}

export interface Location {
  description: string;
  placeId: string | null;
  details: LocationDetails | null;
}

const getPlacePredictions = (
  service: google.maps.places.AutocompleteService,
  request: google.maps.places.AutocompletionRequest,
) =>
  new Promise<google.maps.places.AutocompletePrediction[] | null>(
    (resolve, reject) =>
      service.getPlacePredictions(request, (res, status) => {
        if (['OK', 'ZERO_RESULTS'].includes(status)) {
          resolve(res);
        } else {
          reject(new Error(status));
        }
      }),
  );

const getPlaceDetails = (
  service: google.maps.places.PlacesService,
  request: google.maps.places.PlaceDetailsRequest,
) =>
  new Promise<CustomPlaceResult | null>((resolve, reject) =>
    service.getDetails(request, (res, status) => {
      if (['OK', 'ZERO_RESULTS'].includes(status)) {
        resolve(res);
      } else {
        reject(new Error(status));
      }
    }),
  );

const getFirstPhotoUrl = (
  photos: google.maps.places.PlacePhoto[] | undefined,
) => photos?.[0].getUrl() || null;

export const photoURLIsExpired = (createdAt: Date) =>
  differenceInHours(new Date(), createdAt) > 48;

const timezone = getTimezone();

export const usePlacesAutocomplete = (search?: string, skip?: boolean) => {
  const debouncedSearch = useDebounce(search);
  const [predictions, setPredictions] =
    useState<CustomAutocompletePrediction[]>();
  const [loading, setLoading] = useState(false);
  const { i18n } = useTranslation();
  const [token, setToken] =
    useState<google.maps.places.AutocompleteSessionToken | null>(null);

  useEffect(() => {
    if (!debouncedSearch || skip) return;
    const customPrediction = {
      customText: `Utiliser uniquement "${debouncedSearch}"`,
      description: debouncedSearch,
    };
    setLoading(true);
    setPredictions([customPrediction]);
    loadGoogleMaps()
      .then(({ autocompleteService }) => {
        let sessionToken = token;
        if (!sessionToken) {
          sessionToken = new google.maps.places.AutocompleteSessionToken();
          setToken(sessionToken);
        }
        return getPlacePredictions(autocompleteService, {
          input: debouncedSearch,
          language: i18n.resolvedLanguage,
          region: timezone?.countryCode.toLowerCase(),
          sessionToken,
        });
      })
      .then((pred) => {
        setPredictions([customPrediction, ...(pred || [])]);
      })
      .catch(() => {
        setPredictions([customPrediction]);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, debouncedSearch, i18n.resolvedLanguage]);

  const resetToken = useCallback(() => setToken(null), []);

  const getDetails = useCallback(
    async (placeId: string) => {
      const sessionToken = token || undefined;
      resetToken();
      const { placeService } = await loadGoogleMaps();
      return getPlaceDetails(placeService, {
        placeId,
        language: i18n.resolvedLanguage,
        sessionToken,
        fields: placeFields,
      });
    },
    [i18n.resolvedLanguage, resetToken, token],
  );

  const onSelect = useCallback(
    async (
      newValue: CustomAutocompletePrediction | null,
      setValue?: Updater<{
        location: Location | null;
        locationPhotoURL: string | null;
        locationPhotoURLCreatedAt: Date | null;
      }>,
    ) => {
      setPredictions([]);

      const location: Location | null = newValue?.description
        ? {
            description: newValue.description,
            placeId: newValue.place_id || null,
            details: null,
          }
        : null;

      setValue?.({
        location,
        locationPhotoURL: null,
        locationPhotoURLCreatedAt: null,
      });

      if (!newValue?.place_id) {
        resetToken();
        return;
      }

      try {
        const details = await getDetails(newValue.place_id);
        if (!details) return;

        const { photos, opening_hours, ...rest } = details;
        const locationPhotoURL = getFirstPhotoUrl(photos);

        setValue?.((prev) => {
          if (
            !prev.location?.placeId ||
            prev.location.placeId !== newValue.place_id
          ) {
            return prev;
          }

          return {
            ...prev,
            location: {
              ...prev.location,
              details: {
                ...rest,
                openingHours: opening_hours?.periods || null,
              },
            },
            locationPhotoURL,
            locationPhotoURLCreatedAt: locationPhotoURL ? new Date() : null,
          };
        });
      } catch (e) {}
    },
    [getDetails, resetToken],
  );

  return { predictions, loading, onSelect };
};

export const useGetLocationPhotoUrl = () => {
  const { i18n } = useTranslation();

  const getLocationPhotoUrl = useCallback(
    async (placeId: string) => {
      const { placeService } = await loadGoogleMaps();
      const details = (await getPlaceDetails(placeService, {
        placeId,
        language: i18n.resolvedLanguage,
        fields: ['photos'],
      })) as CustomPhotosPlaceResult;

      return getFirstPhotoUrl(details?.photos);
    },
    [i18n.resolvedLanguage],
  );

  return { getLocationPhotoUrl };
};

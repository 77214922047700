import { useIonRouter } from '@ionic/react';
import { AddLink, TouchApp } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { paths } from '../../router';

export const JoinEventDialog: FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const [link, setLink] = useState('');

  const eventId = useMemo(() => {
    try {
      const url = new URL(link);
      return url.pathname.split('/e/')[1];
    } catch (e) {
      return '';
    }
  }, [link]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('JOIN_EVENT')}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TouchApp sx={{ color: 'GrayText', fontSize: 30, mr: 1 }} />
          <Typography>{t('JOIN_EVENT_BY_TAPPING_LINK')}</Typography>
        </Box>
        <Divider sx={{ my: 1 }}>{t('OR')}</Divider>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AddLink sx={{ color: 'GrayText', fontSize: 30, mr: 1 }} />
          <Typography>{t('JOIN_EVENT_BY_PASTING_LINK')}</Typography>
        </Box>
        <TextField
          value={link}
          onChange={({ target }) => setLink(target.value)}
          sx={{ mt: 2 }}
          fullWidth
          label={t('LINK')}
          error={!!link && !eventId}
          helperText={link && !eventId && t('INVITATION_LINK_NOT_VALID')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <Button
          disabled={!eventId}
          variant="contained"
          onClick={() => {
            onClose();
            router.push(generatePath(paths.EVENT, { eventId }));
          }}
        >
          {t('JOIN')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { ArrowDropDown } from '@mui/icons-material';
import {
  Box,
  Chip,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Guest } from '../services/useGuest';
import { Post, Range, ranges } from '../services/usePost';
import { Sx } from '../utils/sx';
import { useMenu } from '../utils/useMenu';
import { getFullName } from '../utils/userName';
import { GuestAvatar } from './GuestAvatar';
import { PostCreatedAt } from './PostCreatedAt';

interface PostHeaderProps {
  guest: Guest | null;
  post: Post;
  onRangeChange?: (range: Range) => void;
  loading?: boolean;
  sx?: Sx;
}

export const PostHeader: FC<PostHeaderProps> = ({
  guest,
  post,
  onRangeChange,
  loading,
  sx,
}) => {
  const { t } = useTranslation();
  const rangeDetails = ranges[post.range];
  const [openMenu, closeMenu] = useMenu(
    <Box>
      {Object.entries(ranges).map(([key, range], i) => (
        <MenuItem
          key={i}
          onClick={() => {
            key !== post.range && onRangeChange?.(key as Range);
            closeMenu();
          }}
          selected={post.range === key}
        >
          <ListItemIcon>
            <range.Icon fontSize="small" />
          </ListItemIcon>
          <ListItemText secondary={t(range.tooltip)}>
            {t(range.label)}
          </ListItemText>
        </MenuItem>
      ))}
    </Box>,
  );

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <GuestAvatar guest={guest} />
      <Box
        sx={{
          ml: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Typography
          color={guest ? undefined : 'GrayText'}
          sx={{
            fontWeight: 800,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          component="span"
        >
          {guest
            ? getFullName({
                firstName: guest.firstName,
                lastName: guest.lastName,
              })
            : t('REMOVED_GUEST')}
        </Typography>

        <PostCreatedAt updatedAt={post.updatedAt} createdAt={post.createdAt} />
      </Box>
      {(onRangeChange || post.range !== Range.PUBLIC) && (
        <Tooltip title={t(rangeDetails.tooltip)}>
          <Chip
            icon={<rangeDetails.Icon />}
            deleteIcon={<ArrowDropDown />}
            onDelete={onRangeChange ? openMenu : undefined}
            onClick={onRangeChange ? openMenu : undefined}
            label={t(rangeDetails.label)}
            size="small"
            sx={{ ml: 2 }}
          />
        </Tooltip>
      )}
      {loading && <CircularProgress size={20} sx={{ ml: 1 }} />}
    </Box>
  );
};

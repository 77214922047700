import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isCommentValid } from '../../components/fields/utils';
import { Comment, useUpdateComment } from '../../services/useComment';
import { useGetEvent } from '../../services/useEvent';
import { Post } from '../../services/usePost';
import { useNotification } from '../../utils/useNotification';

interface EditCommentDialogProps {
  open: boolean;
  onClose: () => void;
  comment: Comment;
  post: Post;
}

export const EditCommentDialog: FC<EditCommentDialogProps> = ({
  open,
  onClose,
  comment,
  post,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { updateComment, loading } = useUpdateComment();
  const { pushError } = useNotification();
  const [commentMessage, setCommentMessage] = useState(comment.message || '');

  const onUpdate = async () => {
    if (!event || !isCommentValid(commentMessage)) return;
    try {
      await updateComment(event.id, post.id, comment.id, {
        message: commentMessage.trim(),
        updatedAt: new Date(),
      });
      onClose();
    } catch (e) {
      pushError(e);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('EDIT_COMMENT')}</DialogTitle>
      <DialogContent>
        <TextField
          sx={{ mt: 1 }}
          multiline
          maxRows={3}
          value={commentMessage}
          onChange={({ target }) => setCommentMessage(target.value)}
          label={t('COMMENT')}
          fullWidth
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          onClick={onUpdate}
          disabled={!isCommentValid(commentMessage)}
        >
          {t('EDIT')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

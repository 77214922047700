import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { FirebaseError } from 'firebase/app';
import {
  ConfirmationResult,
  User as FbUser,
  PhoneAuthProvider,
  RecaptchaVerifier,
  deleteUser as fbDeleteUser,
  signOut as fbSignOut,
  onAuthStateChanged,
  signInWithCredential,
  signInWithPhoneNumber,
} from 'firebase/auth';
import {
  DocumentData,
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { auth, db, timestampConverter } from '../firebase';
import { ls } from '../utils/localStorage';
import { isApp } from '../utils/platform';
import {
  LS_USER_NAME_KEY,
  Name,
  getNameFromLocalStorage,
} from '../utils/userName';
import { useUpdate } from './useEntity';
import { getUserGuestConstraint, useUpdateMultipleGuests } from './useGuest';
import {
  deleteDeviceToken,
  deviceNotificationTokenAtom,
  disableTokenUploadAtom,
  getUserNotificationTokensCollection,
} from './useNotificationToken';

export interface User {
  id: string;
  createdAt: Date;
  phoneNumber: string;
  firstName: string | null;
  lastName: string | null;
  editedNameAt: Date | null;
  thumbnailURL: string | null;
  photoURL: string | null;
}

export const usersCollection = collection(db, 'users');

export const useGetUser = () => {
  const [user, setUser] = useState<User | null>(null);
  const [uid, setUid] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    const unsub = onAuthStateChanged(
      auth,
      (res) => {
        setUid(res?.uid || null);
        !res?.uid && setLoading(false);
        setError(undefined);
      },
      (e) => {
        setLoading(false);
        setError(e);
      },
    );

    return () => {
      unsub();
    };
  }, []);

  useEffect(() => {
    if (!uid) {
      setUser(null);
      return;
    }

    setLoading(true);
    const unsub = onSnapshot(
      doc(usersCollection, uid).withConverter<User, DocumentData>(
        timestampConverter,
      ),
      (docu) => {
        const data = docu.data();
        setUser(data || null);
        data && ls.remove(LS_USER_NAME_KEY);
        setLoading(false);
        setError(undefined);
      },
      (e) => {
        setLoading(false);
        setError(e);
      },
    );

    return () => {
      unsub();
    };
  }, [uid]);

  return { user, loading, error };
};

export const useSignIn = (button: HTMLButtonElement | null) => {
  const { t } = useTranslation();
  const [verifier, setVerifier] = useState<RecaptchaVerifier>();
  const [confirmationResult, setConfirmationResult] =
    useState<ConfirmationResult>();
  const [verificationId, setVerificationId] = useState<string | undefined>();

  useEffect(() => {
    if (button?.id && !isApp) {
      try {
        setVerifier(
          new RecaptchaVerifier(auth, button.id, { size: 'invisible' }),
        );
      } catch (e) {}
    }
  }, [button]);

  const signIn = useCallback(
    async (phoneNumber: string) => {
      if (isApp) {
        return new Promise<void>((resolve, reject) => {
          FirebaseAuthentication.addListener('phoneCodeSent', async (event) => {
            setVerificationId(event.verificationId);
            resolve();
          })
            .then(() =>
              FirebaseAuthentication.signInWithPhoneNumber({
                phoneNumber,
              }),
            )
            .catch(reject);
        });
      }

      if (!verifier) {
        throw new Error('RecaptchaVerifier not loaded');
      }
      const confirmation = await signInWithPhoneNumber(
        auth,
        phoneNumber,
        verifier,
      );
      setConfirmationResult(confirmation);
    },
    [verifier],
  );

  const confirmCode = useCallback(
    async (code: string, name?: Name) => {
      let user: Pick<FbUser, 'uid' | 'phoneNumber'> | null;

      try {
        if (isApp) {
          if (!verificationId) {
            throw new Error('No verification id');
          }

          const credential = PhoneAuthProvider.credential(verificationId, code);
          const { user: result } = await signInWithCredential(auth, credential);
          user = result;
        } else {
          if (!confirmationResult) {
            throw new Error('No confirmation result');
          }

          const { user: result } = await confirmationResult.confirm(code);
          user = result;
        }
      } catch (e) {
        let error = e as FirebaseError;
        if (
          [
            'auth/invalid-verification-code',
            'invalid-verification-code',
          ].includes(error.code)
        ) {
          error = { ...error, message: t('INVALID_CONFIRMATION_CODE') };
        }
        throw error;
      }

      if (!user) {
        throw new Error('No user');
      }

      const LSName = await getNameFromLocalStorage();
      const newName = {
        firstName: (name || LSName)?.firstName || null,
        lastName: (name || LSName)?.lastName || null,
      };

      const docRef = doc(usersCollection, user.uid).withConverter<
        User,
        DocumentData
      >(timestampConverter);
      const sfDoc = await getDoc(docRef);

      if (!sfDoc.exists()) {
        const userToUpload: Omit<User, 'id'> = {
          createdAt: new Date(),
          phoneNumber: user.phoneNumber || '',
          thumbnailURL: null,
          photoURL: null,
          editedNameAt: null,
          ...newName,
        };

        await setDoc(docRef, userToUpload);

        return { id: user.uid, ...userToUpload };
      }

      const oldUser = sfDoc.data();

      if (
        (!oldUser.firstName && newName.firstName) ||
        (!oldUser.lastName && newName.lastName)
      ) {
        await updateDoc(docRef, newName);
        return { ...oldUser, ...newName };
      }

      return oldUser;
    },
    [confirmationResult, t, verificationId],
  );

  return { signIn, confirmCode };
};

export const useUpdateUser = () => {
  const { update } = useUpdate();
  const { updateMultipleGuests } = useUpdateMultipleGuests();

  const updateUser = useCallback(
    async (userId: string, fields: Partial<User>) => {
      await update(usersCollection, userId, fields);
      await updateMultipleGuests([getUserGuestConstraint(userId)], {
        ...(fields.firstName ? { firstName: fields.firstName } : {}),
        ...(fields.lastName ? { lastName: fields.lastName } : {}),
        ...(fields.thumbnailURL !== undefined
          ? { thumbnailURL: fields.thumbnailURL }
          : {}),
        ...(fields.photoURL !== undefined ? { photoURL: fields.photoURL } : {}),
      });
    },
    [update, updateMultipleGuests],
  );

  return { updateUser };
};

export const useSignOut = () => {
  const { user } = useGetUser();
  const [deviceToken] = useRecoilState(deviceNotificationTokenAtom);
  const [, setDisableTokenUpload] = useRecoilState(disableTokenUploadAtom);

  return useCallback(async () => {
    if (!user) return;

    setDisableTokenUpload(true);
    if (isApp && deviceToken) {
      await deleteDeviceToken(
        getUserNotificationTokensCollection(user.id),
        deviceToken,
      );
    }
    await fbSignOut(auth);
    if (isApp) {
      await FirebaseAuthentication.signOut();
    }
    setDisableTokenUpload(false);
  }, [deviceToken, setDisableTokenUpload, user]);
};

export const deleteUser = async () => {
  if (isApp) {
    return FirebaseAuthentication.deleteUser();
  }
  const user = auth.currentUser;
  if (!user) return;
  return fbDeleteUser(user);
};

import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventPost } from '../../components/fields/EventPost';
import { isEventPostValid } from '../../components/fields/utils';
import { useGetUser } from '../../services/useAuth';
import { useGetEvent } from '../../services/useEvent';
import { useGetGuest, useUpdateGuest } from '../../services/useGuest';
import {
  NewPost,
  Range,
  convertNewPostToPost,
  useCreatePost,
} from '../../services/usePost';
import { useGetHost } from '../../utils/useGetHost';
import { useNotification } from '../../utils/useNotification';
import { formatName } from '../../utils/userName';

interface DeclinePostDialogProps {
  open: boolean;
  onClose: () => void;
}

export const DeclinePostDialog: FC<DeclinePostDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { createPost } = useCreatePost();
  const { updateGuest } = useUpdateGuest();
  const { user } = useGetUser();
  const { guest } = useGetGuest();
  const { pushError, pushNotification } = useNotification();
  const [post, setPost] = useState<NewPost>({
    range: Range.PRIVATE,
    message: null,
  });
  const [loading, setLoading] = useState(false);
  const { eventHost } = useGetHost();

  if (!event || !guest) return null;

  const onPublish = async () => {
    setLoading(true);
    try {
      await createPost({
        ...post,
        eventId: event.id,
        userId: user?.id || null,
      });
      await updateGuest(event.id, guest.id, { hasPostedDecline: true });
      eventHost &&
        pushNotification(
          t('DECLINE_POST_SUCCESS', {
            name: formatName(eventHost.firstName),
          }),
          { variant: 'success' },
        );
      onClose();
    } catch (e) {
      pushError(e);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{t('DECLINE_POST_DIALOG_TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {eventHost &&
            t('DECLINE_POST_DIALOG_TEXT', {
              name: formatName(eventHost.firstName),
            })}
        </DialogContentText>
        <EventPost
          sx={{ mt: 2 }}
          post={convertNewPostToPost(post)}
          onMessageChange={(message) =>
            setPost((prev) => ({ ...prev, message }))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('NO_THANKS')}</Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          disabled={!isEventPostValid(post.message, true)}
          onClick={onPublish}
        >
          {t('SEND')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

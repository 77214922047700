import { useMemo } from 'react';
import { useGetUser } from '../services/useAuth';
import { useGetEvent } from '../services/useEvent';
import { useGetGuests } from '../services/useGuest';

export const useGetHost = () => {
  const { user } = useGetUser();
  const { event } = useGetEvent();
  const { guests } = useGetGuests();

  const eventHost = useMemo(
    () => guests?.find(({ userId }) => userId === event?.userId),
    [event?.userId, guests],
  );

  const isHost = useMemo(
    () => !!user && event?.userId === user?.id,
    [event?.userId, user],
  );

  return { eventHost, isHost };
};

import { Box, Card, Typography } from '@mui/material';
import { format } from 'date-fns';
import { FC } from 'react';
import { theme } from '../theme';
import { Sx } from '../utils/sx';

interface VisualCalendarProps {
  sx?: Sx;
  date: Date | undefined;
  color?: string;
}

export const VisualCalendar: FC<VisualCalendarProps> = ({
  sx,
  date,
  color,
}) => {
  return (
    <Card
      sx={{
        width: 120,
        height: 120,
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Box
        sx={{
          backgroundColor: color,
          flexBasis: '25%',
          flexShrink: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: 1,
        }}
      >
        <Typography
          sx={{
            color: theme.palette.common.white,
            textAlign: 'center',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: 18,
          }}
        >
          {date && format(date, 'MMMM')}
        </Typography>
      </Box>
      <Typography sx={{ my: 'auto', fontSize: 60, textAlign: 'center' }}>
        {date && format(date, 'd')}
      </Typography>
    </Card>
  );
};

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetEvent } from '../../services/useEvent';
import {
  Answer,
  Guest,
  answers,
  useGetGuest,
  useUpdateGuest,
} from '../../services/useGuest';
import { useNotification } from '../../utils/useNotification';

interface GuestAnswerMenuProps {
  guest: Guest | null;
  onClose: () => void;
  openDeclinePostDialog: () => void;
}

export const GuestAnswerMenu: FC<GuestAnswerMenuProps> = ({
  guest: targetedGuest,
  onClose,
  openDeclinePostDialog,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const { guest } = useGetGuest();
  const { updateGuest } = useUpdateGuest();
  const { pushError, setAnswerNotificationsDisabled } = useNotification();

  if (!event || !targetedGuest) return null;

  const onUpdateAnswer = async (answer: Answer) => {
    onClose();
    setAnswerNotificationsDisabled(true);
    try {
      await updateGuest(event.id, targetedGuest.id, {
        answer,
        answeredAt: new Date(),
      });
      if (
        guest?.id === targetedGuest.id &&
        targetedGuest.userId !== event.userId &&
        answer === Answer.DO_NOT_PARTICIPATE &&
        (targetedGuest.userId || !targetedGuest.hasPostedDecline)
      ) {
        openDeclinePostDialog();
      }
    } catch (e) {
      pushError(e);
    }
    setAnswerNotificationsDisabled(false);
  };

  return (
    <>
      {[Answer.PARTICIPATE, Answer.MAYBE, Answer.DO_NOT_PARTICIPATE]
        .filter((answ) => answ && answ !== targetedGuest?.answer)
        .map((answ, i) => {
          const answerInfos = answ && answers[answ];
          const Icon = answerInfos?.Icon;
          return (
            <MenuItem key={i} onClick={() => onUpdateAnswer(answ)}>
              <ListItemIcon>
                {Icon && (
                  <Icon fontSize="small" sx={{ color: answerInfos?.color }} />
                )}
              </ListItemIcon>
              <ListItemText>
                {answerInfos &&
                  t(
                    guest?.id === targetedGuest.id
                      ? answerInfos?.labelMe
                      : answerInfos?.label,
                  )}
              </ListItemText>
            </MenuItem>
          );
        })}
    </>
  );
};

import { Star, StarHalfSharp, StarOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { FC } from 'react';
import { Sx } from '../utils/sx';

const FULL_NOTATION = 5;
// eslint-disable-next-line prefer-destructuring
const color = yellow[700];

interface StarsRatingProps {
  rating: number;
  ratingCount?: number;
  sx?: Sx;
}

export const StarsRating: FC<StarsRatingProps> = ({
  rating,
  ratingCount,
  sx,
}) => {
  const roundedNotation = Math.round(rating * 2) / 2;
  const fullStars = Math.floor(roundedNotation);
  const decimal = roundedNotation % 1;
  const emptyStars = Math.max(FULL_NOTATION - fullStars - (decimal ? 1 : 0), 0);

  return (
    <Box sx={{ ...sx, display: 'flex', alignItems: 'center' }}>
      <Typography color="GrayText" sx={{ mr: 1, fontSize: 14 }}>
        {rating}
      </Typography>
      {Array.from(Array(fullStars)).map((_, i) => (
        <Star key={i} sx={{ color, fontSize: 20 }} />
      ))}
      {!!decimal && <StarHalfSharp sx={{ color, fontSize: 20 }} />}
      {Array.from(Array(emptyStars)).map((_, i) => (
        <StarOutline key={i} sx={{ color, fontSize: 20 }} />
      ))}
      {ratingCount && (
        <Typography color="GrayText" sx={{ ml: 1, fontSize: 14 }}>
          {`(${ratingCount})`}
        </Typography>
      )}
    </Box>
  );
};

import { Add, Remove } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { add, addHours, isSameDay, isToday } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Sx } from '../../utils/sx';
import { DatePicker } from './DatePicker';
import { TimePicker } from './TimePicker';

interface EventDateTimeProps {
  startDate: Date;
  endDate: Date | null;
  onStartDateChange?: (startDate: Date) => void;
  onEndDateChange?: (endDate: Date | null) => void;
  sx?: Sx;
  defaultEndDate?: Date | null;
  disabled?: boolean;
}

export const EventDateTime: FC<EventDateTimeProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  sx,
  defaultEndDate,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={sx}>
      <Box sx={{ display: 'flex' }}>
        <DatePicker
          sx={{ mr: 1, flex: 3 }}
          disabled={disabled}
          disablePast
          label={t('START_DATE')}
          value={startDate}
          onChange={onStartDateChange}
        />
        <TimePicker
          sx={{ ml: 1, flex: 2 }}
          disabled={disabled}
          minTime={isToday(startDate) ? new Date() : undefined}
          label={t('START_HOUR')}
          value={startDate}
          onChange={onStartDateChange}
        />
      </Box>

      {endDate && (
        <Box sx={{ display: 'flex', mt: 2 }}>
          <DatePicker
            sx={{ mr: 1, flex: 3 }}
            disabled={disabled}
            disablePast
            label={t('END_DATE')}
            minDate={startDate}
            value={endDate}
            onChange={onEndDateChange}
          />
          <TimePicker
            sx={{ ml: 1, flex: 2 }}
            disabled={disabled}
            minTime={
              isSameDay(startDate, endDate)
                ? add(startDate, { minutes: 1 })
                : undefined
            }
            label={t('END_HOUR')}
            value={endDate}
            onChange={onEndDateChange}
          />
        </Box>
      )}

      <Button
        disabled={disabled}
        sx={{ mt: 1 }}
        startIcon={endDate ? <Remove /> : <Add />}
        onClick={() =>
          onEndDateChange?.(
            endDate ? null : defaultEndDate || addHours(startDate, 3),
          )
        }
      >
        {t('END_DATE_AND_HOUR')}
      </Button>
    </Box>
  );
};

import { Lock, MobileFriendly } from '@mui/icons-material';
import {
  Box,
  InputBaseComponentProps,
  TextField,
  Typography,
} from '@mui/material';
import { FC, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Sx } from '../../utils/sx';
import { isPhoneValid } from './utils';

const PhoneInput = forwardRef<HTMLInputElement, InputBaseComponentProps>(
  ({ onChange, name, className, ...props }, ref) => {
    return (
      <Input
        {...props}
        ref={ref as any}
        onChange={(val) =>
          onChange?.({ target: { name, value: val?.toString() || '' } } as any)
        }
        defaultCountry="FR"
        numberInputProps={{
          className,
          style: { paddingLeft: 6 },
        }}
        style={{ paddingLeft: 22 }}
      />
    );
  },
);

interface PhoneProps {
  sx?: Sx;
  value: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const Phone: FC<PhoneProps> = ({ sx, value, onChange, disabled }) => {
  const { t } = useTranslation();
  const isValid = isPhoneValid(value);
  const [isFocus, setIsFocus] = useState(false);

  const infos = [
    {
      Icon: MobileFriendly,
      text: t('A_CODE_WILL_BE_SENT'),
    },
    {
      Icon: Lock,
      text: t('HIDDEN_PHONE_NUMBER'),
    },
  ];

  return (
    <Box sx={sx}>
      <TextField
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        value={value}
        onChange={({ target }) => onChange?.(target.value)}
        error={!!value.length && !isFocus && !isValid}
        helperText={
          !!value.length && !isFocus && !isValid && t('INVALID_PHONE_NUMBER')
        }
        disabled={disabled}
        label={t('PHONE_NUMBER')}
        placeholder={t('PHONE_PLACEHOLDER')}
        fullWidth
        InputProps={{ inputComponent: PhoneInput }}
        InputLabelProps={{ shrink: true }}
        type="tel"
      />
      <Box>
        {infos.map(({ Icon, text }, i) => (
          <Box key={i} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Icon sx={{ color: 'GrayText', fontSize: 20, mr: 1 }} />
            <Typography color="GrayText">{text}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { CameraAlt, PhotoLibrary } from '@mui/icons-material';
import { Box, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { isApp } from './platform';
import { AnchorEl, useMenu } from './useMenu';

function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(','),
    // eslint-disable-next-line prefer-destructuring
    mime = arr[0].match(/:(.*?);/)?.[1],
    bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    // eslint-disable-next-line immutable/no-mutation
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

type OnUpload = (files: File[] | null) => void;

type OpenFileSelector = (
  event: { currentTarget: AnchorEl },
  onUpload?: OnUpload,
  accept?: string,
) => void;

interface FileUploadContext {
  openFileSelector: OpenFileSelector;
}

const FileUploadContext = createContext<FileUploadContext | undefined>(
  undefined,
);

export const FileUploadProvider: FC<PropsWithChildren> = ({ children }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [accept, setAccept] = useState('image/*');
  const [onUpload, setOnUpload] = useState<OnUpload>(() => {});

  const openFileSelector: OpenFileSelector = (
    event,
    onUploadProp,
    acceptProp,
  ) => {
    setAccept(acceptProp || 'image/*');
    setOnUpload(() => onUploadProp || (() => {}));
    inputRef.current?.click();
  };

  return (
    <FileUploadContext.Provider value={{ openFileSelector }}>
      {children}
      <input
        type="file"
        accept={accept}
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={({ target }) =>
          onUpload?.(target.files ? Array.from(target.files) : null)
        }
      />
    </FileUploadContext.Provider>
  );
};

export const useFileUpload = (): Pick<
  FileUploadContext,
  'openFileSelector'
> => {
  const context = useContext(FileUploadContext);
  const { t } = useTranslation();

  const getPhoto = useCallback(
    async (source: CameraSource, onUpload: OnUpload) => {
      let photo;
      try {
        photo = await Camera.getPhoto({
          quality: 100,
          allowEditing: false,
          resultType: CameraResultType.DataUrl,
          source,
        });
      } catch (e) {}

      if (!photo?.dataUrl) {
        onUpload?.(null);
        return;
      }

      const file = dataURLtoFile(photo.dataUrl, '');
      onUpload?.([file]);
    },
    [],
  );

  const [openMenu, closeMenu] = useMenu<OnUpload>((onUpload) =>
    onUpload ? (
      <Box>
        <MenuItem
          onClick={() => {
            getPhoto(CameraSource.Photos, onUpload);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <PhotoLibrary />
          </ListItemIcon>
          <ListItemText>{t('PICK_PICTURE')}</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            getPhoto(CameraSource.Camera, onUpload);
            closeMenu();
          }}
        >
          <ListItemIcon>
            <CameraAlt />
          </ListItemIcon>
          <ListItemText>{t('TAKE_PICTURE')}</ListItemText>
        </MenuItem>
      </Box>
    ) : null,
  );

  const openSourceMenu: OpenFileSelector = useCallback(
    async (event, onUpload) => {
      openMenu(event, onUpload);
    },
    [openMenu],
  );

  if (!context) {
    throw new Error('You need to wrap useFileUpload inside FileUploadProvider');
  }

  if (isApp) {
    return { openFileSelector: openSourceMenu };
  }

  const { openFileSelector } = context;
  return { openFileSelector };
};

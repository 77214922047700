import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EventLocation,
  LocationPickerValue,
} from '../../components/fields/EventLocation';
import { isEventLocationValid } from '../../components/fields/utils';
import { useGetEvent, useUpdateEvent } from '../../services/useEvent';
import { useNotification } from '../../utils/useNotification';
import { getValue } from '../../utils/valOrUpdater';

interface LocationEditDialogProps {
  open: boolean;
  onClose: () => void;
}

export const LocationEditDialog: FC<LocationEditDialogProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { event } = useGetEvent();
  const [value, setValue] = useState<LocationPickerValue>({
    location: event?.location || null,
    locationPhotoURL: event?.locationPhotoURL || null,
    locationPhotoURLCreatedAt: event?.locationPhotoURLCreatedAt || null,
  });
  const [locationOpen, setLocationOpen] = useState(false);
  const { updateEvent, loading } = useUpdateEvent();
  const { pushError } = useNotification();

  if (!event) return null;

  const onValidation = async () => {
    try {
      await updateEvent(event.id, value);
      onClose();
    } catch (e) {
      pushError(e);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      sx={{ pb: locationOpen ? 20 : undefined }}
    >
      <DialogTitle>{t('TODO_LOCATION_LABEL')}</DialogTitle>
      <DialogContent>
        <EventLocation
          sx={{ mt: 1 }}
          value={value}
          onChange={(updater) =>
            setValue((prev) => ({
              ...prev,
              ...getValue(updater, prev),
            }))
          }
          setOpen={setLocationOpen}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('CANCEL')}</Button>
        <LoadingButton
          loading={loading}
          onClick={onValidation}
          variant="contained"
          disabled={!isEventLocationValid(value.location)}
        >
          {t('APPLY')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

import { PushNotifications } from '@capacitor/push-notifications';
import { useIonRouter, useIonToast } from '@ionic/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { paths } from '../router';
import { useGetUser } from '../services/useAuth';
import { useGetEvent } from '../services/useEvent';
import { useGetGuest } from '../services/useGuest';
import {
  deviceNotificationTokenAtom,
  getGuestNotificationTokensCollection,
  getUserNotificationTokensCollection,
  useGetGuestNotificationTokens,
  useGetUserNotificationTokens,
  useUploadDeviceToken,
} from '../services/useNotificationToken';
import { isApp } from './platform';
import { useAppIsActive } from './useAppIsActive';

export const useDeviceNotifications = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const appIsActive = useAppIsActive();
  const [present] = useIonToast();
  const { user, loading: userLoading } = useGetUser();
  const { eventId } = useGetEvent();
  const { guest } = useGetGuest();
  const [deviceToken, setDeviceToken] = useRecoilState(
    deviceNotificationTokenAtom,
  );
  const { userNotificationTokens } = useGetUserNotificationTokens(!isApp);
  const { guestNotificationTokens } = useGetGuestNotificationTokens(
    !isApp || !!user || userLoading,
  );
  const uploadDeviceToken = useUploadDeviceToken();

  useEffect(() => {
    if (!isApp) return;

    PushNotifications.addListener('registration', (token) => {
      setDeviceToken(token.value);
    });

    PushNotifications.addListener('pushNotificationReceived', (notification) =>
      present({
        header: notification.title,
        message: notification.body,
        layout: 'stacked',
        duration: 6000,
        position: 'top',
        buttons: [
          ...(notification.data.eventId && notification.data.eventId !== eventId
            ? [
                {
                  text: t('SEE_EVENT'),
                  role: 'info',
                  handler: () =>
                    router.push(
                      generatePath(paths.EVENT, {
                        eventId: notification.data.eventId,
                      }),
                    ),
                },
              ]
            : []),
          {
            text: t('CLOSE'),
            role: 'cancel',
          },
        ],
      }),
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (action) => {
        const { eventId: notificationEventId } = action.notification.data;

        if (notificationEventId && notificationEventId !== eventId) {
          router.push(
            generatePath(paths.EVENT, { eventId: notificationEventId }),
            'none',
          );
        }
      },
    );

    return () => {
      PushNotifications.removeAllListeners();
    };
  }, [eventId, present, router, setDeviceToken, t]);

  useEffect(() => {
    if (!isApp || !eventId || !appIsActive) return;

    PushNotifications.getDeliveredNotifications().then(
      (deliveredNotifications) => {
        const notificationsFromEvent =
          deliveredNotifications.notifications.filter(
            (notification) => notification.data.eventId === eventId,
          );
        if (!notificationsFromEvent.length) return;
        PushNotifications.removeDeliveredNotifications({
          notifications: notificationsFromEvent,
        });
      },
    );
  }, [appIsActive, eventId]);

  // If user, request permission
  useEffect(() => {
    if (!isApp || !user?.id) return;

    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      }
    });
  }, [user?.id]);

  // If no user, request permission only if guest
  useEffect(() => {
    if (!isApp || user?.id || !guest?.id) return;

    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      }
    });
  }, [guest?.id, user?.id]);

  useEffect(() => {
    if (!isApp || !deviceToken) return;

    if (user) {
      if (!userNotificationTokens?.map(({ id }) => id).includes(deviceToken)) {
        uploadDeviceToken(
          user.id,
          getUserNotificationTokensCollection(user.id),
        );
      }
      return;
    }

    if (
      eventId &&
      guest &&
      !guestNotificationTokens?.map(({ id }) => id).includes(deviceToken)
    ) {
      uploadDeviceToken(
        guest.id,
        getGuestNotificationTokensCollection(eventId, guest.id),
      );
    }
  }, [
    deviceToken,
    eventId,
    guest,
    guestNotificationTokens,
    uploadDeviceToken,
    user,
    userNotificationTokens,
  ]);
};

import { useIonRouter } from '@ionic/react';
import {
  Close,
  Edit,
  Email,
  History,
  MoreHoriz,
  Place,
} from '@mui/icons-material';
import { Box, Button, Skeleton, Typography } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { VisualCalendar } from '../../components/VisualCalendar';
import { UserLoginDialog } from '../../components/user/UserLoginDialog';
import { paths } from '../../router';
import { User, useGetUser } from '../../services/useAuth';
import { useGetEvent } from '../../services/useEvent';
import { useDeleteGuest, useGetGuest } from '../../services/useGuest';
import { theme } from '../../theme';
import { eventIsPast, formatStartEndDates } from '../../utils/eventDate';
import { getLocationName } from '../../utils/eventLocation';
import { useDialog } from '../../utils/useDialog';
import { useGetHost } from '../../utils/useGetHost';
import { useInvite } from '../../utils/useInvite';
import { useMenu } from '../../utils/useMenu';
import { useNotification } from '../../utils/useNotification';
import { ActionsMenu } from './ActionsMenu';
import { CancelDialog } from './CancelDialog';
import { EditDialog } from './EditDialog';
import { ReportDialog } from './ReportPostDialog';

interface HeaderProps {
  loading?: boolean;
}

export const Header: FC<HeaderProps> = ({ loading }) => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const { event: globalEvent } = useGetEvent();
  const event = loading ? undefined : globalEvent;
  const { isHost } = useGetHost();
  const { user } = useGetUser();
  const { guest } = useGetGuest();
  const { pushError } = useNotification();
  const { openInviteModal } = useInvite();
  const { deleteGuest } = useDeleteGuest();

  const [openEditDialog, closeEditDialog] = useDialog((open) =>
    event ? (
      <EditDialog open={open} onClose={closeEditDialog} event={event} />
    ) : null,
  );

  const [openCancelDialog, closeCancelDialog] = useDialog((open) => (
    <CancelDialog open={open} onClose={closeCancelDialog} />
  ));

  const [openReportDialog, closeReportDialog] = useDialog((open) => (
    <ReportDialog open={open} onClose={closeReportDialog} />
  ));

  const [openLoginDialog, closeLoginDialog] = useDialog((open) => (
    <UserLoginDialog
      open={open}
      onClose={closeLoginDialog}
      title={t('LOGIN_TO_QUIT_EVENT')}
      text={t('LOGIN_TO_QUIT_EVENT_SUBTITLE')}
      onSuccess={(loggedUser) => {
        // To let guest update with userId before deleting it
        setTimeout(() => {
          onQuitEvent(loggedUser);
        }, 1000);
      }}
    />
  ));

  const onQuitEvent = async (loggedUser: User | null) => {
    if (!guest || !event) {
      return;
    }

    if (!loggedUser) {
      openLoginDialog();
      return;
    }

    router.push(paths.HOME, 'root');

    try {
      await deleteGuest(event?.id, guest.id);
    } catch (e) {
      pushError(e);
    }
  };

  const [openMenu, closeMenu] = useMenu(() => (
    <ActionsMenu
      onClose={closeMenu}
      onCancel={openCancelDialog}
      openReportDialog={openReportDialog}
      onQuitEvent={() => onQuitEvent(user)}
    />
  ));

  const [firstDateLine, secondDateLine] = formatStartEndDates(event, t);
  const eventIsPastOrCanceled = eventIsPast(event) || !!event?.canceledAt;
  const dateColor = eventIsPastOrCanceled ? grey[500] : red[500];

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <VisualCalendar
        date={event?.startDate}
        color={dateColor}
        sx={{ position: 'absolute', top: -60, left: theme.spacing(3) }}
      />

      {!eventIsPastOrCanceled && (
        <Box
          sx={{
            position: 'absolute',
            top: -68.5,
            right: 0,
            p: 2,
            pr: [2, 2, 0],
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            startIcon={<Email />}
            onClick={() => openInviteModal()}
          >
            {t('INVITE')}
          </Button>
        </Box>
      )}

      <Box sx={{ pl: [3, 21], pt: [10, 3], pb: 3, pr: 3 }}>
        {eventIsPastOrCanceled && (
          <Typography
            color="GrayText"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {event?.canceledAt ? (
              <Close sx={{ fontSize: 18, mr: 1 }} />
            ) : (
              <History sx={{ fontSize: 18, mr: 1 }} />
            )}
            {t(event?.canceledAt ? 'EVENT_IS_CANCELED' : 'EVENT_IS_PAST')}
          </Typography>
        )}
        <Typography
          variant="h4"
          sx={{
            overflow: 'hidden',
            textDecoration: event?.canceledAt ? 'line-through' : undefined,
          }}
        >
          {event ? event.title : <Skeleton width={300} />}
        </Typography>
        <Typography variant="h6" sx={{ color: dateColor }}>
          {firstDateLine ? firstDateLine : <Skeleton width={200} />}
        </Typography>
        {secondDateLine && (
          <Typography variant="h6" sx={{ color: dateColor, opacity: 0.6 }}>
            {secondDateLine}
          </Typography>
        )}
        {event?.location && (
          <Box
            component={event.location.details?.url ? 'a' : 'div'}
            href={event.location.details?.url}
            target="_blank"
            rel="noreferrer"
            sx={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
              color: theme.palette.grey[600],
            }}
          >
            <Place
              sx={{ mr: 0.5, color: theme.palette.grey[500], fontSize: 22 }}
            />
            <Typography variant="h6">
              {getLocationName(event.location)}
            </Typography>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          position: ['absolute', 'static'],
          flexShrink: 0,
          top: 0,
          right: 0,
          mt: 2,
          mr: 2,
          ml: 'auto',
          display: 'flex',
        }}
      >
        {isHost && !event?.canceledAt && (
          <Button
            sx={{ mr: 1 }}
            variant="outlined"
            startIcon={<Edit />}
            onClick={() => openEditDialog()}
          >
            {t('EDIT')}
          </Button>
        )}
        <Button
          variant="outlined"
          onClick={openMenu}
          sx={{ minWidth: 0, px: 1.5 }}
        >
          <MoreHoriz />
        </Button>
      </Box>
    </Box>
  );
};

import { IonContent, IonPage } from '@ionic/react';
import { Container } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventCreation } from '../../components/EventCreation';
import { TopBar } from '../../components/TopBar';
import { theme } from '../../theme';
import { isApp } from '../../utils/platform';

export const NewEvent: FC = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [locationOpen, setLocationOpen] = useState(false);

  return (
    <IonPage>
      {isApp && <TopBar title={t('NEW_EVENT')} />}
      <IonContent>
        {!isApp && <TopBar />}
        <Container
          maxWidth="md"
          sx={{
            pb: `max(${theme.spacing(
              locationOpen ? 30 : 2,
            )}, env(safe-area-inset-bottom))`,
            pt: 3,
          }}
          disableGutters
        >
          <EventCreation
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            sx={{ mt: '60px' }}
            setLocationOpen={setLocationOpen}
          />
        </Container>
      </IonContent>
    </IonPage>
  );
};
